/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Signika:wght@300..700&display=swap');

/* body{font-family: 'Roboto', sans-serif; margin: 0; padding: 0;} */
body {
	font-family: 'Signika', sans-serif;
	margin: 0;
	padding: 0;
}

img {
	max-width: 100%;
}

li {
	list-style: none;
}

a:hover {
	text-decoration: none !important;
}

p:hover {
	text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
ol,
li,
ul,
a,
img,
p,
span,
strong {
	padding: 0px;
	margin: 0px;
}

a:hover,
a:focus {
	outline: none;
	text-decoration: none
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	/* border: 1px solid red; */
}


header {
	background: #000;
}

a {
	text-decoration: none;
}

.react-datepicker-wrapper {
	width: 100%;
	display: inline-block;
	padding: 0;
	border: 0;
}

.adminNavLogo {
	margin-left: 30px;
	max-width: 185px;
}

.DashBoardInputBx .ql-container {
	height: 83%;
}

.jobseekerMailImageBox {
	max-width: 300px;
}

.jobseekersListingNoData {
	margin-top: 100px;
}

.sliderImageBox {
	height: 400px;
	background-size: cover;
}
.jobTable .css-j8yymo {
    cursor: inherit;
    position: absolute;
    opacity: 0;
    /* width: 100%; */
    height: 100%;
    top: 9px;
    left: 5px;
    margin: 0;
    padding: 0;
    z-index: 1;
    /* display: none; */
}
.jobExpired {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh; /* Ensures minimum height to cover the full viewport */
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.uploadCvButton {
	color: #f3734c;
	background-color: white;
	font-weight: 500;
	border: 2px #f3734c solid;
	font-size: 17px;
	border-radius: 3px;
	margin-left: 10px;
	padding: 9px 6px;
	min-width: 175px;
}

.frontendNavLogo {
	max-width: 140px;
}

/* Cookie consent start */
.wrapper {
	min-height: 25vh;
	background-color: #4070f4;
	position: fixed;
	bottom: 20px;
	/* Adjust this value to change the distance from the bottom */
	right: 20px;
	/* Adjust this value to change the distance from the right */
	max-width: 345px;
	width: 100%;
	background: #fff;
	border-radius: 8px;
	padding: 15px 25px 22px;
	transition: right 0.3s ease;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	z-index: 999;
}

.wrapper.show {
	right: 20px;
}

.wrapper header {
	display: flex;
	align-items: center;
	column-gap: 15px;
}

.wrapper header i {
	color: #4070f4;
	font-size: 32px;
}

.wrapper header h2 {
	color: #4070f4;
	font-weight: 500;
}

.wrapper .data {
	margin-top: 16px;
}

.wrapper .data p {
	color: #333;
	font-size: 16px;
}

.data p a {
	color: #4070f4;
	text-decoration: none;
}

.data p a:hover {
	text-decoration: underline;
}

.wrapper .buttons {
	margin-top: 16px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.buttons .button {
	border: none;
	color: #fff;
	padding: 8px 0;
	border-radius: 4px;
	background: #4070f4;
	cursor: pointer;
	width: calc(100% / 2 - 10px);
	transition: all 0.2s ease;
}

.buttons #acceptBtn:hover {
	background-color: #034bf1;
}

#declineBtn {
	border: 2px solid #4070f4;
	background-color: #fff;
	color: #4070f4;
}

#declineBtn:hover {
	background-color: #4070f4;
	color: #fff;
}

/* Cookie Consent end */



.homeJobs {
	background: transparent !important;
	border-radius: 0;
	text-align: left;
	padding: 0;
	margin: 0;
	font-size: 25px;
	margin-bottom: 13px;
	border: none;
}

.fourthBlock .homeJobs {
	background: transparent !important;
	border-radius: 0;
	text-align: left;
	padding: 0;
	margin: 0;
	font-size: 25px;
	margin-bottom: 13px;
}

.errorPage {
	text-align: center;
}

.errorImage {
	margin-top: 30px;
	max-width: 600px;
}

.empPaginationButton {
	margin: 20px 0 20px 0px;
}

.empPaginationData {
	text-align: center;
	/* margin-left: 360px; */
}

.DashBoardCreatBx .css-13cymwt-control {
	border: 1px dashed #a7a6a6;
	min-height: 75px;
}

.skillPackage {
	z-index: 99;
}

.MembershipMainBx .owl-nav {
	display: none;
}

/* loader */

.loader-container {
	position: fixed;
	width: 100%;
	height: 100%;
	/* background: #ffffff00 url('/public/Images/loaderNew.gif') no-repeat center; */
	background: #ffffff00 url('/public/Images/lgbtiLoader.gif') no-repeat center;
	z-index: 999;
}

.skillPackageCategory {
	z-index: 99;
}

.skillPackageSubcategory {
	z-index: 98;
}

.APBlogEditor {
	border: 1px dashed #a7a6a6;
	border-radius: 5px;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes animloader {
	50% {
		transform: scale(1) translate(-50%, -50%);
	}
}

.validation .error {
	border: none;
	outline: none;
	font-size: 17px;
	height: 50px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	/* max-width: 1100px; */
	border: 1px dashed #ff0000;
	/* padding: 20px 20px; */
}

.validation .input-error {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: 100px;
	max-width: 1100px;
	border: 1px dashed #ff0000;
	padding: 20px 20px;
}

.employerLogin .error {
	border: none;
	outline: none;
	font-size: 17px;
	height: 50px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	/* max-width: 1100px; */
	border: 1px dashed #ff0000;
	/* padding: 20px 20px; */
}

.employerLogin .input-error {
	border: none;
	outline: none;
	font-size: 17px;
	height: 50px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	/* max-width: 1100px; */
	border: 1px dashed #ff0000;
	/* padding: 20px 20px; */
}

.createJob .error {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: 100px;
	max-width: 1100px;
	border: 1px dashed #ff0000;
	padding: 20px 20px;
}

.createJob .input-error {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: 100px;
	max-width: 1100px;
	border: 1px dashed #ff0000;
	padding: 20px 20px;
}

.editProfile .error {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: 100px;
	max-width: 1100px;
	border: 1px dashed #ff0000;
	padding: 20px 20px;
}

.editProfile .input-error {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: 100px;
	max-width: 1100px;
	border: 1px dashed #ff0000;
	padding: 20px 20px;
}


.form-select {
	box-shadow: none !important;
	outline: none;
	font-size: 16px;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px dashed #a7a6a6;
	color: #797979;
	height: 70px;
	opacity: 1;
}

.newcontact_form .form-select {
	height: 50px !important;
}

.newcontact_form .form-control {
	height: 50px !important;
}

.newcontact_form textarea.form-control {
	min-height: 120px !important;
}

.formButton {
	padding: 8px 0 8px 0;
}


/* Header Code start  */

/* .headerNav{
	border-radius: 0px;
    margin: 25px 0 0px 0;
    min-height: 40px;
} */
.defaultnavbar {
	position: relative;
	padding: 17px 0px;
	background-color: #000000;

}

.navbar-brand .frontendNavLogo {
	margin: 30px 0px 0px 0px;
	width: auto;
	max-width: 380px;

}

.navbar-brand {


	z-index: 1000;
	float: none;
	position: absolute;
	top: -20px;

	padding: 0;
	height: auto;

}




.navbar-nav .endorsedCompanies a.show {
	color: #fff;
}

.APNavbar .defaultnavbar {
	background: #fff;
}

.navbardefault .navitems {
	color: white !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
	position: absolute;
	background: #000 !important;
	/* width: 210px !important; */
	/* left: 15px!important; */

}

.navbar-expand-lg .navbar-nav .adminDropdown {
	background: #fff !important;
	position: absolute;
	width: 300px !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item {
	display: block !important;
	padding: 3px 20px !important;
	clear: both;
	font-weight: 400 !important;
	line-height: 1.42857143 !important;
	color: #fff !important;
	white-space: nowrap !important;
	font-size: 90% !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item:hover {
	color: #EB602E !important;
	background: unset !important;
}


/* Header Code End  */

/* Navbar */
.navbody {

	justify-content: center;
}

.navigation {
	max-height: 100px;
	margin-left: 70px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.navbardefault .navitems {
	margin: 8px 0 0 29px;
	font-weight: 700;
	font-family: 'Signika', sans-serif;
	font-size: 18px;
	position: relative;
	color: #3c3c3c;
}

.navbardefault .navitemsUkr {
	margin: 8px 0 0 20px;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	position: relative;
	color: #3c3c3c;
}


@media (max-width: 1400px) {
	.navbardefault .navitems {
		font-size: 14px;
	}
}

.navbardefault .navitems.active {
	font-weight: 500;
	color: #422778;
}

.navbardefault .navitems:hover:before,
.navbardefault .navitems.active::before {
	position: absolute;
	left: 0;
	bottom: -17px;
	content: "";
	/* background: #A82686; */
	width: 100%;
	height: 3px;
}

.navitems:hover {

	color: #EB602E !important;

}

.SearchIcon {
	margin: 0 0px 0 28px;
	padding-top: 7px;
	color: #3c3c3c;
}

.navbardefault .btn-primary {
	border: none;
	background: #294a9c;
	border-radius: 3px;
	text-transform: uppercase;
	font-weight: 500;
	padding: 10px 16px;
	font-family: 'Roboto', sans-serif;
}

.navbardefault .btn-primary i {
	margin-right: 5px;
}

.navButton1 {
	color: white;
	background-color: #294A9C;
	font-weight: 500;
	border: 2px #294a9c solid;
	font-size: 16px;
	padding: 9px 25px;
	border-radius: 3px;
	margin-left: 15px;
	min-width: 135px;
}

.navButton1:hover {
	color: #fff;
	background-color: #f3734c;
	border-color: #f3734c;
}

.navButton2 {
	color: #FC21D0;
	background-color: white;
	font-weight: 500;
	/* border: 2px #f3734c solid; */
	font-size: 16px;
	padding: 9px 25px;
	border-radius: 3px;
	margin-left: 10px;
}

.navButton2:hover {
	color: #422778;
	background-color: #fff;
	border-color: #fff;
}

.endorsedCompanies {
	bottom: 7px;
}

.endorsedCompanies a {
	color: white !important;

	/* bottom: 7px; */
}

.endorsedCompanies a:hover {
	color: #EB602E !important;
}

.navbar-brand {
	z-index: 1000;
	float: none;
	position: absolute;
	top: -20px;
	padding: 0;
	height: auto;
}

.APNavbar .defaultnavbar span.navbar-brand {
	top: 10px;
}

.navbardefault .endorsedCompanies:hover:before,
.navbardefault .endorsedCompanies.active::before {
	position: absolute;
	left: 0;
	bottom: -24px;
	content: "";
	width: 100%;
	height: 3px;
}

/* Userpage */

.marqueeText {
	background-color: #294a9c;
	color: white;
	padding: 15px 0 15px 0;
}

.marqueeLink {
	color: white;
	font-size: large;
}

.upperSectionBox {
	left: 0;
	padding: 12px 20px 16px 20px;
	width: 800px;
	background-color: rgba(66, 39, 120, 0.8);
	border-radius: 10px;
	display: inline-block;
	text-align: left;
}

.globalImage {
	position: absolute;
	right: 10px;
	top: 20px;
	z-index: 99;
	width: 340px;
}

.sliderbody {
	position: relative;
	text-align: left;
	color: black;
	overflow: hidden;
	border-top: 4px solid #fff;
}

.upperSlider {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 99;
	width: 100%;
	padding-top: 155px;
	text-align: center;
}

.lowerSlider {
	padding: 130px 0;
}

.sliderbody .owl-dots {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
}

.slidertext h1 {
	font-size: 45px;
	color: #000;
	font-weight: 300;
	color: #000;
	font-family: 'Signika', sans-serif;
	font-weight: 700;
	font-size: 250%;
	padding: 0;
	margin: 0 0 10px 0;
	font-weight: 400;
}

.slidertext p {
	font-size: 19px;
	color: white;
	opacity: 0.9;
}

.inputGrp {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 75%;
}

.searcharea {
	background-color: white;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.12);
	margin-top: 30px;
	border-radius: 5px;
	padding: 6px;
}

.searcharea i {
	margin: 9px;
}

.searcharea img {
	max-width: 180%;
}

.searcharea .form-control {
	border-radius: 0;
	border: none;
	box-shadow: none !important;
	outline: none;
	font-size: 18px;
	font-weight: 300;
	opacity: 0.8;
}

.button1 {
	color: white;
	background-color: #294a9c;
	font-weight: 500;
	border: 2px #294a9c solid;
	font-size: 17px;
	padding: 10px 25px;
	border-radius: 3px;
}

.button1:hover {
	color: #fff;
	background-color: #f3734c;
	border-color: #f3734c;
}

.button2 {
	color: #f3734c;
	background-color: white;

	font-weight: 500;
	border: 2px #f3734c solid;
	font-size: 17px;
	padding: 9px 25px;
	border-radius: 3px;
	margin-left: 10px;
}

.button2:hover {
	color: #294a9c;
	background-color: #fff;
	border-color: #294a9c;
}

.slidertext2 {


	margin-top: 40px;

}

.jobs-clients-posted {
	padding: 0 20px;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	display: inline-block;
}

.jobs-clients-posted:first-child {
	border-left: none;
	padding-left: 0;
}

.jobs-clients-posted h3 {
	display: block;
	color: #3e3e42;
	font-size: 38px;
	font-weight: 500;
}

.jobs-clients-posted h6 {
	color: white;
	font-size: 18px;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
}

.CategorySection {
	padding: 70px 0;
}


.CategoryBx img {
	margin-bottom: 0;

}

.CategoryBx h5 {
	color: #25242a;
	font-size: 26px;
	font-weight: normal;
	margin-top: 25px;
}

.CategoryBx p {
	color: #525252;
	font-size: 18px;
	margin-top: 10px;
	opacity: 0.8;
	max-width: 80%;
}

.subsecondblock {
	margin-top: 120px;
	text-align: center;
	position: relative;
}

.RightDotImg {
	position: absolute;
	right: -60px;
	top: 40px;
}

.LeftDotImg {
	position: absolute;
	left: -84px;
	top: 40%;
}

.subsecondblockHeading {
	font-weight: 500;
	color: #25242a;
	font-size: 40px;
}

.textGradient {
	position: relative;
}

.textGradient::before {
	position: absolute;
	left: 0;
	bottom: -6px;
	content: "";
	background: #eb68d47a;
	width: 100%;
	height: 28px;
	z-index: 0;
}

.SubHaddingTxt {
	position: relative;
}

.textGradient {
	position: relative;
	margin-left: 5px;
}

.ExploreCategory {
	margin-top: 60px;
}

.thirdBlock {
	background-image: url("/public/Images/BAckground-Choose.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	padding: 70px 0;
}

.BrowseJobsHadding {
	font-size: 40px;
	font-weight: 500;
	color: #fff;
}

.thirdBlockData {
	text-align: center;
	color: white;
}

.TB1item {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	margin-top: 5%;
}

.TB1subitems:hover {
	border-bottom: 3px solid #f3734c;
}

.TB2subitems {
	text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.fourthBlock h3 {
	text-align: center;
	margin-bottom: 30px;
}

/*.fourthBlock .FBitem {
  margin-left: 8%;
  margin-right: 8%;
}*/
.FeaturedJobsSection {
	padding-bottom: 70px;
	margin-top: 100px;
	margin-bottom: 30px;
}

.fourthBlock button {
	background-color: #f3734c;
	border-radius: 3px;
	text-align: center;
	font-weight: 500;
	border: none;
	padding: 10px 27px;
	font-size: 18px;
	margin-top: 40px;
}

.fourthBlock button:hover {
	background-color: #294a9c;
}

/* fifthBlock */

.fifthBlock {
	margin: 0 8% 5% 8%;
}

.fifthBlock .section2 {
	margin: 3% 0 3% 0;
}

.employersLogo {

	max-height: 60px;
	margin: 10px 10px 20px 10px;

}

/* .EmployersLogoBx {
	width: 100%;
	padding: 10px;
	min-height: 233px;
	height: auto;
} */

ul.topEmpNew li {
	display: inline-block;
	width: 25%;
}

ul.topEmpNew {
	text-align: center;
}

.BorderRight {
	border-right: 1px #ddd solid;
}

.BorderBottom {
	border-bottom: 1px #ddd solid;
}

.TopEmployersSection {
	padding: 70px 0 130px;
}

.TopEmployersBx {
	margin-top: 60px;
}

/* sixthBlock */

.sixthBlock {
	margin: 0 0 5% 0;
}

.defaultnavbar .navButton1 {
	background-color: #fc21d0;
	border: none;
	margin-left: 32px;
}

.defaultnavbar .navButton2 {
	margin-left: 33px;
	min-width: 135px;
}

.sixthBlock {
	margin: 0 0 5%;
	overflow: hidden;
}

.DownloadAppSection {
	background: url("/public/Images/downloadbg.jpg") no-repeat center top;
	color: white;
	background-size: cover;
	padding: 0 0 30px;
}

.section1text {
	padding-top: 150px;
}

.section1text h2 {
	font-size: 60px;
	font-weight: 600;
}

.section1text h3 {
	font-size: 49px;
	font-weight: 500;
}

.section1button img {
	width: 35%;
	margin: 5% 3% 0 0;
}

.DownloadRightImg {
	position: relative;
	display: inline-block;
	width: 100%;
}

.phoneImage {
	position: absolute;
	z-index: 2;
	margin: 0;
	left: -80px;
	top: 70px;
}

.girlImage {
	position: relative;
	z-index: 1;
	float: inline-end;
	margin-top: -60px;
	margin-left: 100px;
}

.sixthBlock .section2 {

	padding: 5% 3% 0 3%;
	text-align: center;
}

.flexImage {
	width: 90%;
}

/* CategoryCard */

.catCard {
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
	width: 100%;
	min-height: 350px;
	position: relative;
	text-align: start;
	border: none;
	border-radius: 10px;
	padding: 40px 40px 20px;
	margin-bottom: 25px;
}

.ExploreCategory .catCardBody {
	padding: 0;
}

.catCard .catCardTitle {
	color: #201f1f;
	font-size: 25px;
	font-weight: normal;
}

.catCard .catCardSubtitle {
	color: #201f1f;
	font-size: 28px;
	font-weight: 500;
}

.subsecondblock .button1 {
	border: 1px #294a9c solid;
	font-size: 17px;
	padding: 12px 25px;
	margin-top: 40px;
}

.subsecondblock .button1:hover {
	color: #fff;
	background-color: #f3734c;
	border-color: #f3734c;
}

.ImageCard {
	position: relative;
	margin: 40px 0 20px;
}

.imageChild1 {
	position: relative;
	z-index: 1;
	top: 0;
	left: 0;
}

.imageChild2 {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 14px;
}


.catCardFooter {
	font-size: 17px;
	color: #505050;
	font-weight: normal;
	margin-top: 31px;
	line-height: 23px;
}


/* BrowseJobToggler */

.buttons {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	margin-top: 3%;
	margin-bottom: 3%;

}

.BrowseJobstab {
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.BrowseJobstab .btn {
	color: white;
	font-size: 20px;
	position: relative;
}

.BrowseJobstab .btn.active::before {
	position: absolute;
	left: 0;
	bottom: -2px;
	content: "";
	background: #f3734c;
	width: 100%;
	height: 3px;
}

.BrowseJobstab .btn:first-child:active,
.BrowseJobstab .btn.active,
:not(.btn-check)+.btn:active {

	border-color: transparent;
}

.first .row .col p {
	text-align: left;

}

.list {
	text-decoration: none;
	color: white;
	text-align: left;
	font-size: 18px;
	padding-bottom: 11px;
	display: inline-block;
	border-bottom: 1px dashed transparent;
}

.list:hover {
	border-bottom: 1px dashed white;

}

.BrowseJobstabs {
	margin-top: 40px;
	text-align: left;
}

/* JobCard */

.Jcard {
	min-height: 280px;
	position: relative;
	text-align: start;
	border: 1px solid #dd5bc6;
	border-radius: 20px;
	padding: 30px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	-moz-transition: 0.5s;
	transition: 0.5s;
	margin-bottom: 25px;
}

.Jcard .JcardBody {
	padding: 0;
}

.shareNodeJobCard {
	position: absolute;
	right: 14px;
	top: 31px;
	font-size: 19px;
}

.JcardTitle {
	border-bottom: 1px solid transparent;
	color: #25242a;
	font-size: 25px;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 94%;
}

.Jcard:hover {
	box-shadow: 0 10px 20px 0 rgba(173, 173, 173, 0.32);
	border: 1px solid #f8edea;
	background: #fffbf9;
}

.JcardTitle {
	font-size: 25px;
	color: #25242a;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	border-bottom: 1px transparent solid;
	white-space: nowrap;
}

.JcardBodySection1 {
	color: #33335a;
	font-size: 17px;
	font-weight: 500;
	opacity: 0.8;
}

.JcardBodySection2 {
	margin: 20px 0;
}

.section2Item1 {
	font-size: 14px;
	border: 1px solid #78788d;
	border-radius: 15px;
	padding: 2px 15px 2px 15px;
	display: inline-block;
	color: #6e6e85;
	font-weight: 500;
}

.section2Item2 {
	font-size: 15px;
	padding: 2px 13px 2px 25px;
	display: inline-block;
	color: #6e6e85;
	font-weight: 500;
}

.section2Item2 i {
	font-weight: normal;
	font-size: 20px;
	color: #9797a6;
}

.JcardBodySection3 {
	color: #6e6e85;
	font-size: 17px;
	font-weight: 500;
	min-height: 70px;
}

.card-footer.JcardFooter {
	border-radius: 0;
	background: transparent;
	border: none;
	border-top: 1px #ececec solid;
	padding: 20px 0 0;
}

.JcardFooter {
	font-size: 13px;
}

.JcardFooterItem1 {
	display: inline-block;
}

.JcardFooterItem1DynamicImage {
	width: 20%;
	margin-top: 5px;
	display: inline-block;
}


.JcardFooterItem1DynamicImage img {
	max-width: 70px;
	max-height: 40px;
}

.JcardFooterItem2 {
	display: inline-block;
	padding-left: 20px;
}

.JcardFooterItem2 h6 {
	color: #25242a;
	font-size: 18px;
	font-weight: 500;
	padding-top: 7px;
}

.JcardFooterItem3 {
	font-size: 14px;
	border: 1px solid #bcbcbc;
	border-radius: 15px;
	padding: 5px 15px;
	color: rgba(36, 37, 42, 0.8);
	float: right;
	margin-top: 10px;
}


/* Footer */

/* Display the li elements when the mobile menu is open */
.footer.mobile-menu-open ul.mobile-menu {
	display: block;
}

/* Hide the li elements when the mobile menu is closed */
.footer ul.mobile-menu {
	display: none;
}

.footerEachHeader {
	width: 100%;
	/* margin: 0px 0px 30px 0; */
	display: flex;
	justify-content: space-between;
}

.footerEachHeader h3 {
	display: inline-block;
}

.footerPlusLink {
	color: white;
	/* padding: 0px 0 14px 0px; */
	font-size: 25px;
	float: right;
	margin-right: 15px;
	margin-bottom: 35px;
}

.footer {
	background-color: #12233c;
	padding-top: 50px;
}

.FooterLinks h3 {
	color: #fff;
	font-size: 26px;
	font-weight: 500;
}

.FooterLinks ul {
	margin-top: 20px;
}

.FooterLinks ul li {
	margin-top: 20px;

}

/* .FooterLinks ul li a {
	 color: #422778; ;
} */
.footerHeadersCol {
	color: white;
	margin-top: 5%;
	margin-bottom: 1%;
	font-size: 23px;
	font-weight: bold;
	text-align: start;
	margin-left: 7%;
	/* margin-right: 7%; */
}

.footerBodyCol {
	color: white;
	font-size: small;
	margin-top: 2%;
	text-align: start;
	margin-left: 7%;
	margin-right: 7%;
	text-decoration: none;
}

.footerFooter {
	color: white;
	margin-top: 50px;


}

.footerFooter hr {
	margin: 0;
}

.footerFooter p {
	padding: 25px 0;
	text-align: center;
	font-size: 17px;
}

.footerFooter a {
	text-decoration: none;
	color: white;
}

.SocialIcons {
	margin-top: 30px;
}

.SocialIcons a {
	display: inline-block;
	width: 36px;
	height: 36px;
	border: 2px #fff solid;
	margin-right: 10px;
	text-align: center;
	color: #fff;
	line-height: 33px;
	border-radius: 50%;
}

.LangaugeDropdown {
	margin-top: 30px;
}

.LangaugeDropdown .form-select {
	background: #12233c;
	color: #fff;
	height: 50px;
	border-radius: 3px;
	border-width: 2px;
	width: 200px;
	font-size: 19px;
	box-shadow: none;
	background-image: url("/public/Images/selectarrow.png");
	background-repeat: no-repeat;
	background-position: 90% center;
}

.MembershipMainBx {
	margin-top: 60px;
	border-left: 2px solid rgba(222, 222, 222, 0.5);
	border-right: 2px solid rgba(222, 222, 222, 0.5);
}

.MembershipCard {
	padding: 30px 70px;
}

.MembershipCardAuth {
	padding: 30px 5px;
}

.membershipCardUpperPart {
	min-height: 190px;
}

.membershipCardMain {
	height: 100%;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.membershipCardMain:hover {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.MembershipCard h4 {
	color: #25242a;
	font-size: 25px;
	font-weight: 500;
}

.MembershipCard h2 {
	margin: 20px 0 0;
	font-size: 50px;
	font-weight: 600;
	color: #422778;
}

.MembershipCard h6 {
	color: #25242a;
	font-size: 17px;
	opacity: 0.8;
	font-weight: normal;
}

.ApplyAll {
	border-top: 2px solid rgba(222, 222, 222, 0.5);
	margin-top: 20px;
	padding: 23px 20px 0;
	text-align: left;
	margin-right: 35px;
}

.ApplyAll .form-check-input[type="checkbox"] {
	border-radius: 2px;
	border-width: 2px;
	width: 20px;
	height: 20px;
	margin-right: 8px;
}

.ApplyAll .form-check-label {
	color: #25242a;
	font-size: 15px;
	opacity: 0.7;
	padding-top: 2px;
}

.applyoption {
	padding: 23px 0 0 0px;
	text-align: left;
}

.applyoption li {
	position: relative;
	color: rgba(36, 37, 42, 0.7);
	font-size: 14px;
	/* background: url("/public/Images/BlueArrow.png") no-repeat left center; */
	padding: 0 0 0 23px;
	margin: 9px 0;
}

.MembershipCard .btn-primary {
	background: #422778;
	border: none;
	border-radius: 3px;
	font-size: 19px;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
	padding: 10px 32px;
	width: 84%;
	margin-top: 30px;
}

.MembershipCard .btn-secondary {
	background: #A82686;
	border: none;
	border-radius: 3px;
	font-size: 19px;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
	padding: 10px 32px;
	width: 84%;
	margin-top: 30px;
}

.center .MembershipCard h2 {
	color: #A82686;
}

.center .applyoption li {
	/* background: url("/public/Images/OrengeArrow.png") no-repeat left center; */

}

.membershipChecks {
	color: #422778;
	padding-right: 10px;
	font-size: 18px;

}

.center .membershipChecks {
	color: #A82686;
	padding-right: 10px;
	font-size: 18px;

}

.center .MembershipCard .btn-primary {
	background: #A82686;
}

.center .MembershipCard .btn-primary:hover {
	background: #A82686;
	box-shadow: 0 10px 20px 0 rgba(243, 115, 76, 0.5);
}

.center .MembershipCard {
	background-color: #fffcfb;
	border-left: 2px solid rgba(222, 222, 222, 0.5);
	border-right: 2px solid rgba(222, 222, 222, 0.5);
}

.MembershipMainBx .owl-dots {
	display: none;
}

.MembershipMainBx .owl-nav {
	position: absolute;
	margin: 0;
	left: 0;
	top: 50%;
	transform: translate(0, -50%);
	width: 100%;
}

.MembershipMainBx .owl-nav .owl-prev {
	margin: 0;
	position: absolute;
	left: -60px;
	background: transparent !important;
}

.MembershipMainBx .owl-nav .owl-next span,
.MembershipMainBx .owl-nav .owl-prev span {
	margin: 0;
	border: 1px #7e7d7f solid !important;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	font-size: 35px;
	line-height: 32px;
	display: inline-block;
	color: #7e7d7f;
}

.MembershipMainBx .owl-nav .owl-next {
	margin: 0;
	position: absolute;
	right: -60px;
	background: transparent !important;
}

.center .MembershipCard {
	box-shadow: none;
	-webkit-transition: all 200ms ease-in;
	-webkit-transform: scale(1.10);
	-ms-transition: all 200ms ease-in;
	-ms-transform: scale(1.10);
	-moz-transition: all 200ms ease-in;
	-moz-transform: scale(1.10);
	transition: all 200ms ease-in;
	transform: scale(1.10);
}

.MembershipSection {
	padding: 40px 0;
}

.bodyItem:hover .SidebarImages img,
.imageChild2 {
	-webkit-animation: balloon2 3s ease-in-out infinite;
	-moz-animation: balloon2 3s ease-in-out infinite;
	-o-animation: balloon2 3s ease-in-out infinite;
	animation: balloon2 3s ease-in-out infinite;
}

@-webkit-keyframes balloon2 {

	0%,
	100% {
		-webkit-transform: translateY(0) rotate(0eg);
	}

	50% {
		-webkit-transform: translateY(-10px) rotate(0deg);
	}
}

@-moz-keyframes balloon2 {

	0%,
	100% {
		-moz-transform: translateY(0) rotate(0deg);
	}

	50% {
		-moz-transform: translateY(-10px) rotate(-0deg);
	}
}

@-o-keyframes balloon2 {

	0%,
	100% {
		-o-transform: translateY(0) rotate(0deg);
	}

	50% {
		-o-transform: translateY(-10px) rotate(0deg);
	}
}

@keyframes balloon2 {

	0%,
	100% {
		transform: translateY(0) rotate(0deg);
	}

	50% {
		transform: translateY(-10px) rotate(0deg);
	}
}


.CategoryCard2 .imageChild2 {
	left: 24px;
}

.HomeCategorysCardBx:nth-child(7) .imageChild2,
.HomeCategorysCardBx:nth-child(5) .imageChild2,
.HomeCategorysCardBx:nth-child(4) .imageChild2,
.HomeCategorysCardBx:nth-child(2) .imageChild2,
.CategoryCard2 .imageChild2 {
	-webkit-animation: balloon3 3s ease-in-out infinite;
	-moz-animation: balloon3 3s ease-in-out infinite;
	-o-animation: balloon3 3s ease-in-out infinite;
	animation: balloon3 3s ease-in-out infinite;
}

@-webkit-keyframes balloon3 {

	0%,
	100% {
		-webkit-transform: translateX(0) rotate(0eg);
	}

	50% {
		-webkit-transform: translateX(-10px) rotate(0deg);
	}
}

@-moz-keyframes balloon3 {

	0%,
	100% {
		-moz-transform: translateX(0) rotate(0deg);
	}

	50% {
		-moz-transform: translateX(-10px) rotate(-0deg);
	}
}

@-o-keyframes balloon3 {

	0%,
	100% {
		-o-transform: translateX(0) rotate(0deg);
	}

	50% {
		-o-transform: translateX(-10px) rotate(0deg);
	}
}

@keyframes balloon3 {

	0%,
	100% {
		transform: translateX(0) rotate(0deg);
	}

	50% {
		transform: translateX(-10px) rotate(0deg);
	}
}









/* About us */

.aboutusSection1 {
	padding: 70px 0;
	margin: 0 0 3% 0;
	background-image: url("/public/Images/About-Us-slider.jpg");
}

.aboutusSection2 img {

	width: 100%;
}

.aboutusImage {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
	border-radius: 8px;
}

.aboutusImage:hover {
	-webkit-transform: scale(1.3);
	transform: scale(1.06);
}

.aboutusSection2content {
	margin: 5% 0 5% 0;
}

.aboutusSection3 .row {
	margin: 5% 0 2% 0;
}

.aboutusSection3 h1 {
	color: #f3734c;
	font-size: 50px;
	font-weight: 600;
}

.aboutusSection4 h1 {
	margin: 5% 0 5% 0;
}

.aboutusSection4 .card {
	min-height: 300px;
	margin: 0 3% 5% 3%;
	transition: all 1s ease;
	border: 1px solid rgba(0, 0, 0, 0.08);
	border-radius: 10px;
}

.aboutusSection4 .card:hover {
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	transition: all 1s ease;
}

.aboutUsCardImage1 {
	padding-top: 5%;
	position: absolute;
	z-index: 2;
}

.aboutUsCardImage2 {
	padding-top: 11%;
	position: relative;
	z-index: 1;
}

/* Jobseeker listing */

.jobseekerListing .cardHead {
	padding: 14px 0 14px 5px;
	/* border: 1px solid #d9d8d8; */
	border-radius: 5px;
	background-color: #feeae4;
	/* color: white; */
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 20px;
}

.jobseekerListing .cardBody {
	background-color: #feeae4;
	/* margin: 6% 0 6% 0; */
	padding: 20px;
	align-items: center;
	border-radius: 5px;
}

.jobseekerListingForm button {
	margin-right: 10px;
	margin-bottom: 5px;
}

.jobseekerListing thead {
	padding: 14px 0 14px 5px;
	/* border: 1px solid #d9d8d8; */
	border-radius: 5px;
	background-color: #feeae4;
	/* color: white; */
	/* font-size: 20px; */
	font-weight: 500;
	margin-bottom: 20px;
	height: 58px;
}

.jobseekerListing td {
	padding: 20px 0 10px 9px;
}

.jobseekerLowerPart a {
	color: #f3734c;
}

.jobseekerLowerPart th {
	font-weight: 500;
	padding-bottom: 15px;
	font-size: 18px;
}

.jobseekerListingSelect .select__control {
	border-color: #fabaa7;
}

.jobseekerSearchWithNoResult {
	margin: 50px 20px 20px 0px;
}

/* How it Works */

.privacyPolicy {
	margin: 0 0 -50px 0;
	background-image: url("/public/Images/About-Us-slider.jpg");
}

.PPSection1 h1 {
	color: #422778;
	font-size: 35px;
	font-weight: 500;
}

.PPSection1 h6 {
	margin-top: 10px;
	font-weight: 300;
	font-size: 18px;
	color: rgba(37, 36, 42, 0.9) !important;
}

/* BlogPage */

.blogPageSection1 {
	background-image: url("/public/Images/About-Us-slider.jpg");
	padding: 3% 0 3% 0;
	margin: 0 0 3% 0;
}

.blogPageSection1 h1 {
	font-size: 40px;
	font-weight: 500;
}

.blogPageSection1 h6 {
	font-weight: 400;
	margin-top: 10px;
	font-size: 18px;
}

.blogPagination {
	text-align: right;
	padding-right: 20px;
}

.paginationDetail {
	margin: 10px 60px 10px 0;
}


/* BlogCard */

.blogCard {
	min-height: 100%;
	/* margin-bottom: 2%; */
}

.blogCard img {
	border-radius: 15px;
	min-height: 320px;
	max-height: 300px;
}

.blogCardBody {
	font-weight: 500;
}

.blogCardBody .card-title {
	min-height: 60px;
}

.blogCardBody a {
	text-decoration: none;
	color: #294a9c;
	font-weight: 400;
}

.blogPagination {
	margin: 15px 0 30px 0;
}

.blogCardFooter {
	border: none;
}

/* Dynamic blog page */



.DBPSection1 {
	background-image: url("/public/Images/About-Us-slider.jpg");
	padding: 3% 0 3% 0;
	margin: 0 0 3% 0;
}

.DBPSection2 {
	margin-bottom: 60px;
}


.DBPSection2 img {
	width: 70%;
	min-height: 500px;
	max-height: 700px;
	padding-bottom: 2%;
	border-radius: 15px;
}

.DBPSection1 h1 {
	font-size: 30px;
	font-weight: 500;
}

.DBPSection1 h6 {
	font-weight: 400;
	margin-top: 10px;
	font-size: 18px;
}

/* faq page */

.faqSection1 {
	background-image: url("/public/Images/FAQ-Slider.png");
	background-repeat: no-repeat;
	padding: 70px 0;
	margin: 0 0 3% 0;
}

.faqSection1 h6 {
	margin-bottom: 6%;
}

.faqSection1 .card {
	max-width: 40%;
	font-size: 500;
	box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
	border: 0;
}

.faqSection2 .error {
	box-shadow: none !important;
	outline: none;
	font-size: 18px;
	height: 50px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px solid red;
	border-radius: 3px;
}

.faqSection2 .input-error {
	box-shadow: none !important;
	outline: none;
	font-size: 18px;
	height: 50px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px solid red;
	border-radius: 3px;
}

.faqSection1 input {
	box-shadow: none !important;
	outline: none;
	font-size: 18px;
	height: 50px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px solid rgb(202, 202, 202);
	border-radius: 3px;
}

.faqSection2 select {
	box-shadow: none !important;
	outline: none;
	font-size: 18px;
	font-weight: 400;
	height: 50px;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px solid rgb(202, 202, 202);
	border-radius: 3px;
	height: 45px;
}

.faqSection1 textarea {
	box-shadow: none !important;
	outline: none;
	font-size: 19px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px solid rgb(202, 202, 202);
	border-radius: 3px;
}

.faqSection1 .card button {
	color: white;
	background-color: #294a9c;
}

.faqSection1 .card button:hover {
	color: white;
	background-color: #f3734c;
}

.faqSection2 {
	margin-top: 25px;
}


/* Contact Page */

.ContactSection1 {
	background-image: url("/public/Images/About-Us-slider.jpg");
	padding: 70px 0;
	margin: 0 0 3% 0;
}

.ContactSection1 h1 {
	margin-bottom: 10px;
}

.formCentere {
	width: 800px;
	margin: 0 auto;
}

.ContactSection3 .col-md-6 button {
	border-radius: 2px;
	color: white;
	background-color: #294a9c;
	float: left;
}

.ContactSection3 .col-md-6 button:hover {
	border-radius: 2px;
	color: white;
	background-color: #f3734c;
}

.ContactSection3 input {
	box-shadow: none !important;
	outline: none;
	font-size: 16px;
	font-weight: 400;
	opacity: 0.8;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px solid rgba(202, 202, 202, 0.6);
	border-radius: 6px;
	height: 48px;
}

.ContactSection3 .error {
	box-shadow: none !important;
	outline: none;
	font-size: 16px;
	font-weight: 400;
	opacity: 0.8;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px solid red;
	border-radius: 6px;
	height: 48px;
}

.ContactSection3 .input-error {
	box-shadow: none !important;
	outline: none;
	font-size: 16px;
	font-weight: 400;
	opacity: 0.8;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px solid red;
	border-radius: 6px;
	height: 48px;
}

.ContactSection3 textarea {
	box-shadow: none !important;
	outline: none;
	font-size: 19px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px solid rgb(202, 202, 202);
	border-radius: 3px;
}

/* Search job page */

.formItems .searchButtons {
	max-width: 80px;
	min-width: 120px;
}

.SJPSection1 {
	background-image: url("/public/Images/About-Us-slider.jpg");
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	/* margin-bottom: 5%; */
	background-repeat: no-repeat;
}

.SJPSection1 .formItems {
	background-color: #feeae4;
	margin: 6% 0 6% 0;
	padding: 2% 2% 2% 2%;
	align-items: center;
	display: flex;
	border-radius: 5px;
}

.formItems input {
	box-shadow: none !important;
	outline: none;
	font-size: 16px;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px solid #fabaa7;
	color: #797979;
	height: 50px;
	opacity: 1;
}

.formItems select {
	box-shadow: none !important;
	outline: none;
	font-size: 16px;
	opacity: 1;
	border: 1px solid #fabaa7;
	color: #797979;
	height: 50px;
}

.formItems button {
	color: white;
	background-color: #294a9c;
	min-width: 160px;
	height: 49px;
	font-weight: 500;
}

.formItems button:hover {
	color: #fff;
	background-color: #f3734c;
	/* border-color:#f3734c; */
}

.suggestions {
	background-color: #fff;
	border-radius: 8px;
	margin-top: 2px;
	padding: 10px;
	position: absolute;
	top: 69px;
	width: -webkit-fill-available;
	z-index: 99;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.suggestionsSmall {
	background-color: #fff;
	border-radius: 8px;
	margin-top: 2px;
	padding: 10px;
	position: absolute;
	top: 422px;
	width: 266px;
	z-index: 99;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.suggestionsSmall li {
	padding: 5px;
	cursor: pointer;
}

.suggestionsSmall li:hover {
	background-color: #FEEAE4;
	border-radius: 8px;
}

.suggestions li {
	padding: 5px;
	cursor: pointer;
}

.suggestions li:hover {
	background-color: #FEEAE4;
	border-radius: 8px;
}

.SJPSection2 {
	margin-bottom: 3%;
}

.eachLocation {
	display: flex;
}

.locationIcon {
	margin: 0 8px 0 0;
}

/* All category Page */

.allCategorySection2 .eachLink {
	margin-top: 25px;
	color: black;
}

.eachLink:hover {
	text-decoration: underline !important;
}

/* Company profile */

.newCompanyProfile {
	width: 100%;
}

.newCompanyProfile .uppersection {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.newCompanyProfile .vacanciesBtn {
	float: right;
	padding: 5px 20px;

	font-size: 100%;
	background-color: #EB602E;
	border-color: #EB602E;
}

.newCompanyProfile .vacanciesBtn:hover {
	background-color: #FFC548;
	border-color: #FFC548;

}

.newCompanyProfileLeftSide {
	display: inline-block;
	max-width: 200px;
}

.newCompanyProfileRightSide {

	margin: 20px 0;
}

.companyProfileCardLeft {
	min-height: 280px;
	position: relative;
	text-align: start;
	border: 1px solid #f6ac96;
	border-radius: 10px;
	padding: 30px;
	transition: 0.5s;
	margin-bottom: 25px;
}

.companyProfileCardRight {
	min-height: 280px;
	max-height: 280px;
	position: relative;
	text-align: start;
	border: 1px solid #f6ac96;
	border-radius: 10px;
	padding: 10px 20px 10px 20px;
	transition: 0.5s;
	/* margin-bottom: 25px; */
}

.companyProfileCardRightImage {
	border-radius: 10px;
}

.CPThirdSection {
	width: 100%;
	margin-top: 20px;
}

.CPTPart1 {
	display: inline-block;
	margin-right: 63px;
}

.CPTPart2 {
	display: inline-block;
}

.CPFourthSection {
	width: 100%;
	margin-top: 20px;
}

.CPFPart1 {
	display: inline-block;
	margin-right: 80px;
}

.CPFPart2 {
	display: inline-block;
}

.CPFifthSection {
	width: 100%;
	margin-top: 20px;
}

.CPFifthPart1 {
	display: inline-block;
	margin-right: 58px;
}

.CPFifthPart2 {
	display: inline-block;
}

.CPSixthSection {
	width: 100%;
	margin-top: 40px;
}

.CPSPart1 {
	display: inline-block;
	margin-right: 62px;
	margin-bottom: 20px;
}

.CPSPart2 {
	display: inline-block;
}

.companyProfileCardFooter {
	margin-top: 150px;
	width: 100%;
	display: inline-block;

}

.companyProfileCardFooter .companyProfileLocation {
	float: left;
	font-weight: 400;
}

.companyProfileCardFooter .companyProfileContact {
	float: right;
	font-weight: 400;
}

/* Privacy Policy */

.privacyPolicy {
	background-image: url("/public/Images/FAQ-Slider.png");
	background-repeat: no-repeat;
	padding: 70px 0;
	margin: 0 0 3% 0;
}

.privacyPolicy h6 {
	margin-bottom: 6%;
}

.privacyPolicy .card {
	max-width: 100%;
	font-size: 500;
	box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
	border: 0;
}

.privacyPolicy .error {
	box-shadow: none !important;
	outline: none;
	font-size: 17px;
	height: 45px;
	font-weight: 400;
	opacity: 0.8;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px solid red;
	border-radius: 3px;
}

.privacyPolicy .input-error {
	box-shadow: none !important;
	outline: none;
	font-size: 17px;
	height: 45px;
	font-weight: 400;
	opacity: 0.8;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px solid red;
	border-radius: 3px;
}

.privacyPolicy input {
	box-shadow: none !important;
	outline: none;
	font-size: 17px;
	height: 45px;
	font-weight: 400;
	opacity: 0.8;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px solid #fabaa7;
	border-radius: 3px;
}

.privacyPolicy select {
	box-shadow: none !important;
	outline: none;
	font-size: 17px;
	height: 45px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px solid #fabaa7;
	border-radius: 3px;
	height: 45px;
}

.privacyPolicy textarea {
	box-shadow: none !important;
	outline: none;
	font-size: 18px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px solid #fabaa7;
	border-radius: 3px;
}

.privacyPolicy .card button {
	color: white;
	background-color: #294a9c;
}

.privacyPolicy .card button:hover {
	color: white;
	background-color: #f3734c;
}

/* Employer login */

.employerLogin .card {
	box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
	border: none;
	margin: 6% 5% 10% 5%;
}



.employerLogin .leftSection img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.employerLogin form {
	padding: 0 13% 0 13%;
}

.employerLogin input {
	border: none;
	outline: none;
	font-size: 17px;
	height: 50px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px dashed #a7a6a6;
}

.employerLogin input:hover {
	border: 1px dashed #CBC3E3;
	box-shadow: 10px 14px 22px rgba(148, 95, 248, 0.3);
}

.passwordBox {
	position: relative;
}

.passwordVisibility {
	position: absolute;
	right: 13px;
	top: 11px;
}

/* Jobseeker login */
.jobseekerLogin .card {
	box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
	border: none;
	margin: 10% 5% 10% 5%;

}

.jobseekerLogin .leftSection img {
	height: 100%;
	object-fit: cover;
	width: 100%;
}

.jobseekerLogin form {
	padding: 0 13% 0 13%;
}

.jobseekerLogin input {
	border: none;
	outline: none;
	font-size: 17px;
	height: 50px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px dashed #a7a6a6;
}

.jobseekerLogin input:hover {
	border: 1px dashed #CBC3E3;
	box-shadow: 10px 14px 22px rgba(148, 95, 248, 0.3);
}

/* Employers login */
/* Side bar */

.SidebarSection1 .SBHeader h3 {
	color: black;
	padding-top: 6px;
}

.SidebarSection2 .SBHeader h3 {
	color: black;
	padding-top: 6px;
}

.SidebarSection3 .SBHeader h3 {
	color: black;
	padding-top: 6px;
}

.bodyItem {
	display: block;
	margin: 20px 0;
	color: grey;
}

.reCaptchaLogin {
	margin: 20px 0 10px 0;
}


.SidebarImages {
	display: inline-block;
	position: relative;
	margin: 0;
	width: 47px;
	height: 44px;
	text-align: center;
	vertical-align: middle;
}

.SidebarImages .imageChild1 {
	position: relative;
	z-index: 1;
	top: 0;
	left: 0;
}

.SidebarImages .imageChild2 {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 4px;
}

.menuTitle {
	display: inline-block;
	padding-left: 20px;
	vertical-align: middle;
}

/* for mobile view */
.sidebarEachHeader {
	width: 100%;
	display: inline-block;
}

.sidebarEachHeader .sidebarPlusLink {
	margin-bottom: 0;
}

.sidebarEachHeader h3 {
	display: inline-block;
}

.sidebarPlusLink {
	color: black;
	/* padding: 0px 0 14px 0px; */
	font-size: 25px;
	float: right;
	/* margin-right: 10px; */
	margin-bottom: 35px;
}

/* Employer Registration */

.EPRegistrationBottomText {
	margin-bottom: 10px;
	text-align: center;
}

/* Forgot Password */
.forgotPasswordCard {
	width: 50%;
	margin: 0px auto;
}

.forgotPasswordProcessTxt {
	margin: 15px 0 20px 0;
	font-weight: bold;
}


/* Create job */

.createJob {
	margin-top: 3%;
}

.createJob h3 {
	color: #f3734c;
}

.createJob input {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px dashed #a7a6a6;
	padding: 20px 20px;
}

.createJob input:hover {
	border: 1px dashed #f3734c;
	box-shadow: 4px 14px 28px 0 rgba(242, 107, 73, 0.3);
}

.createJob textarea {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px dashed #a7a6a6;
	padding: 5px 20px;
}

.createJob textarea:hover {
	border: 1px dashed #a46cc4;
	box-shadow: 4px 14px 28px 0 rgba(242, 107, 73, 0.3);
}

.createJob select {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px dashed #a7a6a6;
	padding: 5px 20px;
	color: black;
}

.createJob select:hover {
	border: 1px dashed #f3734c;
	box-shadow: 4px 14px 28px 0 rgba(242, 107, 73, 0.3);
}

.createJob .upper {
	width: 100%;
	margin-bottom: 10px;
}

.createJob .uppLeft {
	display: inline-block;
	margin-right: 160px;
}

.createJob .uppRight {
	display: inline-block;
}


/* Manage job */

.manageJob {
	margin-top: 3%;
}

.MJHeader h3 {
	color: #3c948c;
	display: inline-block;
}

.MJHeader button {
	float: right;
}

.manageJobActionButton .btn-secondary {
	background-color: #a32a2a;
	border: none;
	margin: 0 3px;
	padding: 2px 2px;
	font-size: 14px;
	border-radius: 5px;
	color: white;
	float: inline-start;
}

.manageJobActionButton .btn-primary {
	background-color: green;
	border: none;
	margin: 0 3px;
	padding: 2px 2px;
	font-size: 14px;
	border-radius: 5px;
	color: white;
	float: inline-start;
}

.manageJobPagination {
	margin-top: 40px;
}

/*---------------accdetail--------------*/
.MyProfileTopSection {
	width: 100%;
	display: inline-flex;
	/* background: #478a99; */
	padding: 110px 0 20px;
	background-size: cover;
	position: relative;
	overflow: hidden;
}

.MyProfileImg {
	width: 140px;
	height: 140px;
	display: inline-block;
	position: relative;
	border-radius: 50%;
	overflow: hidden;
	vertical-align: middle;
	margin-right: 25px;
	background: transparent;
	box-shadow: 0 0 5px 0 #9e9e9c;
}

.MyProfileImg img {
	width: 100%;
	height: 100%;
	object-fit: contain
}

.MyProfileDetails {
	display: inline-block;
	vertical-align: middle;
	background: rgba(0, 0, 0, 0.3);
	padding: 10px 25px;
	box-shadow: 2px 3px 16px 0 rgba(0, 0, 0, 0.2);
}

.MyProfileDetails h2 {
	display: block;
	font-size: 24px;
	font-style: normal;
	color: #fff;
	text-transform: capitalize;
}

.MyProfileDetails h6 {
	display: block;
	font-size: 15px;
	font-style: normal;
	color: #fff;
	font-weight: 400;
	margin-bottom: 5px;
}

.MyProfileUpgratePlan {
	float: left;
	width: 100%;
	padding-top: 7px;
}

.MyProfileUpgratePlan span {
	color: #fff;
	display: inline-block;
	font-size: 16px;
	font-weight: 400;
	vertical-align: middle;
}

.MyProfileUpgratePlan .btn-primary {
	color: white;
	background-color: #f3734c;
	font-weight: 500;
	border: none;
	font-size: 17px;
	padding: 7px 15px 9px;
	border-radius: 3px;
}

.UploadBackBg {
	position: absolute;
	right: 0;
	bottom: 0;
	border-radius: 0;
	background-color: #294a9c;
	border: none;
	padding: 8px 25px
}

.EditJobseekerProfileTag {
	float: right
}

.EditJobseekerProfileTag a {
	color: rgba(79, 81, 82, 0.8);
	font-size: 18px;
	margin-left: 9px;
}



.JobseekerProfileActive {
	display: inline-block;
	width: 40%;
}

.JobseekerProfileActive .ActiveButton {
	display: inline-block;
}

.CreatedDate {
	display: inline-block;
	vertical-align: top;
	padding: 2px 0 0 10px;
	color: #34b9ce;
	font-size: 15px;
}

.JobseekerProfileSearch {
	float: right;
}

.Calcultn {
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	margin-left: 25px;
}

.LeftSideCalu {
	float: left;
	margin-top: 8px;
}

.LeftSideCalu i {
	color: #b3b3b3;
	float: left;
	margin-right: 5px;
	font-size: 26px;
}

.RightSideCalu {
	float: right;
	padding-left: 5px;
}

.RightSideCalu h4 {
	font-size: 22px;
	font-style: normal;
	color: #7d7d7d;
}

.RightSideCalu h6 {
	font-size: 12px;
	font-style: normal;
	color: #ababab;
}

.JobseekersTabsBx {
	display: inline-block;
	width: 100%;
	margin-top: 30px;
}

.JobseekersTabs .nav-item {
	margin-right: 10px;
}

.JobseekersTabs .nav-link {
	border: 1px #ddd solid;
	background: transparent;
	color: #333;
	font-size: 18px;
	position: relative;
	border-bottom: none;
	border-radius: 15px 15px 0 0;
}

.JobseekersTabs .nav-link.active {
	color: #294a9c;
}

.JobseekersTabs {
	border-bottom: 1px #ddd solid;
	padding-left: 20px;
}

/*.JobseekersTabs .nav-link:before{ position: absolute; left: 0; bottom: -1px; content: ""; background: #ddd; width: 100%; height: 3px}*/
.JobseekersTabs .nav-link.active:before {
	position: absolute;
	left: 0;
	bottom: -1px;
	content: "";
	background: #fff;
	width: 100%;
	height: 3px
}

.JobseekersTabsContent {
	padding: 20px 0;
}

.JobseekersContentTop {
	display: inline-block;
	width: 100%;
	background: #f7f7f7;
	padding: 20px
}

.JobseekersContentTop ul {
	display: inline-block;
	width: 70%;
}

.JobseekersContentTop ul li {
	display: inline-block;
	padding-right: 10px;
	text-align: center
}

.JobseekersContentTop ul li span {
	display: block;
	font-size: 14px;
	color: #6c6b6b;
	margin-bottom: 10px;
}

.JobseekersContentTop ul li a {
	display: inline-block;
	min-width: 44px;
	height: 44px;
	line-height: 42px;
	border: 1px solid #a3a3a3;
	background: #fff;
	border-radius: 3px;
	font-size: 15px;
	color: #6c6b6b;
}

.JobseekersContentSearch {
	float: right;
	width: 28%;
	margin-top: 30px;
	position: relative
}

.JobseekersContentSearch .form-control {
	height: 46px;
	border-radius: 0;
	padding-right: 35px;
}

.JobseekersContentSearch span {
	position: absolute;
	right: 11px;
	top: 10px;
	opacity: 0.5;
}

.JobseekersSearchContent {
	display: inline-block;
	width: 100%;
	padding: 15px
}

.no_found {
	text-align: center;
	padding: 30px 0;
	font-size: 17px;
}

.JobseekersSearchContent .card-title {
	display: flex;
	justify-content: space-evenly;
}

.JobseekersTabsDetails {
	display: inline-block;
	width: 100%;
	background: #f7f7f7;
	padding: 20px;
}

.JobseekersTabsDetailsList {
	display: inline-block;
	width: 100%;
	margin-bottom: 13px;
}

.JobseekersTabsDetailsList label {
	width: 30%;
	color: #4a4948;
	font-size: 15px;
	margin-top: 6px;
	vertical-align: top;
	font-weight: 600;
}

.JobseekersTabsDetailsList span {
	width: 68%;
	color: #4a4948;
	font-size: 15px;
	display: inline-block;

}

.JobseekersTabsDetailsList span em {
	font-size: 15px;
	color: #303030;
	font-style: normal;
	line-height: 35px;
}

.selectFormInnerAccDetails {
	box-shadow: none !important;
	outline: none;
	font-size: 16px;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px dashed #a7a6a6;
	color: #797979;
	height: 45px;
	opacity: 1;
}

.usernameInnerAccDetails p {
	color: #34b9ce;
}

/* .EPEJimageViewer {
    margin-top: 20px;
	width: 200px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	border-radius: 5px;
	padding: 10px;
}
.EPEJimageViewer:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
} */
.EPEJimageViewer {
	margin-top: 20px;
	width: 200px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	border-radius: 5px;
	padding: 0;
	height: 200px;
	overflow: hidden;
	position: relative;
}

.EPEJimageViewer img {
	width: 100%;
	max-height: 100%;
	object-fit: cover;
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	top: 0;
	margin: auto;
	padding: 5px;
}

.manageJobInternalpageImage {
	width: 200px;
	height: 200px;
	padding: 10px;
	border: 1px solid white;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	border-radius: 5px;
}

.manageJobInternalpageImage:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.sendMailManageJobs {
	color: white;
	background-color: #294a9c;
	font-weight: 500;
	border: 2px #294a9c solid;
	font-size: 16px;
	padding: 9px 25px;
	border-radius: 3px;
	float: right;
	margin-top: 10px;
}

.sendMailHeader {
	background-color: #294a9c;
	color: white;
	font-weight: 500;
	padding: 15px;
	text-align: left;
	margin-bottom: 10px;
}

.sendMailInputManageJobs {
	margin-top: 30px;
	padding: 0 10px;
}

.sendMailInputManageJobs input {
	height: 60px;
	border: 1px dashed #a7a6a6;
}

.sendMailManageJobsButtons {
	margin: 15px 0px;
	text-align: end;
	padding: 0 10px;
}

.sendMailManageJobsButtons button {
	margin-left: 10px;
}

/* Payment History */

.paymentHistory {
	margin-top: 3%;
}

.paymentHistory h3 {
	color: #3c8cc4;
}

.paymentHistory .paymentButton {
	color: white;
	background-color: #294a9c;
	font-weight: 500;
	border: 2px #294a9c solid;
	font-size: 16px;
	padding: 9px 25px;
	border-radius: 3px;
	min-width: -webkit-fill-available;
}

.paymentHistoryPagination {
	margin-top: 20px;
}


/* Favourite List */

.favouriteList {
	margin-top: 3%;
}

.favouriteList h3 {
	color: #dc445c;
}


/* Favourite List Profile */

.FavouriteListProfile {
	margin-top: 3%;
}

.FLPHeaderCard {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	border: 1px solid var(--border, #e0e0e0);
	min-height: 210px;
}

/* .emailValueFLP{
	padding-top: 5px;
}
.contactValueFLP {
	padding-top: 5px;
} */

.viewLink {
	padding-left: 8px;
}

.FLPLeftSideHeader .ImageSpace {
	border-radius: 50%;
	width: 200px;
	height: 200px;
	background-color: #cfcccc;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.FLPImage {
	border-radius: 50%;
	height: 210px;
	width: 220px;
	padding: 0px;
}

.FLPRightSideHeader {
	padding: 20px 0 20px 23px;
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.FLPRightSideHeader h4 {
	display: inline-block;
	color: #f3734c;
	padding-top: 6px;
}

.FavouriteListProfile h4 {
	color: #f3734c;
}

.FLPlink {
	color: #f3734c;
	font-weight: 500;
}

.FLPRightSideBody {
	margin: 0 0 30px 25px;
}

.FLPRightSideBody h6 {
	margin-top: 7px;
}

.listProfileParent {
	margin: 4px 0;
}

.listProfileChild {
	margin-top: 8px;
}

.FLPEducationTable {
	border: 1px solid var(--border, #e0e0e0);
	border-radius: 10px;
	padding: 20px;
	margin: 20px 0;
	width: 100%;
	overflow-y: auto;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.FLPNAText {
	margin: 15px 0 25px 0;
}

.FLPAboutCandidateHeader {
	margin-top: 20px;
}

.FLPAboutCandidateBody {
	margin: 8px 0;
}

.FLPCandidateCertificateHeader {
	margin-top: 20px;
}

.FLPCandidateCertificateBody {
	margin: 15px 0;
}

.FLPCandidateCertificateImage {
	border: 1px solid #dddddd;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	margin: 15px 10px;
	max-width: 270px;
}

.documentBox {
	width: fit-content;
	margin: 17px 0 0 12px;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	cursor: pointer;
	padding: 13px;
}

.favListPagination {
	margin-top: 20px;
}

/* Import Jobseeker */
.importJobseeker {
	margin-top: 3%;
}

.importJobseeker h3 {
	color: #9474c4;
}

.importJobseeker .sampleDownload {
	float: right;

}

.importJobseeker .sampleDownload p {
	cursor: pointer;

}

.importJobseeker input {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px dashed #a7a6a6;
	padding: 22px 20px;
}

.importJobseeker input:hover {
	border: 1px dashed #f3734c;
	box-shadow: 4px 14px 28px 0 rgba(242, 107, 73, 0.3);
}


/* Mail History */

.mailHistory {
	margin-top: 3%;
}

.mailHistory h3 {
	color: #4864ac
}

.mailHistoryPagination {
	margin-top: 20px;
}

/* Mail detail */

.mailDetail {
	line-height: 40px;
}

.mailDetailBx {
	background-color: rgb(240, 240, 238);
	padding: 15px;
	border-radius: 10px;

}

.mailDetail h5 {
	line-height: 40px;
	margin-bottom: 20px;
}

.emailImageAttachments {
	margin-top: 24px;
	/* border-radius: 8px; */
	/* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.emailImageAttachments .mailAttachment {
	width: fit-content;
	margin-top: 20px;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

/* jobseekers */
.jobseekersListingNoData {
	margin-top: 150px;
}

/* My profile */

.myProfile {
	margin-top: 3%;
}

.myProfile h3 {
	color: #a46cc4;
}

.myProfile .form-outline {
	margin: 0 20px 0 20px;
}

.myProfile input {

	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px dashed #a7a6a6;
	padding: 0 20px;
	box-shadow: none;
}

.myProfile input:hover {
	border: 1px dashed #a46cc4;
	box-shadow: #a46cc4 10px 14px 22px;
}

.myProfile select {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px dashed #a7a6a6;
}

.myProfile select:hover {
	border: 1px dashed #a46cc4;
	box-shadow: #a46cc4 10px 14px 22px;
}


/* new code for my profile */

.myProfileEmployerBody {
	margin-top: 20px;
}

.myProfileEmployerBody .card-title h4 {
	font-size: 21px;
}

.myProfileEmployerBody .cardOne {
	padding: 20px;
	margin-bottom: 15px;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.myProfileEmployerBody .cardOne:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.myProfileEmployerBody .cardOne .card-title {
	width: 100%;
}

.myProfileEmployerBody .cardOne .card-title h4 {
	display: inline-block;

}

.myProfileEmployerBody .cardOne .card-title p {
	float: right;
	font-size: 15px;
	color: #555454;
}

.myProfileEmployerBody .cardOne .profileImage {
	width: 100px;
	max-width: 100px;
	border-radius: 50%;
}

.myProfileEmployerBody .nameSection {
	width: 100%;
	margin: 15px 0;
}

.myProfileEmployerBody .nameSection p {
	display: inline-block;
	font-weight: 500;
	padding-top: 5px;
	font-size: 17px;
}


.myProfileEmployerBody .cardOneEditButton {
	padding: 5px 18px;
	background-color: rgb(233 219 244);
	border: none;
	border-radius: 100px;
	/* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
	float: right;
	font-size: 15px;
	color: black;
}

.myProfileEmployerBody .cardTwo {
	padding: 20px;
	margin-bottom: 15px;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.myProfileEmployerBody .cardTwo:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.myProfileEmployerBody .cardTwo .card-title {
	width: 100%;
}

.myProfileEmployerBody .cardTwo .card-title h4 {
	display: inline-block;
}

.myProfileEmployerBody .cardTwoPrimaryButton {
	margin: 20px 0;
	padding: 5px 25px;
	background-color: f2f2f4;
	color: rgb(63 105 223);
	border-radius: 100px;
	border: none;
	font-size: 12px;
}

.myProfileEmployerBody .cardTwoChangeEmailButton {
	margin-top: 20px;
	padding: 5px 25px;
	/* background-color: rgb(176, 176, 177); */
	color: rgb(63 105 223);
	border-radius: 10px;
	border: 1px solid grey;
}

.myProfileEmployerBody .cardTwoChangeButton {
	padding: 8px 18px;
	background-color: rgb(233 219 244);
	border: none;
	border-radius: 100px;
	/* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
	font-size: 15px;
	float: right;
	color: black;
}

.myProfileEmployerBody .cardThree {
	padding: 20px;
	margin-bottom: 15px;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.myProfileEmployerBody .cardThree:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.myProfileEmployerBody .cardThree .card-title {
	width: 100%;
}

.myProfileEmployerBody .cardThree .card-title h4 {
	display: inline-block;
}

.myProfileEmployerBody .cardThreeChangeButton {
	padding: 8px 18px;
	background-color: rgb(233 219 244);
	border: none;
	border-radius: 100px;
	/* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
	font-size: 15px;
	color: black;
}


.myProfileEmployerBody .cardFour {
	padding: 20px;
	margin-bottom: 15px;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.myProfileEmployerBody .cardFour:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.myProfileEmployerBody .cardFour .card-title {
	width: 100%;
}

.myProfileEmployerBody .cardFour .card-title h4 {
	display: inline-block;
}

.myProfileEmployerBody .cardFour .card-title p {
	float: right;
	font-size: 15px;
}

.myProfileEmployerBody .cardFive {
	padding: 20px;
	margin-bottom: 15px;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.myProfileEmployerBody .cardFive:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.myProfileEmployerBody .cardFive .card-title {
	width: 100%;
}

.myProfileEmployerBody .cardFive .card-title h4 {
	display: inline-block;
}

.myProfileEmployerBody .cardFive .card-title p {
	float: right;
	font-size: 15px;
}

.myProfileEmployerBody .cardSix {
	padding: 20px;
	margin-bottom: 15px;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.myProfileEmployerBody .cardSix:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.myProfileEmployerBody .cardSix .card-title {
	width: 100%;
}

.myProfileEmployerBody .cardSix .card-title h4 {
	display: inline-block;
}

.myProfileEmployerBody .cardSix .card-title p {
	float: right;
	font-size: 15px;
}

.myProfileEmployerBody .cardSix .planNameBox ul {
	width: 100%;
	margin: 15px 0;
}

.myProfileEmployerBody .cardSix .planNameBox .list1 {
	display: inline-block;
	padding-top: 8px;
}

.myProfileEmployerBody .cardSix .planNameBox .list2 {
	float: right;
}

.myProfileEmployerBody .cardSix .planNameBox .changePlanButton {
	padding: 8px 18px;
	background-color: rgb(233 219 244);
	border: none;
	border-radius: 100px;
	/* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
	font-size: 15px;
	color: black;
}

.myProfileEmployerBody .cardSix .planDetailBox {
	margin-top: 15px;
}

.myProfileEmployerBody .cardSix .featuresDescription {
	margin: 20px 0 0 0;
}

.noPlanAvl {
	width: 100%;
	margin: 15px 0;
}

.noPlanAvl p {
	display: inline-block;
	padding-top: 5px;
}

.noPlanAvlLink {
	float: right;
}

.certificatesSection {
	margin: 0 0 20px 0;
}

.certificatesSection p {
	padding-right: 30px;
}

.eachDocumentMyprofile {
	display: flex;
	cursor: pointer;
}

.eachDocumentMyprofile i {
	padding-top: 3px;
}

.eachCertificateMyprofile i {
	min-height: 120px;
}

.planDetailsJobseeker {
	margin-top: 15px;
}

.planDetailsJobseeker .parent {
	display: flex;
	margin-bottom: 6px;
}

.planDetailsJobseeker .parent .child1 {
	min-width: 39%;
}

.JobseekerCertificatesDetails ul {
	display: flex;
	width: 100%;
}

.JobseekerCertificatesDetails ul li {
	/* display: inline-block; */
	background: #e6e6e6;
	padding: 10px;
	border-radius: 9px;
	position: relative;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.7);
	margin-right: 10px;
	width: 20%;
	min-height: 40px;
}

.JobseekerCertificatesDetails ul li i {
	display: inline-block;
	vertical-align: top;
	margin-right: 5px;
}

.JobseekerCertificatesDetails ul li span {
	display: inline-block;
	vertical-align: middle;
}

.certificatesSection {
	margin: 0 0 20px 0;
}

.certificatesSection p {
	padding-right: 30px;
}


/* Edit profile */

.editProfile {
	margin-top: 3%;
}

.editProfile h3 {
	color: #4cb4b4;
}

/* .editProfile .form-outline {
	margin: 0 20px 0 0;
} */

.editProfile input {
	color: black;
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px dashed #a7a6a6;
	padding: 20px 20px;
}

.editProfile select {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	color: black;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px dashed #a7a6a6;
}

.editProfile select:hover {
	border: 1px dashed #4cb4b4;
	box-shadow: #4cb4b4 10px 14px 22px;
}

/* Membership Plan */
.membershipPlan {
	margin-top: 3%;
}

.membershipPlan h3 {
	color: #3c8cc4;
}

/* Change Password */

.changePassword {
	margin-top: 3%;
}

.changePassword h3 {
	color: #54acec;
}

.changePassword .form-outline {
	margin: 0 20px 0 20px;
}

.changePassword input {

	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px dashed #a7a6a6;
	padding: 5px 20px;
}

.changePassword input:hover {
	border: 1px dashed #54acec;
	box-shadow: #54acec 10px 14px 22px;
}

/* Change logo */

.changeLogo {
	margin-top: 3%;
}

.changeLogo h3 {
	color: #dc8474;
}

.changeLogo .form-outline {
	margin: 0 20px 0 20px;
}

.changeLogo input {

	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px dashed #a7a6a6;
}

.changeLogo input:hover {
	border: 1px dashed #dc8474;
	box-shadow: #dc8474 10px 14px 22px;
}

.changeLogoButtons {
	/* margin-left: 23px; */
}

.CLPanelRight {
	padding: 0 30px;
}

.ChangePhotoEmployers {
	border: 1px solid white;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	width: 170px;
	height: 170px;
	padding: 10px;
	border-radius: 5px;
}

.ChangePhotoEmployers img {
	width: 150px;
	height: 150px;
	border-radius: 5px;
}

/*----------------New-Design-------*/
.SidebarCreatJobBg {
	background-image: url("/public/Images/employerSide/bg1.png");
}

.SidebarManageJobBg {
	background-image: url("/public/Images/employerSide/bg2.png");
}

.SidebarPaymentJobBg {
	background-image: url("/public/Images/employerSide/bg3.png");
}

.SidebarFavouriteJobBg {
	background-image: url("/public/Images/employerSide/bg4.png");
}

.SidebarImportJobBg {
	background-image: url("/public/Images/employerSide/bg5.png");
}

.SidebarMailHistoryBg {
	background-image: url("/public/Images/employerSide/bg6.png");
}

.SidebarMyProfileBg {
	background-image: url("/public/Images/employerSide/bg7.png");
}

.SidebarEditProfileBg {
	background-image: url("/public/Images/employerSide/bg8.png");
}

.SidebarChangePassBg {
	background-image: url("/public/Images/employerSide/bg9.png");
}

.SidebarChangeLogoBg {
	background-image: url("/public/Images/employerSide/bg10.png");
}

.SidebarLogOutBg {
	background-image: url("/public/Images/employerSide/bg11.png");
}

.SidebarDeleteAccBg {
	background-image: url("/public/Images/employerSide/bg12.png");
}

.SidebarImages img {
	filter: brightness(0%);
	opacity: 0.7;
}

.SidebarCreatJob:hover {
	color: #f3734c;
}

.SidebarManageJob:hover {
	color: #4d9b93;
}

.SidebarPaymentJob:hover {
	color: #4682b0;
}

.SidebarFavouriteJob:hover {
	color: #dc425e;
}

.SidebarImportJob:hover {
	color: #6e5595;
}

.SidebarMailHistory:hover {
	color: #4663ae;
}

.SidebarMyProfile:hover {
	color: #a268bf;
}

.SidebarEditProfile:hover {
	color: #50afb4;
}

.SidebarChangePass:hover {
	color: #51a8eb;
}

.SidebarChangeLogo:hover {
	color: #dd8678;
}

.SidebarLogOut:hover {
	color: #a268bf;
}

.SidebarDeleteAcc:hover {
	color: #d44e84;
}

.bodyItem.Active {
	font-weight: 500;
	border-radius: 5px;
}

.bodyItem.Active .SidebarImages {
	background-image: none;
}

.bodyItem.Active .SidebarImages {
	background-image: none;
	background-color: #fff;
	border-radius: 5px;
	position: relative;
}

.bodyItem.Active .SidebarImages img {
	filter: brightness(100%);
	opacity: 1;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	margin: auto;
}

.SidebarMyProfile.Active {
	background: #f7ebfd;
	color: #a268bf;
}

.bodyItem.Active .SidebarMyProfileBg {
	border: 1px #a268bf dashed;
}


.SidebarCreatJob.Active {
	background: #fcf1ed;
	color: #f3734c;
}

.bodyItem.Active .SidebarCreatJobBg {
	border: 1px #f3734c dashed;
}

.SidebarManageJob.Active {
	background: #dcf4f2;
	color: #4d9b93;
}

.bodyItem.Active .SidebarManageJobBg {
	border: 1px #4d9b93 dashed;
}

.SidebarPaymentJob.Active {
	background: #e4edf5;
	color: #4682b0;
}

.bodyItem.Active .SidebarPaymentJobBg {
	border: 1px #4682b0 dashed;
}

.SidebarFavouriteJob.Active {
	background: #f9ebee;
	color: #dc425e;
}

.bodyItem.Active .SidebarFavouriteJobBg {
	border: 1px #dc425e dashed;
}

.SidebarImportJob.Active {
	background: #d4c1f1;
	color: #916ace;
}

.bodyItem.Active .SidebarImportJob {
	border: 1px #916ace dashed;
}

.SidebarMailHistory.Active {
	background: #dce4f9;
	color: #4663ae;
}

.bodyItem.Active .SidebarMailHistoryBg {
	border: 1px #4663ae dashed;
}

.SidebarEditProfile.Active {
	background: #d4f6f8;
	color: #50afb4;
}

.bodyItem.Active .SidebarEditProfileBg {
	border: 1px #50afb4 dashed;
}

.SidebarChangePass.Active {
	background: #dceefc;
	color: #51a8eb;
}

.bodyItem.Active .SidebarChangePassBg {
	border: 1px #51a8eb dashed;
}

.SidebarChangeLogo.Active {
	background: #fde5e1;
	color: #dd8678;
}

.bodyItem.Active .SidebarChangeLogoBg {
	border: 1px #dd8678 dashed;
}

.SidebarDeleteAcc.Active {
	background: #f5adca;
	color: #d44e84;
}

.bodyItem.Active .SidebarDeleteAccBg {
	border: 1px #d44e84 dashed;
}

.myProfile .DashBoardInputBx input:hover {
	border: 1px dashed #a26fbd;
	box-shadow: 0px 0px 0px 0 rgba(162, 111, 189, 0.5);
}

.DashBoardInputBx {
	position: relative;
}

.DashBoardInputBx .form-label {
	position: absolute;
	left: 12px;
	top: -13px;
	z-index: 9;
	background: #fff;
	padding: 0 8px;
	color: rgba(0, 0, 0, 0.6);
}

.DashBoardInputBx input:hover {
	border: 1px dashed #a26fbd;
	box-shadow: 4px 14px 28px 0 rgba(162, 111, 189, 0.5);
}

.DashBoardCreatBx input:hover {
	border: 1px dashed #f3734c;
	box-shadow: 4px 14px 28px 0 rgba(242, 107, 73, 0.3);
}

.TopHaddingTitle h3 i {
	display: inline-block;
	vertical-align: top;
}

.TopHaddingTitle h3 span {
	vertical-align: top;
	display: inline-block;
	padding: 5px 0 0 5px;
}

.TopHaddingTitle .button1 {
	font-size: 14px;
}

/* .defaultnavbar {box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);padding: 17px 0;} */
.MJBody .table tr {
	-webkit-transition: .3s ease;
	-o-transition: .3s ease;
	transition: .3s ease;
}

.MJBody .table tr.TrDefoult:hover {
	border: 0px solid #c2d2d1;
	box-shadow: 4px 14px 28px 0 rgba(102, 166, 159, 0.3);
}

.TrFirst th {
	padding: 16px 20px;
	font-weight: normal;
	color: rgba(0, 0, 0, 0.8);
	font-size: 16px;
}

.TrDefoult td {
	padding: 25px 16px;
	font-weight: normal;
	color: rgba(70, 70, 70, 0.8);
	font-size: 16px;
}

.ActiveButton input[switch] {
	display: none;
}

.ActiveButton input[switch]+label {
	font-size: 1em;
	line-height: 1;
	width: 100px;
	height: 30px;
	background-color: #ddd;
	background-image: none;
	border-radius: 2rem;
	padding: 5px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	position: relative;
	transition: all 0.1s ease-in-out;
}

.ActiveButton input[switch]+label::before {
	color: #b7b7b7;
	content: attr(data-off-label);
	display: block;
	font-weight: 400;
	font-size: 16px;
	position: absolute;
	right: 8px;
	margin: 0;
	top: 6px;
	text-align: center;
	min-width: 1.66666667rem;
	overflow: hidden;
	transition: all 0.1s ease-in-out;
}

.ActiveButton input[switch]+label::after {
	content: '';
	position: absolute;
	left: 6px;
	background-color: #f7f7f7;
	box-shadow: none;
	border-radius: 2rem;
	height: 22px;
	width: 22px;
	transition: all 0.1s ease-in-out;
	top: 4px;
}

.ActiveButton input[switch]:checked+label {
	background-color: #f3734c;
}

.ActiveButton input[switch]:checked+label:before {
	color: #fff;
	content: attr(data-on-label);
	right: auto;
	left: 11px;
}

.ActiveButton input[switch]:checked+label:after {
	left: 72px;
	background-color: #f7f7f7;
}

.ActiveButton input[switch="bool"]+label {
	background-color: #dde3f1;
}

.ActiveButton input[switch="bool"]+label:before {
	color: rgba(36, 37, 42, 0.8) !important;
	;
}

.ActiveButton input[switch="bool"]:checked+label {
	background-color: #ee6562;
}

.ActiveButton input[switch="bool"]:checked+label:before {
	color: #fff !important;
}

.ActiveButton input[switch="default"]:checked+label {
	background-color: #a2a2a2;
}

.ActiveButton input[switch="default"]:checked+label:before {
	color: #fff !important;
}

.ActiveButton input[switch="success"]:checked+label {
	background-color: #BCE954;
}

.ActiveButton input[switch="success"]:checked+label:before {
	color: #fff !important;
}

.ActiveButton input[switch="warning"]:checked+label {
	background-color: gold;
}

.ActiveButton input[switch="warning"]:checked+label:before {
	color: #fff !important;
}

.PHBody .table tr.TrDefoult:hover {
	border: 0px solid #d1e0ec;
	box-shadow: 4px 14px 28px 0 rgba(97, 145, 185, 0.3);
}

.TrActions .btn {
	margin: 0 3px;
	padding: 2px 6px;
	font-size: 14px;
	border-radius: 5px;
}

.TrActions .btn {
	background-color: #294a9c;
	border: none;
}

.TrActions .btn-secondary {
	background-color: #f3734c;
	border: none;
}

.FLBody .table tr.TrDefoult:hover {
	border: 0px solid #fdd1d8;
	box-shadow: 4px 14px 28px 0 rgba(224, 94, 115, 0.3);
}

.MHBody .table tr.TrDefoult:hover {
	border: 0px solid #c2d0f4;
	box-shadow: 4px 14px 28px 0 rgba(70, 99, 174, 0.3);
}

.editProfile input:hover {
	border: 1px dashed #4cb4b4;
	box-shadow: 4px 14px 28px 0 rgba(76, 180, 180, 0.3);
}

.changeLogo .form-control[type="file"],
.editProfile .form-control[type="file"] {
	padding: 20px;
}

.RedStar {
	color: #f00;
}

.changePassword input:hover {
	border: 1px dashed #54acec;
	box-shadow: 4px 14px 28px 0 rgba(84, 172, 236, 0.3);
}

.changeLogo input:hover {
	border: 1px dashed #dc8474;
	box-shadow: 4px 14px 28px 0 rgba(220, 132, 116, 0.3);
}

/* .form-outline {
	margin: 0 0 0 20px;
} */
.ChoosPlanBx {
	display: inline-block;
	width: 100%;
	border: 1px dashed #d0cece;
	padding: 17px 20px;
	height: 70px;
	border-radius: 5px;
	transition: all .3s ease-in-out;
}

.ChoosPlanBx .button1 {
	background: #294a9c;
	border: none;
	font-size: 14px;
	margin-top: 10px;
	margin-left: 0px;
	padding: 7px 15px;
	font-weight: 400;
}

.ChoosPlanBx:hover {
	border: 1px dashed #a26fbd;
	box-shadow: 4px 14px 28px 0 rgba(162, 111, 189, 0.5);
}

.AvailablePlanBx {
	display: inline-block;
	width: 100%;
	border: 1px dashed #d0cece;
	padding: 17px 20px;
	height: auto;
	border-radius: 5px;
	transition: all .3s ease-in-out;
}

.AvailablePlanBx:hover {
	border: 1px dashed #a26fbd;
	box-shadow: 4px 14px 28px 0 rgba(162, 111, 189, 0.5);
}

.availablePlanFeaturesKey {
	font-weight: 500;
}

.paypal-button-container {
	border: none;
}

.defaultnavbar .navbar-toggler {
	box-shadow: none;
	background: white;
	position: absolute;
	left: 84%;
	bottom: 40px;
	padding: 0 4px;
}

.ContactDetails {
	margin: 15px 1%;
	display: inline-block;
	width: 28%;
	border: 1px #ddd solid;
	padding: 22px;
	border-radius: 10px;
	min-height: 130px;
	text-align: left;
}

.ContactDetails i {
	display: inline-block;
	margin: 0;
	vertical-align: top;
	width: 64px;
	font-size: 20px;
	opacity: 0.7;
}

.ContactDetailsBx {
	display: inline-block;
	width: 75%;
	vertical-align: top;
	padding-left: 15px;
}

.ContactDetailsBx strong {
	font-weight: 500;
	display: block;
	font-size: 19px;
}

.ContactDetailsBx span {
	font-size: 17px;
	color: rgba(0, 0, 0, 0.7);
}

.contactInfo .card {
	border: none;
	border-radius: 0;
}

.ContactTopHadding {
	margin-bottom: 23px;
}

.ContactTopHadding .card-title {
	font-size: 30px;
	font-weight: 500;
}

.ContactTopHadding .card-body {
	font-size: 18px;
	color: rgba(0, 0, 0, 0.7);
}

.MapIfream {
	background: #f1f1f1;
	padding: 5px;
	border: 5px #ddd solid;
	border-radius: 20px;
	height: 100%;
}

.ContactForm {
	background: #edeff4;
	padding: 40px;
	border-radius: 29px;
	box-shadow: none;
	border: none;
	/* display: inline-block; */
	width: 50%;
	margin: 0 auto;
}

.ContactForm .form-select {

	box-shadow: none !important;
	outline: none;
	font-size: 16px;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px solid #dadada;
	height: 55px;
	/* color: #797979; */
	opacity: 1;

}

.ContactSection3 {
	margin-bottom: 50px;
}

.MapIfream iframe {
	max-height: 100%;
	height: 100%;
}

.contactInfo {
	text-align: center;
}

.ContactForm .input-group textarea.form-control {
	height: 150px;
	border: 1px solid rgba(202, 202, 202, 0.6);
	border-radius: 6px !important;
	font-size: 16px;
	resize: none;
}

.contactInfo .card .card-body {
	padding: 0 0 20px;
}

.aboutusSection1 h1 {
	color: #25242a;
	font-size: 40px;
	font-weight: 500;
}

.aboutusSection1 h6 {
	margin-top: 10px;
	font-weight: 300;
	font-size: 18px;
	color: rgba(37, 36, 42, 0.9) !important;
}

.AboutImgFirst {
	display: inline-block;
	width: 100%;
	position: relative;
	overflow: hidden;
	border-radius: 6px;
}

.aboutusSection3 p {
	color: rgba(37, 36, 42, 0.8);
	font-size: 18px;
}

.AboutUsUsers {
	border-bottom: 1px #e5e5e5 solid;
	padding-bottom: 20px;
}

.aboutusSection2content p {
	color: rgba(82, 82, 82, 0.8);
	font-size: 18px;
	padding-bottom: 15px;
	max-width: 80%;
	margin: 0 auto;
}

.aboutusSection4 .card h3 {
	font-weight: normal;
	font-size: 28px;
	color: #201f1f;
	padding-bottom: 10px;
}

.aboutusSection4 .card h3 span {
	font-weight: 500;
}

.aboutusSection4 .card p {
	font-weight: normal;
	font-size: 18px;
	color: #505050;
}

.aboutusSection4 {
	padding-bottom: 60px;
}

.searchItems {
	width: 22%;
	position: relative;
}

.faqSection1 h1,
.faqSection2 h3 {
	font-size: 40px;
	color: #25242a;
	margin-bottom: 10px;
}

.upperPart h4 {
	color: #303030;
	font-size: 20px;
	font-weight: 500;
}

.upperPart p {
	color: #303030;
	font-size: 20px;
	font-weight: 300;
	padding-top: 5px;
}

.lowerPart {
	margin-top: 40px;
}

.lowerPart .accordion-item {
	border-radius: 0;
	border: none;
	border-bottom: 1px #ddd solid;
}

.lowerPart .accordion {
	border-top: 1px #ddd solid;
}

.lowerPart .accordion-item .accordion-header .accordion-button {
	display: block;
	padding: 28px 10px 28px 70px;
	font-size: 24px;
	background: transparent;
	color: #303030;
	border-radius: 0;
	box-shadow: none;
}

.lowerPart .panel-body {
	padding: 0px 15px 15px 70px;
	border: none !important;
	font-size: 18px;
}

.lowerPart .panel-default {
	border: none;
	border-bottom: 1px #ddd solid;
	border-radius: 0;
}

.lowerPart .panel-default>.panel-heading {
	color: #333;
	background-color: #fff;
	border: none;
	border-radius: 0;
	padding: 0;
}

.lowerPart .accordion-item .accordion-header .accordion-button::after {
	background-image: url("/public/Images/plus-icon.png");
	position: absolute;
	left: 25px;
	top: 34px;
	width: 15px;
	height: 15px;
	background-repeat: no-repeat;
	background-size: 100%;
	box-shadow: none;
}

.lowerPart .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
	background-image: url("/public/Images/min-icon.png");
	position: absolute;
	left: 25px;
	top: 34px;
	width: 15px;
	height: 15px;
	background-repeat: no-repeat;
	background-size: 100%;
	box-shadow: none;
	transform: initial;
}

.lowerPart .accordion-item .accordion-body {
	color: #303030;
	font-size: 20px;
	font-weight: 300;
	padding-top: 5px;
}

.BannerImg {
	position: absolute;
	right: 90px;
	bottom: 0;
	width: 62%;
	padding: 0;
	pointer-events: none;
}

.BannerImg1 {
	position: absolute;
	right: 20px;
	top: 50px;
}

.BannerImg3 {
	position: absolute;
	right: -70px;
	top: 180px;
}

.BannerImg2 {
	position: absolute;
	right: -50px;
	bottom: 30px;
}

.BannerImg4 {
	position: absolute;
	right: -50px;
	bottom: 25%;
}

.AboutImgFirst img:hover {
	-webkit-transform: scale(1.12);
	-moz-transform: scale(1.12);
	-o-transform: scale(1.12);
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
}

.AboutImgFirst img {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
}

@media (max-width: 992px) {
	.navbardefault .endorsedCompanies {
		margin: 15px 0 0 29px !important;
		padding: 0;
	}

	.navbar-brand .frontendNavLogo {
		width: 180px;
	}

	.navbardefault .navitems {
		margin: 8px 0 0 29px;
		font-size: 17px;
		font-weight: 700;
		display: inline-block;
		width: 100%;
	}

	.defaultnavbar .navButton1 {
		background-color: #fff !important;
		border: none !important;
		color: #000 !important;
		margin-left: 15px!important;
	}

	.social-media {
		display: none !important;
	}

	.navbardefault {
		background: #eb602e;
		left: 0;
		position: absolute;
		top: 76%;
		width: 100%;
		z-index: 999;
	}

	.navbardefault .navigation {
		display: inline-block;
		max-height: none;
		overflow: visible;
		padding-bottom: 7px;
		width: 100%;
	}
	.defaultnavbar .navButton2 {
		margin-left: 8px !important;
		min-width: 135px !important;
	}
}

@media(max-width: 767px) {
	.featured-jobs-home h1 {
		font-size: 24px !important;
	}


	.navbardefault {
		position: absolute;
		left: 0;
		top: 76%;
		background: #fff;
		width: 100%;
		z-index: 999;
		background: #EB602E;
	}

	.navbardefault .navigation {
		display: inline-block;
		width: 100%;
		overflow: visible;
		max-height: initial;
		padding-bottom: 7px;
		/* border-top: 1px #ddd solid; */
	}

	.navbardefault .navitems {
		margin: 0;
		display: block;
		/* padding: 15px;border-bottom: 1px #ddd solid; */
	}

	.navbardefault .navitems:hover::before,
	.navbardefault .navitems.active::before {
		display: none;
	}

	.navbardefault .btn-primary {
		margin-top: 20px;
	}

	.SearchIcon {
		padding: 11px 0;
		display: inline-block;
		vertical-align: top;
		margin: 0 3px;
	}

	.defaultnavbar .dropdown {
		/* border-top: 1px #ddd solid; */
		padding: 5px 16px;
		margin: 0 !important;
	}

	.defaultnavbar .navButton1 {
		margin-left: 15px !important;
		background-color: #fff !important;
		color: #000 !important;
		border: none !important;
	}

	.defaultnavbar .navButton2 {
		margin-left: 15px !important;
		min-width: 135px !important;
	}

	.MJBody {
		overflow-x: auto;
	}

	/* .FooterLinks {
		margin-bottom: 30px;
	} */

	.FooterLinks ul li {
		/* margin-top: 10px; */
		font-size: 90%;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 10px;

	}

	.FooterLinks ul li a {
		color: #FFC548 !important;
	}

	.footerFooter {
		margin-top: 10px;
	}

	.BannerImg {
		display: none;
	}

	/* .sliderbody {
		padding: 40px 0;
	} */
	.lowerSlider {
		padding: 30px 0;
	}

	.slidertext h1 {
		font-size: 30px;
	}

	.jobs-clients-posted h3 {
		font-size: 30px;
	}

	.searcharea {
		display: inline-block;
		width: 100%;
		position: relative;
	}

	.searcharea i {
		position: absolute;
		left: 8px;
		top: 5px;
	}

	.searcharea .input-group {
		padding-left: 43px;
		margin-bottom: 13px;
	}

	.CategoryBx {
		margin: 20px 0;
		text-align: center;
	}

	.CategoryBx p {
		max-width: 100%;
	}

	.CategoryBx img {
		margin-bottom: 0;
		width: 80px;
	}
}

/* JOBSEEKER SIDE */

/*------------JobseekerProfile--------------*/
.JobseekerProfileBx {
	display: inline-block;
	width: 100%;
	border: 1px dashed #a7a6a6;
	border-radius: 5px;
	margin-top: 20px;
}

.JobseekerProfileTopBx {
	display: inline-block;
	width: 100%;
	border-bottom: 1px dashed #a7a6a6;
	padding: 11px 20px;
	margin-bottom: 10px;
}

.JobseekerProfileTopBx h3 {
	display: inline-block;
	font-size: 18px;
	color: rgba(79, 81, 82, 0.8);
}

.EditProfileTag {
	float: right;
}

.EditProfileTag a {
	color: rgba(79, 81, 82, 0.8);
	font-size: 18px;
}

.JobseekerProfileTopBx h3 span {
	vertical-align: middle;
}

.JobseekerProfileTopBx h3 i {
	margin-right: 7px;
}

.JobseekerProfileDetails {
	display: inline-block;
	width: 100%;
	padding: 20px;
}

.JobseekerProfileBxDetailTop {
	display: inline-block;
	width: 100%;
}

.JobseekerProfileImg {
	display: inline-block;
	width: 110px;
	height: 110px;
	overflow: hidden;
	border-radius: 50%;
	vertical-align: middle;
}

.JobseekerProfileBxDetailTop h3 {
	display: inline-block;
	color: #333;
	font-size: 30px;
	padding-left: 30px;
}

.ProfileDetails {
	display: inline-block;
	width: 100%;
	margin-top: 20px;
}

.ProfileDetails ul {
	display: inline-block;
	width: 100%;
}

.ProfileDetails ul li {
	display: inline-block;
	padding-right: 20px;
	color: rgba(36, 37, 42, 0.8);
	font-size: 16px;
	margin-bottom: 10px;
}

.ProfileDetails ul li.full-width {
	width: 100%;
}

.ProfileDetails ul li i,
.ProfileDetails ul li span {
	vertical-align: middle;
}

.ProfileDetails ul li i {
	margin-right: 5px;
	color: #838285;
}

.JobseekerEducationDetails {
	padding: 20px;
	display: inline-block;
	width: 100%;
}

.JobseekerEducationDetails ul {
	display: inline-block;
	width: 100%;
}

.JobseekerEducationDetails ul li {
	display: inline-block;
	width: 100%;
	color: rgba(36, 37, 42, 0.8);
	font-size: 16px;
	position: relative;
	padding-left: 20px;
	margin-bottom: 5px;
}

.JobseekerEducationDetails ul li::before {
	position: absolute;
	left: 0;
	top: 8px;
	content: "";
	background: #9d9c9f;
	width: 8px;
	height: 8px;
	border-radius: 50%;
}

.JobseekerSkillsAdd {
	display: inline-block;
	width: 100%;
	padding: 20px;
}

.AddProfileTag {
	float: right;
}

.AddProfileTag a {
	color: rgba(79, 81, 82, 0.8);
	font-size: 18px;
}

.AddProfileTag a i {
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
}

.AddProfileTag a span {
	display: inline-block;
	vertical-align: middle;
}

.SkillsAddBxMain {
	display: inline-block;
	width: 100%;
}

.SkillsAddBxMain ul {
	display: inline-block;
	width: 100%;
}

.SkillsAddBxMain ul li {
	display: inline-block;
	background: #e6e6e6;
	padding: 5px 20px;
	border-radius: 20px;
	position: relative;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.7);
	margin-right: 10px;
	margin-bottom: 10px;
}

.RemoveSkills {
	float: right;
	background: #535353;
	width: 22px;
	text-align: center;
	height: 22px;
	border-radius: 50%;
	margin-left: 10px;
	margin-top: 1px;
}

.JobseekerEducationDetails p {
	font-size: 16px;
	color: rgba(0, 0, 0, 0.7);
}

.CurrentPlanBx {
	display: inline-block;
	width: 100%;
}

.CurrentPlanBx label {
	display: inline-block;
	width: 100%;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.7);
	margin-bottom: 15px;
}

.CurrentPlanBx .btn-primary {
	background: #294a9c;
	border: none;
	font-size: 14px;
	text-transform: uppercase;
	border-radius: 2px;
	padding: 7px 20px;
}

.JobseekerCertificatesDetails {
	display: inline-block;
	width: 100%;
	padding: 20px;
}

.JobseekerCertificatesDetails ul {
	display: flex;
	width: 100%;
}

.JobseekerCertificatesDetails ul li {
	background: #e6e6e6;
	padding: 10px;
	border-radius: 9px;
	position: relative;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.7);
	margin-right: 10px;
	width: 20%;
	min-height: 40px;
}

.JobseekerCertificatesDetails ul li i {
	display: inline-block;
	vertical-align: top;
	margin-right: 5px;
}

.JobseekerCertificatesDetails ul li span {
	display: inline-block;
	vertical-align: middle;
}


.jsprofileCross {
	position: absolute;
	right: 0;
	cursor: pointer;
	top: 0;
}

.myProfileLinks {
	width: 100%;
	display: inline-block;
	margin-top: 30px;
}

.myProfileEachButton {
	margin: 40px 20px 0 0;
}

.JobseekerDocsDetails {
	display: flex;
	width: 100%;
	padding: 20px;
}

.JobseekerDocsDetails ul {
	display: inline-block;
	width: 100%;
	cursor: pointer;
}

.JobseekerDocsDetails ul li {
	display: inline-block;
	background: #e6e6e6;
	padding: 10px;
	border-radius: 9px;
	position: relative;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.7);
	margin-right: 10px;
	/* width: 20%; */
	min-height: 40px;
}

.JobseekerDocsDetails ul li i {
	display: inline-block;
	vertical-align: top;
	margin-right: 5px;
}

.JobseekerDocsDetails ul li span {
	display: inline-block;
	vertical-align: middle;
}


/* JSEditprofile */

.JSEPFirstSegment {
	display: flex;
	margin: 3% 0 5% 3%;
}

.JSEPFirstSegment img {
	object-fit: contain;
	width: 200px;
	height: 200px;
	border-radius: 50% !important;
	border: 1px solid black;
	margin-right: 5%;
}

.radioInput {
	border: 1px dashed #a7a6a6;
	border-radius: 8px;
	padding: 21px 21px;
}

.radioInput input {
	height: auto;
	margin-right: 5px;
}

.radioInput .labelMale {
	padding-right: 20px;
}

.searchWrapper {
	border: 1px dashed #ccc;
	border-radius: 40px;
	min-height: 22px;
	padding: 0px;
	position: relative;
}

.coverDelete {
	width: 100%;
	padding-bottom: 70px;
	padding-right: 20px;

}

.coverDelete button {
	float: right;
}

.preferredLocationBx {
	z-index: 9999;
}

.EPJobseekerCertificatesDetails ul li {
	display: inline-block;
	background: #e6e6e6;
	padding: 10px;
	border-radius: 9px;
	position: relative;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.7);
	margin-right: 10px;
	width: auto;
	min-height: 40px;
	max-height: 48px;
}

.EPJobseekerCertificatesDetails {
	display: flex;
	width: 100%;
	;
}

.EPJobseekerCertificatesDetails ul {
	display: inline-block;
	width: 100%;
}

.checkCertificate {
	height: auto;
}

.checkCertificate .EPJobseekerCertificatesDetails ul li {
	width: 20%;
	max-height: initial;
}

.checkCertificate .EPJobseekerVidio ul li {
	width: auto;
	max-height: initial;
}

/* Education */
.EduAddMore {
	text-align: right;
	margin-right: 20px;
}

/* Edit Experience */

.noData {
	padding: 30px 10px;
	border: none;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	margin-bottom: 15px;
}

.removeButtonJobseeker {
	text-align: right;
}

.removeButtonJS {
	text-align: right;
}

.jobseekerAddMore {
	text-align: right;
	margin-right: 22px;
}

.bottomButtonsEducation {
	margin-left: 27px;
}

/* Professional Registratiopn */
.PRAddMore {
	text-align: right;
}

/* JSMake CV */
.makeCV {
	margin-top: 3%;
}

.makeCV h3 {
	color: red
}

.makeCVSeg1 {
	border: 1px dashed rgb(204, 204, 204);
	border-radius: 10px;
	margin-top: 20px;
}

.makeCVAddress {
	margin-top: 8px;
	padding: 0px 0px 20px 0;
	font-weight: bold;
}

.makeCVUserDetail {
	padding: 0px 0px 20px 20px;
	width: 100%;
	margin-top: 20px;
}

.makeCVUserDetail .makeCVEmail {
	display: inline-block;
	margin-right: 50px;
	margin-top: 20px;
}

.makeCVUserDetail .makeCVPhone {
	display: block;
	margin-top: 8px;
}

.makeaCVBottomBotton {
	margin-top: 30px;
	float: right;
}

.makeaCVBottomBotton button {
	font-size: 18px;
}

/* VideoCV */

.videoDisplay {
	margin: 20px 0 40px 0;
}

/* Inner membership */
.MembershipCardAuth {
	padding: 30px 5px;
}

.membershipCardAuthUpperPart {
	min-height: 190px;
}

.membershipCardMain {
	height: 100%;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.membershipCardMain:hover {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.MembershipCardAuth h4 {
	color: #25242a;
	font-size: 25px;
	font-weight: 500;
}

.MembershipCardAuth h2 {
	margin: 20px 0 0;
	font-size: 50px;
	font-weight: 600;
	color: rgba(41, 74, 156, 0.9);
}

.MembershipCardAuth h6 {
	color: #25242a;
	font-size: 17px;
	opacity: 0.8;
	font-weight: normal;
}

.ApplyAllAuth {
	border-top: 2px solid rgba(222, 222, 222, 0.5);
	margin-top: 20px;
	padding: 23px 20px 0;
	text-align: left;
	margin-right: 35px;
}

.ApplyAllAuth .form-check-input[type="checkbox"] {
	border-radius: 2px;
	border-width: 2px;
	width: 20px;
	height: 20px;
	margin-right: 8px;
}

.ApplyAllAuth .form-check-label {
	color: #25242a;
	font-size: 15px;
	opacity: 0.7;
	padding-top: 2px;
}

.applyoptionAuth {
	padding: 23px 0 0 16px;
	text-align: left;
}

.applyoptionAuth li {
	position: relative;
	color: rgba(36, 37, 42, 0.7);
	font-size: 14px;
	background: url("/public/Images/BlueArrow.png") no-repeat left center;
	padding: 0 0 0 28px;
	margin: 9px 0;
}

.MembershipCardAuth .btn-primary {
	background: #294a9c;
	border: none;
	border-radius: 3px;
	font-size: 19px;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
	padding: 7px 32px;
	width: 84%;
	margin-top: 30px;
}

.MembershipCardAuth .btn-secondary {
	background: #f3734c;
	border: none;
	border-radius: 3px;
	font-size: 19px;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
	padding: 7px 32px;
	width: 84%;
	margin-top: 30px;
}

.paymentOptionsAvailable {
	display: flex;
	justify-content: space-around;
}

.stripePaymentButton {
	padding: 3px 52px;
	background-color: #635bff;
	color: white;
	border: none;
	border-radius: 20px;
	height: 48px;
	margin-top: 2px;
	font-weight: 500;
}

.stripePaymentButton:hover {
	background-color: #7b74ff;
	color: white;
}

.payButtonStripe {
	background-color: #294A9C;
	color: white;
	padding: 5px 18px;
	margin-top: 10px;
	border: 1px solid #294A9C;
	border-radius: 5px;

}

.pleaseWaitStripe {
	width: 100%;
	height: 100%;
	position: fixed;
}

.pleaseWaitStripe img {
	width: 10%;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
}

/* JSSavedJobs */
.JSSavedJob {
	margin-top: 3%;
}

.JSSavedJob h3 {
	color: #dc445c;
}

.JSsavedJobsPagination {
	margin-top: 50px;
}

/* JSPayment history */
.PHBody {
	overflow-x: auto;
}

.TrActions {
	min-width: 108px;
}

.JSPHEachCol {
	min-width: 140px;
}

.JSPHFirstCol {
	min-width: 30px;
}

.JSPHSecondCol {
	min-width: 190px;
}

.JSPaymentHistoryPagination {
	margin-top: 50px;
}

/* JSManageAlerts */

.JSMASection2 {
	display: inline-block;
	width: 100%;
}

.JSMAHeaderButton {
	float: right;
}

.EditAlertButtons {
	margin-left: 23px;
}

.JSmanageAlertPagination {
	margin-top: 50px;
}

/* JSApplied Jobs */
.JSAppliedJobsPagination {
	margin-top: 50px;
}

/* JSMail Detail */

.MDButtonBx {
	display: inline-block;
	width: 100%;
}

.MDButtonBx .replyButton {
	float: right;
	color: white;
	background-color: #294a9c;
	font-weight: 500;
	border: 2px #294a9c solid;
	font-size: 16px;
	border-radius: 3px;
	min-width: 110px;
	height: 40px;
}

.MDButtonBx .replyButton:hover {
	float: right;
	font-weight: 500;
	color: #fff;
	background-color: #f3734c;
	border-color: #f3734c;
}

.JSMailHistoryPagination {
	margin-top: 50px;
}

/* JSChange Photo */

.JSChangePhoto {
	border: 1px solid #fff;
	border-radius: 5px;
	box-shadow: 0 3px 8px rgba(0, 0, 0, .24);
	height: 200px;
	padding: 10px;
	width: fit-content;

}

/* .JSChangePhoto img {
    margin: 19px 0 0 8px;
    width: 125px;
    height: 100px;
} */
.jobseekerChangePhoto img {
	border-radius: 5px;
	height: 180px;
	width: 180px;
}

/* Job Details */
.single-job-detail {
	display: inline-block;
	width: 100%;

}

.logo-detail {
	float: left;
	width: 150px;
	height: 150px;
	background: #f1f1f1;
	border-radius: 5px;
	overflow: hidden;
	/* padding-top: 15px; */
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	position: relative;
}

.logo-detail:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.logo-detail .JDImage {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

.job-meta-detail {
	float: left;
	padding-left: 30px;
	padding-top: 10px;
	width: 73%;
}

.job-meta-detail h2 {
	color: #25242a;
	font-weight: 400;
	font-size: 23px;
	font-family: 'Roboto', sans-serif;
	margin-bottom: 10px;
}

.job-meta-detail p {
	margin: 0;
	padding: 0;
	margin-right: 6px;
	color: #666666;
	font-size: 14px;
	line-height: 26px;
}

.job-meta-detail p i {
	margin-right: 3px;
	color: #C0BEBE;
	font-size: 14px;
	width: 18px;
}

.JobDetailTopBx {
	background: #f1f1f1;
	padding: 40px 0;
	margin-bottom: 30px;
}

.JobDetailBtnBx {
	text-align: right;
	margin-top: 50px;
}

.ShareBtnDetails {
	display: inline-block;
}

.ShareBtnDetails a img {
	opacity: 0.5;
}

.shareIconsList {
	width: 100%;
}

.shareIconsButtons {
	display: inline-block;
	margin-right: 8px;
	margin-top: 10px;
}

.ApplyJobBtnBx {
	display: inline-block;
	margin-left: 20px;
}

.ApplyJobBtnBx .btn-primary {
	background: #294a9c;
	border-color: #294a9c;
	margin-right: 15px;
	border-radius: 4px;
}

.ApplyJobBtnBx .btn-secondary {
	background: #fff;
	border-color: #f15424;
	border-radius: 4px;
	color: #f15424;
}

.jobDescription ul {
	padding: 0 20px;
	/* list-style-type: disc; */
}

.jobDescription li {
	padding: 0 10px;
	list-style-type: disc;
}

/* .jobDescription p {
	padding: 0 10px; 
	 list-style-type: disc;
} */
.JobDescriptionBx {
	padding-top: 20px;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	font-style: normal;
	color: #333;
}

.JobDescriptionBx h2 {
	font-size: 24px;
	color: #25242a;
	font-family: 'Roboto', sans-serif;
	font-weight: normal;
	padding: 5px 20px 0 20px;
}

.JobDescriptionBx p {
	padding: 5px 20px 0 20px;

}

.relatedJobsSection {
	padding-top: 30px;
}

.relatedJobsSection h2 {
	font-weight: 500;
}

.JobDetailsCard h2 {
	font-size: 22px;
}

.JobDetailsInfo {
	padding-top: 10px;
}

.JobDetailsInfo ul {
	display: inline-block;
	width: 100%;
}

.JobDetailsInfo ul li {
	display: inline-block;
	width: 100%;
	padding-top: 10px;
	font-size: 16px;
	color: #666;
}

.JobDetailsInfo ul li i {
	width: 25px;
	color: #979797;
}

.JobDetailsCard {
	background: #fff;
	border-color: #ddd;
	border-radius: 10px;
}

.JobDetailsCard:hover {
	box-shadow: none;
	border: 1px solid #ddd;
	background: #fff;
}

.JobDetailsDescriptionSection {
	padding-bottom: 40px;
}

.jobDescriptionKeys {
	font-weight: 600;
}



.JcardFooterItem1 {
	width: 20%;
	margin-top: 5px;
}

.JcardFooterItem2 {
	width: 49%;
	vertical-align: top;
}

.JcardFooterItem2 h6 {
	width: 96%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.catCard .catCardTitle {
	font-size: 22px;
	min-height: 50px;
}


.TopEmployersBxCardBx:nth-child(4) .EmployersLogoBx {
	border-right: none;
}

.TopEmployersBxCardBx:nth-child(5) .EmployersLogoBx {
	border-bottom: none;
}

.TopEmployersBxCardBx:nth-child(6) .EmployersLogoBx {
	border-bottom: none;
}

.TopEmployersBxCardBx:nth-child(7) .EmployersLogoBx {
	border-bottom: none;
}

.TopEmployersBxCardBx:nth-child(8) .EmployersLogoBx {
	border-bottom: none;
	border-right: none;
}




/********************************** ADMIN PANEL **************************************/

/* Admin Panel Navbar */


.APNavbar {
	background: #fff;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;
	height: 80px;
}

.nav-left img {
	width: 28px;
	height: 18px;
	flex-shrink: 0;
	margin-top: 5px;
}

.APNavSectionLeft {
	margin-left: 25px;
}

.APNavSectionRight {
	width: 100%;
}

.APNavSectionRight .part1 {
	display: inline-block;
	/* margin-right: 25px; */
}

.navbar-expand-lg .navbar-nav .APNavSectionRight .dropdown-menu {
	background: #fff !important;
	width: 290px !important;
}

.APNavSectionRight .part1 a {
	margin-left: 25px;
}

.APNavSectionRight .part2 {
	display: inline-block;
}

.APNavSectionRight .part2 .APNavInner1 {
	display: inline-block;
	margin-right: 15px;
}

.APNavSectionRight .part2 .APNavInner2 {
	display: inline-block;
	padding-top: 5px;
}

.APNavSectionRight .part2 .APNavInner3 {
	display: inline-block;
	margin-right: 10px;
}

.nav-right {
	display: inline-flex;
	align-items: center;
	gap: 32px;
}

.nav-right img {
	width: 24px;
	height: 24px;
}

.nav-right .part1 {
	display: flex;
	align-items: flex-start;
	gap: 24px;
}

.nav-right .bellIndicator .bell {
	margin-top: 8px;
	;
	width: 24px;
	height: 24px;
	flex-shrink: 0;
}

.nav-right .bellIndicator .indicator {
	margin-bottom: 5px;
	width: 4px;
	height: 4px;
	flex-shrink: 0;
}

.nav-right .part2 {
	display: flex;
	align-items: center;
	gap: 12px;
}

.nav-right .part2 p {
	color: var(--primary, #000);

	/* Body */
	/* font-family: 'Poppins'; */
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 24px */
	margin-top: 10px;
}

.APNavSectionRight .part2 .APNavInner3 .custom-dropdown-menu {
	right: 0px !important;
	width: 290px !important;
	left: auto !important;
	margin-top: 15px;
	padding: 20px;
	text-align: center;
}

.APNavbar .custom-dropdown-menu a {
	text-decoration: none;
	color: black;
}

.APNavbar .custom-dropdown-menu .dropdownMenu :hover {
	text-decoration: none;
	color: white;
	color: #f3734c;
}

.dropdownMenuLower {
	margin-top: 20px;
	background-color: #f3734c;
	padding: 10px 0;
	border-radius: 7px;
}

.dropdownMenuLower:hover {
	margin-top: 20px;
	background-color: #294a9c;
	padding: 10px 0;
	border-radius: 7px;
	color: white;
}

/* APFooter */
.APFooter {
	background-color: #c9c9c9;
	color: black;
}

.APFooter p {
	padding: 30px 0 30px 270px;
	text-align: center;
}

.APFooter img {
	padding-bottom: 2px;
}


/* AP Basic */
.APBasic {
	overflow-x: hidden;
	width: 100%;
}

.APBasic h2 {
	color: #f3734c;
}

.APBasic a {
	color: #000;
	cursor: pointer;
	text-decoration: none;
}

.SidebarList>a {
	position: relative;
	display: inline-block;
	width: 100%;
}

.APButton3 {
	color: #294a9c;
	background-color: white;
	font-weight: 400;
	border: 1px #294a9c solid;
	font-size: 14px;
	padding: 9px 25px;
	border-radius: 5px;
	margin-left: 10px;
	min-width: 150px;
	/* max-width: 30px; */
}

.APButton3:hover {
	color: #f3734c;
	background-color: #fff;
	border-color: #f3734c;
}

.APButton4 {
	color: #f3734c;
	background-color: white;
	font-weight: 400;
	border: 1px #f3734c solid;
	font-size: 14px;
	padding: 9px 25px;
	border-radius: 5px;
	margin-left: 10px;
	min-width: 150px;
	/* max-width: 30px; */
}

.APButton4:hover {
	color: #294a9c;
	background-color: #fff;
	border-color: #294a9c;
}

.adminValidation {
	text-align: justify;
}

.APBasic input {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: 100px;
	max-width: 1100px;
	border: 1px dashed #a7a6a6;
	padding: 20px 20px;
}

.APBasic input:hover {
	border: 1px dashed #f3734c;
	box-shadow: 4px 14px 28px 0 rgba(242, 107, 73, 0.3);
}

.APBasic textarea {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: 100px;
	max-width: 1100px;
	border: 1px dashed #a7a6a6;
	padding: 20px 20px;
}

.APBasic textarea:hover {
	border: 1px dashed #a46cc4;
	box-shadow: 4px 14px 28px 0 rgba(242, 107, 73, 0.3);
}

.APBasic select {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: 100px;
	max-width: 1100px;
	border: 1px dashed #a7a6a6;
	padding: 20px 20px;
	color: black;
}

.APBasic select:hover {
	border: 1px dashed #f3734c;
	box-shadow: 4px 14px 28px 0 rgba(242, 107, 73, 0.3);
}

.APRadioInput {
	border: 1px dashed #a7a6a6;
	border-radius: 8px;
	padding: 21px 0px;
	max-width: 1100px;
}

.APRadioInput input {
	height: 15px;
}

.breadCumb {
	margin: 10px 0 20px 0;
}

.checkBoxCol {
	line-height: initial;
}

.checkBoxCol .tableCheckBox {
	height: 20px;
	width: 20px;
	min-width: 20px;
	max-width: 20px;
}

.table tbody tr:last-child {
	border-color: #fff;
}

.checkBoxDiv input {
	height: 20px;
	width: 20px;
	min-width: 20px;
	max-width: 20px;
}

.breadCumb1 {
	margin: 0 0 12px 0;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	border: 1px solid white;
	border-radius: 5px;
	padding: 20px 0 20px 5px;

}

.APJoditEditor {
	max-width: 1100px;
	/* border: 1px dashed #a7a6a6; */
	/* min-height: 200px; */
}

.APQuillEditor {
	max-width: 1100px;
	/* border: 1px dashed #a7a6a6; */
}

.selectedInputImage {
	width: auto;
	padding: 14px;
	height: 215px;
	border: 1px solid #e1dfdf;
	border-radius: 10px;
	margin: 30px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.selectedImageArea {
	width: 250px;
	height: 250px;
	border: 1px solid black;
	border-radius: 10px;
	margin-top: 20px;
}

.selectedImageButton {
	margin-top: 20px;
}

.APBasic .error {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: 100px;
	max-width: 1100px;
	border: 1px dashed #ff0000;
	padding: 20px 20px;
}

.APBasic .input-error {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width: 100px;
	max-width: 1100px;
	border: 1px dashed #ff0000;
	padding: 20px 20px;
}

.adminBottomButtons {
	margin-top: 20px;
}

.adminMediumButton1 {
	color: white;
	background-color: #294a9c;
	font-weight: 500;
	border: 2px #294a9c solid;
	font-size: 16px;
	padding: 9px 25px;
	border-radius: 3px;
	margin-left: 15px;
	min-width: 135px;
}

.adminMediumButton1:hover {
	color: white;
	background-color: #f3734c;
	font-weight: 500;
	border: 2px #f3734c solid;
	font-size: 16px;
	padding: 9px 25px;
	border-radius: 3px;
	margin-left: 15px;
	min-width: 135px;
}

.adminLowerButton1 {
	color: white;
	background-color: #294a9c;
	font-weight: 500;
	border: 2px #294a9c solid;
	font-size: 17px;
	padding: 9px 25px;
	border-radius: 3px;
}

.adminLowerButton1:hover {
	color: white;
	background-color: #f3734c;
	font-weight: 500;
	border: 2px #f3734c solid;
	font-size: 17px;
	padding: 9px 25px;
	border-radius: 3px;
}

.adminLowerButton2 {
	color: #f3734c;
	background-color: white;
	font-weight: 500;
	border: 2px #f3734c solid;
	font-size: 17px;
	padding: 9px 25px;
	border-radius: 3px;
	margin-left: 10px;
}

.adminLowerButton2:hover {
	color: #294a9c;
	background-color: white;
	font-weight: 500;
	border: 2px #294a9c solid;
	font-size: 17px;
	padding: 9px 25px;
	border-radius: 3px;
	margin-left: 10px;
}


/* Styling the page scroller */
/* Webkit (Safari and Chrome) */
/* ::-webkit-scrollbar {
	width: 6px; 
  }
  
  ::-webkit-scrollbar-thumb {
	background-color: #f3734c;
	border-radius: 6px;
  } */

/* Style the horizontal scrollbar */
::-webkit-scrollbar-horizontal {
	height: 6px;
}

/* Style the horizontal scrollbar thumb */
::-webkit-scrollbar-thumb:horizontal {
	background-color: #A82686;
	border-radius: 6px;
}

/* Location suggestions for admin panel */

.suggestionsAdminSide {
	background-color: #fff;
	border-radius: 8px;
	margin-top: 2px;
	padding: 10px;
	position: absolute;
	top: 69px;
	z-index: 99;
	min-width: 100px;
	max-width: 1100px;
	width: -webkit-fill-available;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.suggestionsAdminSide li {
	padding: 5px;
	cursor: pointer;
}

.suggestionsAdminSide li:hover {
	background-color: #ef9478;
	border-radius: 8px;
}





/* Admin Panel Login */

.checkbox-wrapper-46 {
	text-align: left;
}

.checkbox-wrapper-46 input[type="checkbox"] {
	display: none;
	visibility: hidden;
}

.checkbox-wrapper-46 .cbx {
	margin: auto;
	-webkit-user-select: none;
	user-select: none;
	cursor: pointer;
}

.checkbox-wrapper-46 .cbx span {
	display: inline-block;
	vertical-align: middle;
	transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-46 .cbx span:first-child {
	position: relative;
	width: 18px;
	height: 18px;
	border-radius: 3px;
	transform: scale(1);
	vertical-align: middle;
	border: 1px solid #9098A9;
	transition: all 0.2s ease;
}

.checkbox-wrapper-46 .cbx span:first-child svg {
	position: absolute;
	top: 3px;
	left: 2px;
	fill: none;
	stroke: #FFFFFF;
	stroke-width: 2;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-dasharray: 16px;
	stroke-dashoffset: 16px;
	transition: all 0.3s ease;
	transition-delay: 0.1s;
	transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-46 .cbx span:first-child:before {
	content: "";
	width: 100%;
	height: 100%;
	background: #294A9C;
	display: block;
	transform: scale(0);
	opacity: 1;
	border-radius: 50%;
}

.checkbox-wrapper-46 .cbx span:last-child {
	padding-left: 8px;
}

.checkbox-wrapper-46 .cbx:hover span:first-child {
	border-color: #294A9C;
}

.checkbox-wrapper-46 .inp-cbx:checked+.cbx span:first-child {
	background: #294A9C;
	border-color: #294A9C;
	animation: wave-46 0.4s ease;
}

.checkbox-wrapper-46 .inp-cbx:checked+.cbx span:first-child svg {
	stroke-dashoffset: 0;
}

.checkbox-wrapper-46 .inp-cbx:checked+.cbx span:first-child:before {
	transform: scale(3.5);
	opacity: 0;
	transition: all 0.6s ease;
}

@keyframes wave-46 {
	50% {
		transform: scale(0.9);
	}
}


.centerContainer {
	min-height: 100vh;

}

.loginCardStyles {
	width: 500px;
	padding: 20px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	border: none;
	margin: 150px auto 0;
}

.loginCardStyles img {
	max-width: 326px !important;
}

.belowSectionAdminLogin {
	text-align: center;
	margin-top: 30px;
}

.centerContainer input {
	border: none;
	outline: none;
	font-size: 17px;
	height: 60px;
	font-weight: 400;
	opacity: 0.8;
	min-width: 100px;
	max-width: 1100px;
	border: 1px dashed #a7a6a6;
	padding: 20px 20px;
}

.passwordVisibilityAdmin {
	position: absolute;
	right: 13px;
	top: 16px;
}

.forgotPassword {
	width: 100%;
	margin-bottom: 45px;
}

.forgotPassword a {
	float: right;
	color: #EB602E;
}


/* Sidebar */
/* .Sidebar {
	height: 100%;
	width: auto;
	background-color: #2F4050;
} */
/* .SidebarList {
	height: auto;
	padding: 0;
	width: 100%;

} */

.site-min-height {
	margin: 90px 0 30px 290px;
	min-height: 100vh;
}

.Sidebar {
	background-color: #2f4050;
	width: 270px;
	height: 100%;
	position: fixed;
	padding-bottom: 90px;
}

.SidebarList {
	height: 100%;
	padding: 0;
	width: 100%;
	margin-top: 83px;
	overflow-y: auto;
}

.SidebarList .row {
	width: auto;
	height: 47px;
	list-style-type: none;
	margin: 0;
	display: flex;
	flex-direction: row;
	color: white;
	justify-content: center;
	align-items: center;
	position: relative;
	/*font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
}

.SidebarList .row:hover {
	cursor: pointer;
	background-color: #293846;
}

.SidebarList #active {
	background-color: 293846;
}

.SidebarList>a:first-child::before {
	position: absolute;
	left: 10px;
	bottom: 0;
	content: "";
	width: 92%;
	height: 2px;
	background: #171b1e;
	z-index: 999;
}

.row #icon {
	flex: 20%;
	display: grid;
	place-items: center;
	padding-left: 10px;
}

.row #icon2 {
	position: absolute;
	right: 0;
	width: auto;
	top: 11px;
}

.row #title {
	flex: 75% 1;
	padding-top: 9px;
	font-weight: normal;
	font-family: 'Roboto', sans-serif;
	font-size: 15px;
}

.SidebarListInternal {
	background-color: black;
}

.SidebarListInternal .row {
	width: 100%;
	height: 43px;
	list-style-type: none;
	margin: 0;
	display: flex;
	flex-direction: row;
	color: white;
	justify-content: center;
	align-items: center;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.Sidebar .dropdown {
	border: 1px solid #171b1e;
	border-radius: 0;
	/* padding: 0px 10px; */
	margin: 0px 10px;
}

.SidebarListInternal {
	background-color: #253039;
}

.SidebarListInternal .row #innerIcon {
	flex: 20% 1;
	display: grid;
	place-items: center;
	padding-left: 9px;
	padding-right: 0;

}

.SidebarListInternal .row #innerTitle {
	flex: 74% 1;
	padding: 3px 0;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	margin-top: 10px;
}

.SidebarListInternal .row:hover {
	cursor: pointer;
	background-color: #293846;
	border-radius: 20px;
}

.SidebarListInternal .row .css-i4bv87-MuiSvgIcon-root {
	width: 15px;
	height: 15px;
}

/* .APSidebar {
	width: 250px; 
	position: fixed; 
	top: 0;
	bottom: 0;
	left: 0;
	overflow-y: auto; 
} */
.SidebarList .dropdown {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.1s ease-in-out;
	/* Smooth transition */
}

.SidebarList .dropdown.open {
	max-height: 1000px;
	/* Adjust as needed */
	transition: max-height 0.9s ease-in-out;
	/* Smooth transition */
}

.adminSidebarLinks h3 {
	color: #fff;
	font-size: 26px;
	font-weight: 500;
}

/* .adminSidebarLinks ul {
	margin-top: 20px;
} */
.adminSidebarLinks ul li {
	margin-top: 20px;
}

.adminSidebarLinks ul li a {
	font-size: 17px;
	color: #fff;
	font-weight: 300;
}

.sidebarEachHeader {
	width: 100%;
}

.sidebarEachHeader h3 {
	display: inline-block;
	padding-top: 20px;
}

.adminSidebarPlusLink {
	color: #fff;
	float: right;
	font-size: 22px;
	padding-top: 20px;
	margin-bottom: 15px;
	margin-right: 15px;
}

.sidebarEachHeader a {
	color: white;
}


/* Styling Sidebar Scroller */

.SidebarList {
	/* Your existing styles for SidebarList here */

	/* Change the color of the scrollbar thumb (the draggable part) */
	scrollbar-color: #f3734c;
	/* Change 'red' and 'green' to your desired colors */
}

/* Style the scrollbar track (the background) */
.SidebarList::-webkit-scrollbar {
	width: 6px;
	/* Adjust the width as needed */
}

/* Style the scrollbar thumb (the draggable part) */
.SidebarList::-webkit-scrollbar-thumb {
	background-color: #f3734c;
	/* Change 'red' to your desired color */
}

/* Style the scrollbar track (the background) on hover */
/* .SidebarList::-webkit-scrollbar-thumb:hover {
	background-color: blue; 
  } */

.adminPageHeading {
	margin-top: 30px;
}


/* Dashboard */

.dashboardHeader p {
	color: rgb(148, 148, 148);
	margin-top: 10px;
}

.dashboardSection1 {
	width: 100%;
	margin-top: 30px;
}

.dashboardSection1 .cardUpperLeft a {
	text-decoration: none;
	cursor: pointer;
	color: black;
}

.dashboardCard1 {
	display: inline-block;
	border: 1px solid #148EF4;
	width: 100%;
	height: 130px;
	border-radius: 12px;
	background: #FFF;
	/* margin-right: 20px; */
	padding: 15px;
}

.dashboardCard2 {
	display: inline-block;
	border: 1px solid #ED8936;
	width: 100%;
	height: 130px;
	border-radius: 12px;
	background: #FFF;
	/* margin-right: 20px; */
	padding: 15px;
}

.dashboardCard3 {
	display: inline-block;
	border: 1px solid #38B2AC;
	width: 100%;
	height: 130px;
	border-radius: 12px;
	background: #FFF;
	/* margin-right: 20px; */
	padding: 15px;
}

.dashboardCard4 {
	display: inline-block;
	border: 1px solid #F3734C;
	width: 100%;
	height: 130px;
	border-radius: 12px;
	background: #FFF;
	/* margin-right: 20px; */
	padding: 15px;
}

.dashboardCard2,
.dashboardCard3,
.dashboardCard4,
.dashboardCard1 {
	position: relative;
}

.dashboardBody .cardLower {
	width: 60px;
	position: absolute;
	height: 60px;
	text-align: center;
	right: 30px;
	top: 50%;
	transform: translate(0, -50%);
	border-radius: 10px;
	line-height: 54px;
}

.dashboardCard1 .cardLower {
	background: #148EF4
}

.dashboardCard2 .cardLower {
	background: #ED8936
}

.dashboardCard3 .cardLower {
	background: #38B2AC
}

.dashboardCard4 .cardLower {
	background: #F3734C
}

.dashboardBody .cardUpperLeft {
	display: inline-block;
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.dashboardBody .cardUpperRight {
	float: right;
}

.dashboardBody .cardUpperRight input {
	height: 20px;
	width: 20px;
	min-width: 20px;
	max-width: 20px;
}

.dashboardBody .cardCenter {
	margin: 9px 0;
}

.dashboardBody .cardLowerImg {
	display: inline-block;
}

.dashboardBody .cardLowerTxt {
	display: inline-block;
}

.dashboardBody .cardLower p {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	color: var(--secondary, #7C8DB5);
	margin-left: 15px;
}

.dashboardBody .lowerCardInnerTxt {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	color: var(--secondary, #7C8DB5);
	margin-left: 15px;
}

.dashboardSection2 {
	margin: 50px 0;
}


.dashboardBody .tableHeader {
	width: 100%;
	margin-bottom: 30px;
}

.dashboardBody .tableHeader p {
	color: var(--primary, #000);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	display: inline-block;
}

.dashboardBody .tableHeader button {
	float: right;
}

.dashboardBody .tableHeaderButton {
	color: white;
	background-color: #294a9c;
	font-weight: 500;
	border: 2px #294a9c solid;
	font-size: 16px;
	padding: 4px 12px;
	border-radius: 3px;
	/* margin-left: 15px; */
	min-width: 115px;
}

.dashboardBody .tableHeaderButton:hover {
	color: #fff;
	background-color: #f3734c;
	border-color: #f3734c;
}

/* .APActionButtonDashboard .btn-primary {
	background-color: #294a9c;
    border: none;
    margin: 0 3px;
    padding: 2px 6px;
    font-size: 14px;
    border-radius: 5px;
    color: white;
}
.APActionButtonDashboard .btn-secondary {
    background-color: #f3734c;
    border: none;
    margin: 0 3px;
    padding: 2px 6px;
    font-size: 14px;
    border-radius: 5px;
    color: white;
} */
/* Configuration - Change Logo */

.AdminChangeLogoBx {
	width: 300px;
	height: 160px;
	border: 1px solid white;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.AdminChangeLogoBx img {
	margin: 0px 0px 0px 0px;
	width: -moz-available;
	height: 150px;
	padding: 20px;
}

/* Change favicon */
.adminFavicon {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	border: 1px solid #e1dfdf;
	border-radius: 10px;
	width: fit-content;
}

.adminFaviconImage {
	width: auto;
	padding: 14px;
	height: 140px;
	margin: 30px;
}

/* Configuration - Change Payment Details */

.APPaymentDetailsRadio input {
	min-width: 20px;
	max-width: 20px;
	margin-left: 20px;
}

.APPaymentDetailsRadio label {
	margin-left: 2px;
}

/* Configuration - Change Color Theme */
.colorPickerBody {
	width: 100%;
}

.primaryColor {
	display: inline-block;
	margin-right: 150px;
}

.primaryColor label {
	font-weight: 400;
	margin-bottom: 10px;
}

.secondaryColor {
	display: inline-block;
}

.secondaryColor label {
	font-weight: 400;
	margin-bottom: 10px;
}

.defaultColor {
	margin: 40px 0 0 0;
}

/* Configuration - Add plan */

.NumberCheck {
	display: inline-block;
	width: 100%;
	margin-top: 20px;
	padding-left: 20px;
}

.NumberCheck input[type="checkbox"] {
	margin: 0 !important;
	vertical-align: top;
}

.NumberCheck label {
	padding-left: 10px;
	vertical-align: top;
	line-height: 21px;
}

.jobPost {
	width: 100%;
}

#jobPostInput {
	width: 20%;
	display: inline-block;
	height: 49px;
}

.checkBoxDiv {
	display: inline-block;
	padding-left: 10px;
}

#unlimitedCheckbx {
	display: inline-block;
	width: 100%;
	margin-top: 20px;
	padding-left: 20px;
}

.unlimitedLabel {
	display: inline-block;
	vertical-align: top;
	padding-left: 5px;
}

/* Configuration - Manage Sub Admin */
.APManageSubadmin .ManageSubAdminHeader {
	width: 100%;
}

.ManageSubAdminHeader h2 {
	display: inline-block;
	margin-top: 15px;
}

.ManageSubAdminHeader button {
	float: right;
	margin-right: 30px;
	margin-top: 8px;
}

.manageRoleBody {
	margin-bottom: 40px;
}

.manageRoleBody .card {
	width: 90%;
}

.MainTitleChack .form-check-input {
	width: 20px;
	height: 20px;
	min-width: 0;
	min-height: 0;
	padding: 0;
	box-shadow: none;
}

.SubCheckBx .form-check-input {
	width: 20px;
	height: 20px;
	min-width: 0;
	min-height: 0;
	padding: 0;
	box-shadow: none;
}

.MainTitleChack label {
	margin-left: 10px;
}

.SubtitleBx label {
	margin-left: 10px;
}

.APDashboardSearchBx {
	position: relative;
	display: inline-block;
	margin: 25px 20px 0 0;
}

.APDashboardSearchBx input {

	border: none;
	outline: none;
	font-size: 17px;
	height: 50px;
	font-weight: 400;
	opacity: 0.8;
	min-width: 713px;
	max-width: 500px;
	border: 1px dashed #a7a6a6;
	padding: 20px 20px;
}

.APDashboardSearchBx .form-label {
	position: absolute;
	left: 12px;
	top: -13px;
	z-index: 9;
	background: #fff;
	padding: 0 8px;
	color: rgba(0, 0, 0, 0.6);
}

.manageSubadminPart1Sec1 {
	width: 100%;
	margin: 40px 0;
	border: 1px solid var(--border, #e0e0e0);
	border-radius: 10px;
	padding: 20px;
}

.manageSubadminPart1 h4 {
	font-weight: 400;
	color: rgb(58, 57, 57);
}

.manageSubadminPart1Sec1 h4 {
	font-weight: 400;
	color: rgb(110, 109, 109);
}

.APDashboardSearchButtons {
	display: inline-block;
}

.manageSubadminPart2 {
	border: 1px solid var(--border, #e0e0e0);
	border-radius: 10px;
	padding: 20px;
	margin: 40px 0;
	width: 100%;
	overflow-y: auto;
}

.manageSubadminPart2 tr {
	vertical-align: top;
}

.manageSubadminpart2 .row .btn-primary {
	font-weight: 400;
	background-color: #294a9c;
	border: none;
	margin: 0 3px;
	padding: 2px 6px;
	font-size: 14px;
	border-radius: 5px;
}

.manageSubadminpart2 .row .btn-secondary {
	font-weight: 400;
	background-color: #f3734c;
	border: none;
	margin: 0 3px;
	padding: 2px 6px;
	font-size: 14px;
	border-radius: 5px;
}

.manageSubadminpart3 {
	width: 100%;
}

.functionalityButton {
	display: inline-block;
}

.manageSubadminpart3 .pagination {
	float: right;
	margin-right: 15px;
}

.manageRoleBody {
	margin-bottom: 40px;
}

.parentRole {
	margin: 20px 0 0 0;
	border: 1px solid white;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	padding: 20px;
	border-radius: 8px;
}

.parentRole .checkBox {
	width: 20px;
	height: 20px;
	max-width: 20px;
	min-width: 20px;
}

.parentRole label {
	padding: 0 20px 0 5px;
	margin: 0;
	line-height: 22px;
	vertical-align: top;
}

.childRole {
	margin: 15px 0 0 0;
	display: flex;
}

.APActionButton {
	min-width: 108px;
}

/* .APActionButton .btn {
    margin: 0 3px;
    padding: 2px 2px;
    font-size: 14px;
    border-radius: 5px;
} */
.APActionButton .btn-secondary {
	background-color: #f3734c;
	border: none;
	margin: 3px 3px;
	padding: 2px 2px;
	font-size: 14px;
	border-radius: 5px;
	color: white;
}

.APActionButton .btn-primary {
	background-color: #294a9c;
	border: none;
	margin: 3px 3px;
	padding: 2px 2px;
	font-size: 14px;
	border-radius: 5px;
	color: white;
}

.pagination .Mui-selected {
	background-color: #294a9c;
}



/* APManageEmailSetting */

.siteSettingPaymentInfo {
	width: 100%;
}

.siteSettingPaymentInfo label {
	display: inline-block;
	margin-right: 15px;
	margin-left: 10px;
	vertical-align: top;

}

.siteSettingPaymentInfo input {
	display: inline-block;
	/* margin: 10px 0 0 10px; */
	/* padding: 10px 0 0 10px; */

}

.APMEmailSetting {
	margin-top: 3%;
}

.APMEmailSetting input {
	box-shadow: none !important;
	outline: none;
	font-size: 16px;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px dashed #a7a6a6;
	color: #797979;
	height: 50px;
	opacity: 1;
}

.APMEmailSetting .button1 {
	color: white;
	background-color: #294a9c;
	min-width: 130px;
	height: 49px;
	font-weight: 500;
}

.APMEmailSetting .button1:hover {
	color: #fff;
	background-color: #f3734c;
	border-color: #f3734c;
}

.APMEmailSetting .button2 {
	color: white;
	background-color: #f3734c;
	min-width: 150px;
	height: 49px;
	font-weight: 500;
}

.APMEmailSetting .button2:hover {
	color: #fff;
	background-color: #294a9c;
	border-color: #294a9c;
}

/* APEmployerlist */

.APEmployerlist {
	margin-top: 3%;
}

.verifiedUsername {
	margin-left: 3px;
}

.APELSection1 {
	display: inline-block;
	width: 100%
}

.APELSection1 h2 {
	display: inline-block;
}

.APELSec1Right {
	float: right;
}

.APELSection2 {
	display: flex;
	width: 1283px;
	height: 131px;
	padding: 20px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	flex-shrink: 0;
}

.APELSection2 h4 {
	color: #5A5A5A;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 24px */
}

.APEmployerlist .APELSection2Input {
	width: 320px;
	height: 42px;
	flex-shrink: 0;
	border-radius: 6px;
	border: 1px solid #E6EDFF;
}

.APEmployerlist .button1 {
	color: white;
	background-color: #294a9c;
	width: 120px;
	height: 42px;
	flex-shrink: 0;
}

.APEmployerlist .button1:hover {
	color: #fff;
	background-color: #f3734c;
	border-color: #f3734c;
}

.APEmployerlist .button2 {
	color: white;
	background-color: #f3734c;
	width: 120px;
	height: 42px;
	flex-shrink: 0;
}

.APEmployerlist .button2:hover {
	color: #fff;
	background-color: #294a9c;
	border-color: #294a9c;
}

.APELSection3 {
	display: inline-flex;
	width: 1400px;
	height: 311px;
	padding: 20px;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	flex-shrink: 0;
	border-radius: 10px;
	border: 1px solid var(--border, #E6EDFF);
	background: var(--white, #FFF);
}

.APELSection3 .th {
	display: inline-flex;
	width: 150px;
	height: 21px;
	align-items: center;
	gap: 4px;
	flex-shrink: 0;
	padding-top: 20px;
}

.APELSection3 .eachRow {
	width: 1237px;
	height: 54px;
	flex-shrink: 0;
	border-bottom: 1px solid var(--border, #E6EDFF);
	background: rgba(255, 255, 255, 0.00);
}

.eachRow input {
	width: 20px;
	height: 20px;
	flex-shrink: 0;
	border-radius: 2px;
	border: 1px solid #CEDAF9;
}

.eachRow .td {
	color: #474646;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	display: inline-flex;
	width: 150px;
	height: 21px;
	align-items: center;
	gap: 4px;
	flex-shrink: 0;
	padding-top: 20px;
}

/* APHomePageSLider */
.APHomepageSliderImage {
	width: 200px;
	height: 150px;
}

/* APJobseeker List */
.APJobseekerList .btn-primary {
	margin-top: 5px;
}

.APJobseekerList .btn-secondary {
	margin-top: 5px;
}

/* APAddJobseekers */

.APAJHeader {
	color: #294A9C;
	margin-bottom: 45px;
}

.APAJImagebox {
	margin-bottom: 45px;
}

.APAJImageArea {
	margin-top: 10px;
	width: 150px;
	height: 150px;
	background-color: rgb(233, 228, 228);
}

.APAJImagebox p {
	margin-top: 15px;
	color: rgba(71, 70, 70, 0.50);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.APAJImagebox button {
	color: #f3734c;
	background-color: white;
	font-weight: 500;
	border: 2px #f3734c solid;
	font-size: 13px;
	padding: 9px 25px;
	border-radius: 3px;
	margin-top: 10px;
}

/* APEditJobseeker */
.otherSection {
	margin-bottom: 40px;
}

.otherSectionBody {
	display: flex;
	margin-bottom: 25px;
	margin-top: 15px;
}

.otherSection p {
	padding-top: 5px;
	font-size: 18px;
}

.ActiveButtonBox {
	margin-left: 20px;
}

.activeTrue {
	color: white;
	background-color: #1c7e1c;
	font-weight: 500;
	border: 2px #1c7e1c solid;
	font-size: 14px;
	padding: 3px 6px;
	border-radius: 3px;
	margin-left: 15px;
	min-width: 100px;
	margin-top: 5px;
}

.activeFalse {
	color: white;
	background-color: #a70f0f;
	font-weight: 500;
	border: 2px #a70f0f solid;
	font-size: 14px;
	padding: 3px 6px;
	border-radius: 3px;
	margin-left: 15px;
	min-width: 100px;
	margin-top: 5px;
}

/* APAppliedJobList */
.appliedJobListNoData {
	padding: 30px 15px;
	border: 1px solid white;
	border-radius: 6px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	margin-top: 40px;
}

/* APManageCertificate */
.certificateBoxParent {
	/* display: flex; */
	margin-top: 20px;
}

.manageCertificateBox {
	width: 350px;
	height: 200px;
	border: 1px solid white;
	border-radius: 5px;
	margin: 16px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	transition: transform .5s ease;
}

.manageCertificateBox:hover {
	width: 350px;
	height: 200px;
	border: 1px solid white;
	border-radius: 5px;
	margin: 16px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	transform: scale(1.1);
}

.manageCertificateBox img {
	margin: 20px 0 0 0px;
	max-height: 150px;
}

.certificateFloatingButton {
	float: right;
	margin-top: 4px;
	transition: transform .5s ease;
}

.certificateFloatingButton:hover {
	transform: scale(1.2);
}

/* APAddCategory */

.APACHeader {
	color: #294A9C;
	margin-bottom: 45px;
}

.APACImagebox {
	margin-bottom: 45px;
}

.APACImageArea {
	margin-top: 10px;
	width: 150px;
	height: 150px;
	background-color: rgb(233, 228, 228);
}

.APACImagebox p {
	margin-top: 10px;
	color: rgba(71, 70, 70, 0.50);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.APAddCategory p {
	margin-top: 15px;
	color: rgba(71, 70, 70, 0.50);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.APACImagebox button {
	color: #f3734c;
	background-color: white;
	font-weight: 500;
	border: 2px #f3734c solid;
	font-size: 13px;
	padding: 9px 25px;
	border-radius: 3px;
	margin-top: 10px;
}

/* APJobs */
.editJobSelectedImageBox {
	width: 250px;
	height: 250px;
	border: 1px dashed grey;
	border-radius: 6px;
	margin: 10px 0;
}

.ActionBtn {
	display: flex;
}

#traditionalLink {
	color: #294A9C;
}

/* APPayment History */
.APTransactionList {
	margin-bottom: 20px;
}

.APTransactionListButton {
	color: #294a9c;
	background-color: white;
	font-weight: 400;
	border: 1px #294a9c solid;
	font-size: 14px;
	padding: 9px 25px;
	border-radius: 5px;
	/* margin-left: 10px; */
	min-width: 150px;
	/* max-width: 30px; */
}

/* APManageNewsletter */
.unsubscribeListNoData {
	padding: 30px 15px;
	border: 1px solid white;
	border-radius: 6px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.editNewsletterForm {
	margin-bottom: 50px;
}

/* APBannerList */
.BannerList .BLAdvertisementImage {
	width: 200px;
	height: 100px;
}

/* APContent */
.APTextPages .APTPSec1 {
	margin-top: 50px;
}

/* APEmailTemplate */
.APEditEmailTemplateButtons {
	display: inline-block;
	margin-top: 20px;
}

.APEmailTemplateListing {
	min-height: 160vh;
}

/* APKeyword - Requested Keyword  */
.APRequestedKeyword .requestedSelect {
	border: none;
	outline: none;
	font-size: 17px;
	height: 49px;
	font-weight: 400;
	opacity: 0.8;
	min-width: 100px;
	max-width: 1100px;
	border: 1px dashed #a7a6a6;
	padding: 9px 20px;
	color: black;

}

@media only screen and (max-width:1300px) {


	/* Styling the page scroller */
	/* Webkit (Safari and Chrome) */
	::-webkit-scrollbar {
		width: 5px;
		/* Adjust the width of the scrollbar as needed */
	}

	::-webkit-scrollbar-thumb {
		background-color: #f3734c;
		/* Change to the color you want */
		border-radius: 6px;
		/* Rounded corners for the scrollbar thumb */
	}

	.navbardefault .navitems {
		margin: 15px 0 0 17px;
		font-size: 14px;
	}

	.navButton1 {
		color: white;
		background-color: #294a9c;
		font-weight: 500;
		border: 2px #294a9c solid;
		font-size: 14px;
		padding: 6px 6px;
		border-radius: 3px;
		margin-left: 15px;
		min-width: 100px;
		margin-top: 5px;
	}

	.navButton2 {
		color: #f3734c;
		background-color: white;
		font-weight: 500;
		border: 2px #f3734c solid;
		font-size: 14px;
		padding: 6px 6px;
		border-radius: 3px;
		margin-left: 10px;
		min-width: 100px;
		margin-top: 5px;
	}
}

@media only screen and (max-width:1400px) {
	.navbardefault .navitems {
		margin: 8px 0 0 22px;
		font-weight: 700;
		font-family: 'Signika', sans-serif;
		font-size: 17px;
		position: relative;
		color: #3c3c3c;
	}

	.sliderImageBox {
		height: 400px;
		background-size: cover;
	}
}



/* MEDIA QUERY FOR MOBILE RESPONSIVENESS */

/* @media (max-width: 992px) {
	.navbardefault .endorsedCompanies {
		margin: 15px 0 0 29px !important;
		padding: 0;
	}

	.navbar-brand .frontendNavLogo {
		width: 180px;
	}

	.navbardefault .navitems {
		margin: 8px 0 0 29px;
		font-size: 17px;
		font-weight: 700;
		display: inline-block;
		width: 100%;
	}

	.defaultnavbar .navButton1 {
		background-color: #fff !important;
		border: none !important;
		color: #000 !important;
		margin-left: 29px!important;
	}

	.social-media {
		display: none !important;
	}

	.navbardefault {
		background: #eb602e;
		left: 0;
		position: absolute;
		top: 76%;
		width: 100%;
		z-index: 999;
	}

	.navbardefault .navigation {
		display: inline-block;
		max-height: none;
		overflow: visible;
		padding-bottom: 7px;
		width: 100%;
	}
} */

@media only screen and (max-width:767px) {

	/* Styling the page scroller */
	/* Webkit (Safari and Chrome) */
	::-webkit-scrollbar {
		width: 5px;
		/* Adjust the width of the scrollbar as needed */
	}

	::-webkit-scrollbar-thumb {
		background-color: #f3734c;
		/* Change to the color you want */
		border-radius: 6px;
		/* Rounded corners for the scrollbar thumb */
	}

	.RightDotImg {
		right: 0;
	}

	.LeftDotImg {
		left: 0;
	}

	.loader-container {
		position: fixed;
		width: 100%;
		height: 100%;
		/* background: #ffffffc0 url('/public/Images/loaderNew.gif') no-repeat center; */
		background: #ffffff00 url('/public/Images/lgbtiLoader.gif') no-repeat center;
		z-index: 999;
		background-size: 100px;
	}

	.endorsedCompanies a {
		padding: 0;
	}

	.navButton1 {
		color: white;
		background-color: #294a9c;
		font-weight: 500;
		border: 2px #294a9c solid;
		font-size: 13px;
		padding: 9px 20px;
		border-radius: 3px;
		margin-left: 15px;
		min-width: 135px;
	}

	.endorsedCompanies a:hover {
		color: #000 !important;
	}



	.upperSectionBox {
		left: 0;
		padding: 25px 20px 30px 20px;
		width: 100%;
		background-color: rgba(66, 39, 120, 0.8);
		border-radius: 10px;
		display: inline-block;
		text-align: left;
	}

	.upperSlider {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 99;
		width: 100%;
		padding-top: 35px;
		text-align: center;
	}

	.uploadCvButton {
		color: #f3734c;
		background-color: white;
		font-weight: 500;
		border: 2px #f3734c solid;
		font-size: 17px;
		border-radius: 3px;
		margin-left: 10px;
		padding: 9px 6px;
		min-width: 158px;
	}

	.formCentere {
		width: 100%;
		margin: 0 auto;
	}


	/* Footer */
	.FooterLinks h3 {
		color: #FFC548;
		font-size: 17px;
		font-weight: 500;
		padding-top: 10px;
	}

	.footerPlusLink {
		color: #FFC548 !important;
		float: right;
		font-size: 22px;
		margin-bottom: 10px;
		margin-right: 0px;
	}

	.SocialIcons {
		font-size: 10px;
	}

	.SocialIcons a {
		border: 2px solid #fff;
		border-radius: 50%;
		color: #fff;
		display: inline-block;
		height: 36px;
		line-height: 33px;
		margin-right: 10px;
		text-align: center;
		font-size: 14px;
		width: 36px;
	}

	.SearchIcon {
		padding: 11px 0;
		display: inline-block;
		vertical-align: top;
		/* margin: 0 3px; */
		margin: 9px 0 0 29px;
	}

	.navbardefault .navitems {
		margin: 8px 0 0 29px;
		font-size: 17px;
		font-weight: 700;
	}

	.navbardefault .jobseekers {
		margin: 8px 0 0 29px !important;
	}

	.navbardefault .endorsedCompanies {
		margin: 15px 0 0 29px !important;
		padding: 0;
	}

	.navbardefault .employers {
		margin: 5px 0 0 30px !important;
		color: white !important;
		padding: 0;
	}

	.navbardefault .employers a {

		color: white !important;
	}

	/* .navbardefault .navitems {
		color: #FC21D0 !important;
	} */

	/* APFooter */
	.APFooter {
		background-color: #c9c9c9;
		color: black;
	}

	.APFooter p {
		padding: 30px 0 30px 0px;
		text-align: center;
	}

	/* Userpage */

	.globalImage {
		display: none;
	}

	.sliderImageBox {
		height: 400px;
		background-position: 70% top;
		background-size: contain;
		background-repeat: no-repeat;
	}

	.sliderbody .owl-carousel {
		height: 135px;
	}

	.sliderbody .owl-carousel .owl-stage-outer {
		height: 100%;
	}

	.sliderbody .owl-carousel .owl-stage {
		height: 100%;
	}

	.sliderbody .owl-carousel .owl-item {
		height: 100%;
	}

	.sliderDiv {
		height: 100%;
	}

	.owl-carousel .owl-item img {
		height: 100%;
		object-fit: cover;
	}

	.inputGrp {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		width: 100%;
		padding: 0px 0px 10px 40px;
	}

	.RightDotImg img {
		padding-top: 30px;
	}

	.LeftDotImg img {
		padding-top: 515%;
	}

	.JcardFooterItem1 {
		margin-top: 9px;
	}

	.JcardFooterItem3 {
		margin-top: -35px;
	}

	.ImageCard {
		display: inline-block;
	}

	.catCardBody {
		text-align: center;
	}

	.BrowseJobstab .btn {
		margin-top: 10px;
		color: white;
		font-size: 16px;
		position: relative;
		padding: 6px;
		font-weight: 500;
	}

	.DownloadAppSection .section1text {
		text-align: center;
		margin-top: -100px;
	}

	.DownloadAppSection .section1text h2 {
		font-size: 35px;
	}

	.DownloadAppSection .section1text h3 {
		font-size: 25px;
	}

	.DownloadRightImg {
		text-align: center;
	}

	.DownloadRightImg .girlImage {
		width: 50%;
		margin-top: 30px;
		/* margin-left: 110px; */
	}

	.DownloadRightImg .phoneImage {
		width: 50%;
		margin: 0 0 0px 100px;

	}

	.list {
		text-decoration: none;
		color: white;
		text-align: left;
		font-size: 18px;
		padding-bottom: 11px;
		font-weight: 300;
		display: inline-block;
		border-bottom: 1px dashed transparent;
	}

	.sixthBlock .section2 .flexImage {
		margin-top: 20px;
	}

	.MembershipCard .btn-primary {
		background: #294a9c;
		border: none;
		border-radius: 3px;
		font-family: Roboto, sans-serif;
		font-size: 16px;
		font-weight: 500;
		margin-top: 30px;
		padding: 10px 19px;
		width: 84%;
	}

	.MembershipCard h2 {
		color: rgba(41, 74, 156, .9);
		font-size: 40px;
		font-weight: 600;
		margin: 20px 0 0;
	}

	/* Blog */
	.blogPageSection1 {
		padding: 45px 0 40px 0;
	}

	.blogPagination {
		margin: 15px 0 30px 0;
	}

	.DBPSection1 {
		padding: 45px 0 40px 0;
	}

	.DBPSection2 img {
		width: 100%;
		margin-bottom: 20px;
		min-height: auto;
	}

	/* About us */
	.aboutUs {
		overflow: hidden;
	}

	.aboutusSection1 {
		padding: 45px 0 40px 0;
	}

	.aboutusSection4 h1 {
		margin-bottom: 50px;
		margin-top: 50px;
	}

	.aboutusSection3 .row {
		margin: 30px 0 15px 0;
	}

	.aboutusImage {
		margin-bottom: 20px !important;
	}

	.col.aboutusSection3Col {
		max-width: 100%;
		display: inline-block;
		flex: auto;
		margin: 20px 0;
	}

	.aboutusSection3 h1 {
		color: #f3734c;
		font-size: 42px;
		font-weight: 600;
	}

	.aboutusDivider {
		display: none;
	}

	.jobseekerListing table {
		margin-top: 20px;
	}

	.jobseekerListing th {
		font-size: 17px;
	}

	/* How It Works */
	.privacyPolicy .PPSection1 {
		padding: 0px 0 40px 0;
	}

	.privacyPolicy .card {
		margin-top: 30px;

	}

	.passwordForget .PPSection1 {
		padding: 0px 0 40px 0;
	}

	.passwordForget .card {
		margin-top: 30px;
		width: 90%;

	}

	/* Blog  */
	.blogPagination {
		text-align: center;
		/* padding-right: 20px; */
		margin-top: 20px;
	}

	.paginationDetail {
		margin: 10px 0;
		text-align: center;
	}

	/* Contact Us */
	.contactUs {
		overflow-x: hidden;
	}

	.ContactSection1 {
		padding: 45px 0 40px 0;
	}

	.ContactDetails {
		margin: 15px 1%;
		display: inline-block;
		width: 100%;
		border: 1px #ddd solid;
		padding: 22px;
		border-radius: 10px;
		min-height: 130px;
		text-align: left;
	}

	.ContactTopHadding .card-title {
		font-size: 20px;
	}

	.ContactTopHadding .card-body {
		font-size: 17px;
	}

	.ContactForm {
		margin-bottom: 20px;
	}

	/* FAQ */
	.section1Faq {
		padding: 0px 0 40px 0;
	}

	.faqSection1 {
		padding: -70px 0;
	}

	.section2Faq .col-lg-5 {
		text-align: center;
	}

	.section2Faq .accordion-button p {
		font-size: 20px;
	}

	.section2Faq .accordion-body p {
		font-size: 18px;
	}

	.faqSection1 .card {
		max-width: 100%;
		font-size: 500;
		box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
		border: 0;
	}

	.section2Faq .upperPart {
		margin-top: 25px;
	}

	.faqBodySection1 {
		margin-bottom: 23px;
	}

	/* Search Job Page */
	.section2Item2 {
		font-size: 15px;
		padding: 2px 13px 2px 14px;
		display: inline-block;
		color: #6e6e85;
		font-weight: 500;
	}

	.SJPSection1 form {
		width: 100%;
	}

	.Jcard {
		min-height: 280px;
		position: relative;
		text-align: start;
		border: 1px solid #f6ac96;
		border-radius: 20px;
		padding: 16px;
		transition: 0.5s;
		margin-bottom: 25px;
	}

	.JcardFooterItem3 {
		font-size: 14px;
		border: 1px solid #bcbcbc;
		border-radius: 15px;
		padding: 5px 15px;
		color: rgba(36, 37, 42, 0.8);
		float: right;
		margin-top: 10px;
		/* margin: 10px; */
	}

	.JcardFooterItem2 {
		width: 50%;
		vertical-align: top;
	}

	.shareNodeJobCard {
		top: 19px;
	}

	.locationBoxSearchpage {
		position: relative;
	}

	.suggestionsSmall {
		background-color: #fff;
		border-radius: 8px;
		margin-top: 2px;
		padding: 10px;
		position: absolute;
		top: 38px;
		width: 100%;
		z-index: 99;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	}

	/* Sidebar Transition */
	.SBBody {
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.9s ease-in-out;
	}

	.SBBody.open {
		max-height: 1000px;
		/* Adjust as needed */
		transition: max-height 0.9s ease-in-out;
	}

	.IADMainBx {
		margin-top: 10px;
	}


	/* Jobseeker Page */
	.editProfile select {
		border: none;
		outline: none;
		font-size: 17px;
		height: 62px;
		font-weight: 400;
		color: black;
		opacity: 0.8;
		min-width: -moz-fit-content;
		min-width: fit-content;
		border: 1px dashed #a7a6a6;
	}

	.editProfile input {
		color: black;
		border: none;
		outline: none;
		font-size: 17px;
		height: 62px;
		font-weight: 400;
		opacity: 0.8;
		min-width: -moz-fit-content;
		min-width: fit-content;
		border: 1px dashed #a7a6a6;
		padding: 20px 20px;
	}

	.PageHeader {
		margin-top: 20px;
	}

	.bottomButtons {
		text-align: center;
	}

	.JobseekerProfileBxDetailTop {
		text-align: center;
	}

	.JobseekerProfileBxDetailTop h3 {
		margin-top: 10px;
		font-size: 18px;
		padding-right: 20px;
	}

	.ProfileDetails {
		text-align: left;
		padding-left: 70px;
	}

	.JobseekerEducationDetails {
		width: 100%;
		display: inline-block;
	}

	.CurrentPlanBx {
		display: inline-block;
		width: 100%;
	}

	.CurrentPlanBx .btn-primary {
		float: right;
	}

	.JSEPFirstSegment {
		width: 100%;
		display: block;
	}

	.JSEPFirstSegment img {
		width: 100px;
		height: 100px;
		border-radius: 50% !important;
		border: 1px solid black;
		margin-right: 5%;
	}

	.myProfileLinks {
		text-align: center;
	}

	.button1 {
		display: inline-block;
	}

	.myProfileEachButton {
		margin: 20px 0px 0 0;
	}

	.CurrentPlanBx label {
		width: auto;
	}

	.JobseekerCertificatesDetails ul li {
		width: fit-content;
	}

	.JSEPFirstSegment .mt-5 {
		display: inline-block;
	}

	.jobseekerLogin form {
		padding: 0 5% 0 5%;
	}

	.employerLogin form {
		padding: 0 5% 0 5%;
	}

	.employerLogin input {
		border: none;
		outline: none;
		font-size: 14px;
		height: 45px;
		font-weight: 400;
		opacity: 0.8;
		min-width: -moz-fit-content;
		min-width: fit-content;
		border: 1px dashed #a7a6a6;
	}

	.jobseekerLogin input {
		border: none;
		outline: none;
		font-size: 14px;
		height: 45px;
		font-weight: 400;
		opacity: 0.8;
		min-width: -moz-fit-content;
		min-width: fit-content;
		border: 1px dashed #a7a6a6;
	}

	.button1 {
		color: white;
		background-color: #294a9c;
		font-weight: 500;
		border: 2px #294a9c solid;
		font-size: 14px;
		padding: 12px 40px;
		border-radius: 3px;
	}

	.searcharea .button1 {
		width: 100%;
	}

	.button2 {
		color: #f3734c;
		background-color: white;
		font-weight: 500;
		border: 2px #f3734c solid;
		font-size: 14px;
		padding: 9px 30px;
		border-radius: 3px;
		margin-left: 10px;
	}

	.EPRegistrationBottomText {
		font-size: 13px;
		margin-bottom: 10px;
	}

	.JSEPFirstSegment {
		margin: 3% 0 11% 3%;
	}

	.SJPSection1 .formItems {
		align-items: start;
		background-color: #feeae4;
		border-radius: 5px;
		display: block;
		margin: 6% 0;
		padding: 2%;
	}

	.searchItems {
		width: 100%;
		margin: 10px 0 !important;
	}

	/* Jobseeker - Edit Profile */
	.JSEPFinalButton {
		text-align: center;
	}

	.EPChangePhoto {
		color: white;
		background-color: #294a9c;
		font-weight: 500;
		border: 2px #294a9c solid;
		font-size: 11px;
		padding: 9px 11px;
		border-radius: 3px;

	}

	.JSEPFirstSegment {
		padding-left: 15px;
	}

	.radioInput input {
		height: auto;
	}

	/* Jobseeker - Education */
	.jobseekerAddMore {
		margin-top: 20px;
	}

	.bottomButtonsEducation {
		text-align: center;
		margin-left: -28px;
	}

	/* Jobseeker - Experience */
	.bottomButtonsExperience {
		text-align: center;
	}

	/* Jobseeker - Professional Registration */
	.specialJSPR .form-label {
		font-size: 15px;
	}

	.jobseekerAddMore button {
		margin-top: -67px;
	}

	/* Jobseeker - MakeACV */
	.headerMakeCV {
		margin-top: 20px;
	}

	.makeCV .headerMakeCV img {
		padding: 5px 0 15px 0;
	}

	.makeaCVBottomBotton {
		margin-top: 40px;
		text-align: center;
	}

	/* Jobseeker - Payment History */
	.empPaginationData {
		margin-left: 0;
		text-align: center;
	}

	.empPaginationButton {
		margin: 20px 0 20px 0;
	}

	/* Jobseeker - Mail Detail  */
	.mailDetail h3 {
		padding-top: 5px;
	}

	.MDButtonBx .replyButton {
		float: right;
		color: white;
		background-color: #294a9c;
		font-weight: 500;
		border: 2px #294a9c solid;
		font-size: 14px;
		border-radius: 3px;
		min-width: 110px;
		height: 40px;
	}

	/* Jobseeker - Manage Alerts */
	.JSMAHeaderButton {
		color: white;
		background-color: #294a9c;
		font-weight: 500;
		border: 2px #294a9c solid;
		font-size: 11px;
		padding: 9px 20px;
		border-radius: 3px;
	}

	.JSEditAlert h3 {
		padding-top: 5px;
	}

	/* Jobseeker - Change Photo */
	/* .JSChangePhoto {
		border: 1px solid #fff;
		border-radius: 5px;
		box-shadow: 0 3px 8px rgba(0,0,0,.24);
		height: 170px;
		padding: 10px;
		width: 170px;
	}
	.JSChangePhoto img {
		border-radius: 5px;
		height: 150px;
		width: 150px;
	} */
	.buttonsSec {
		text-align: center;
	}

	/* Employer */
	/* Employer - Create Job */
	.createJob h3 {
		padding-top: 5px;
	}

	/* Employer - Manage Job */
	.MJHeader button {
		color: white;
		background-color: #294a9c;
		font-weight: 500;
		border: 2px #294a9c solid;
		font-size: 12px;
		padding: 9px 20px;
		border-radius: 3px;
	}

	/* Employer - Inner Account Details */
	.MyProfileImg {
		width: 100px;
		height: 100px;
		display: inline-block;
		position: relative;
		border-radius: 50%;
		overflow: hidden;
		vertical-align: middle;
		margin-right: 10px;
		background: transparent;
		box-shadow: 0 0 5px 0 #9e9e9c;
	}

	.MYProfileHeader {
		width: 100%;
		margin-bottom: 30px;
	}

	.MyProfileDetails {
		display: inline-block;
		vertical-align: middle;
		background: rgba(0, 0, 0, 0.3);
		padding: 6px 10px;
		/* margin-bottom: 24px; */
		box-shadow: 2px 3px 16px 0 rgba(0, 0, 0, 0.2);
	}

	/* .MyProfileDetails {
		float: right;
	} */
	.MyProfileUpgratePlan .btn-primary {
		color: white;
		background-color: #f3734c;
		font-weight: 500;
		border: none;
		font-size: 14px;
		padding: 7px 17px 3px;
		border-radius: 3px;
	}

	.UploadBackBg {
		position: absolute;
		right: 0;
		bottom: 0;
		border-radius: 0;
		background-color: #294a9c;
		border: none;
		padding: 3px 15px;
	}

	.JobseekerProfileActive {
		display: inline-block;
		width: 100%;
	}

	.JobseekerProfileSearch {
		display: block;
		margin-top: 10px;
	}

	.Calcultn {
		display: inline-block;
		vertical-align: middle;
		text-align: left;
		margin-left: 0px;
	}

	.JobseekersContentTop ul {
		display: inline-block;
		width: 100%;
	}

	.JobseekersContentTop ul li {
		display: inline-block;
		padding-right: 20px;
		text-align: center;
		padding-bottom: 20px;
	}

	.JobseekersContentSearch {
		display: block;
		/* float: right; */
		width: 100%;
		margin-top: 30px;
		position: relative;
	}

	.JobseekersSearchContent th p {
		font-size: 14px;
	}

	.EPEJimageViewer {
		margin-top: 10px;
	}

	.EPEJimageViewer img {
		width: 138px;
		margin-top: 10px;
	}

	.JobseekersTabsDetailsList label {
		width: 40%;
		color: #4a4948;
		font-size: 15px;
		margin-top: 6px;
		vertical-align: top;
		font-weight: 600;
	}

	.JobseekersTabsDetailsList span {
		width: 58%;
		color: #4a4948;
		font-size: 15px;
		display: inline-block;
	}



	/* Employer - Payment History */
	.paymentHistory .paymentButton {
		color: white;
		background-color: #294a9c;
		font-weight: 500;
		border: 2px #294a9c solid;
		font-size: 14px;
		padding: 9px 2px;
		border-radius: 3px;
		min-width: 90px;
	}

	/* Employer - User detail */
	.FLPRightSideHeader {
		width: 100%;
	}

	.FLPInnerRight {
		float: right;
	}

	.FLPlink {
		display: block;
		text-align: left;
	}

	#FLLink {
		margin-left: 2px;
	}

	.ImageSpace {
		margin-bottom: 20px;
	}

	.FLPLeftSideHeader .ImageSpace {
		width: 190px;
		height: 200px;
		background-color: #cfcccc;
	}

	/* Employer - Import Jobseeker */
	.importJobseeker h3 {
		padding-top: 5px;
	}

	/* Employer - Edit Profile */
	.editProfile h3 {
		padding-top: 5px;
	}

	/* Employer - Change Password */
	.changePassword h3 {
		padding-top: 5px;
	}

	/* Employer - Change Logo */
	.changeLogo h3 {
		padding-top: 5px;
	}

	/* Employer - My Profile */

	.myProfile h3 {
		padding-top: 7px;
	}

	.myProfile input {
		border: none;
		outline: none;
		font-size: 17px;
		height: 55px;
		font-weight: 400;
		opacity: 0.8;
		min-width: -moz-fit-content;
		min-width: fit-content;
		border: 1px dashed #a7a6a6;
		padding: 0 20px;
		box-shadow: none;
	}

	.ChoosPlanBx {
		display: grid;
		width: 100%;
		border: 1px dashed #d0cece;
		padding: 20px 20px;
		height: auto;
		border-radius: 5px;
		transition: all .3s ease-in-out;
	}

	/* Employer - Membership Plan */
	.MembershipCard h4 {
		color: #25242a;
		font-size: 22px;
		font-weight: 500;
	}

	.planDetailsJobseeker .parent .child1 {
		max-width: 77%;
		min-width: 86%;
	}

	.MembershipCard {
		padding: 30px 3px;
	}

	.MembershipCard .btn-secondary {
		background: #f3734c;
		border: none;
		border-radius: 3px;
		font-size: 14px;
		font-weight: 500;
		font-family: 'Roboto', sans-serif;
		padding: 10px 17px;
		width: 84%;
		margin-top: 30px;
	}

	.membershipCardUpperPart {
		min-height: 130px;
	}

	.ApplyAll {
		border-top: 2px solid rgba(222, 222, 222, 0.5);
		margin-top: 20px;
		padding: 21px 29px 0px;
		text-align: left;
		margin-right: 19px;
	}

	.applyoption {
		padding: 23px 0 0 12px;
		text-align: left;
	}

	.MembershipCard h2 {
		color: rgba(41, 74, 156, .9);
		font-size: 33px;
		font-weight: 600;
		margin: 20px 0 0;
	}

	.MembershipCard .btn-primary {
		background: #294a9c;
		border: none;
		border-radius: 3px;
		font-family: Roboto, sans-serif;
		font-size: 14px;
		font-weight: 500;
		margin-top: 30px;
		padding: 10px 17px;
		width: 84%;
	}

	.ApplyAll .form-check-label {
		color: #25242a;
		font-size: 14px;
		opacity: 0.7;
	}

	.applyoption li {
		font-size: 12px;
		margin-left: 20px;
	}

	.membershipPlan img {
		padding: 0px 0px 20px 0px;
	}

	/* Employer - Sidebar */
	.sidebarEachHeader h3 {
		font-size: 20px;
		/* margin-left: 3px; */
		color: white;
		/* padding: 20px; */
	}

	.sidebarEachHeader a {
		color: white;
		float: right;
		padding: 20px;
	}

	.sidebarEachHeader .sidebarPlusLink {
		margin-bottom: 0;
		font-size: 24px;
	}

	.SidebarSection1 .SBHeader h3 {
		color: black;
		padding: 15px 0 15px 0;
	}

	.SBHeader .sidebarEachHeader a {
		color: #3a3a3a;
		float: right;
		padding: 9px 0 0;
	}

	.SidebarSection2 .SBHeader h3 {
		color: black;
		padding: 15px 0 15px 0;
	}

	.SBHeader hr {
		display: none;
	}

	.SidebarSection3 .SBHeader h3 {
		color: black;
		padding-top: 12px;
	}

	/* After giving to madan sir. Append this code in his code.*/

	/* Job description */
	.logo-detail {
		display: inline-block;
		width: 150px;
		height: 150px;
		background: #f1f1f1;
		border-radius: 5px;
		overflow: hidden;
	}

	.job-meta-detail {
		display: inline-block;
		width: 50%;
	}

	.JobDetailBtnBx {
		text-align: center;
		margin-top: 50px;
	}

	.JDSection2 iframe {
		width: 100%;
	}

	/* Login page */
	.reCaptchaLogin iframe {
		width: 100%;
		transform: scale(0.77);
		transform-origin: 0 0;
	}

	.reCaptchaLogin {
		position: relative;
	}

	.reCaptchaLogin .CaptchaVerify {
		position: absolute;
		left: 0;
		bottom: -6px;
		font-size: 14px;
	}

	/* Register Page */
	.leftSection .card-title p {
		font-size: 5px;
	}

	/* Company Profile */
	.CPFPart1 {
		display: inline-block;
		margin-right: 0;
		width: 100%;
		color: #000 !important;
	}

	.CPFifthPart1 {
		display: inline-block;
		margin-right: 0;
		width: 100%;
		color: #000 !important;
	}

	.CPTPart1 {
		display: inline-block;
		margin-right: 0;
		width: 100%;
		color: #000 !important;
	}

	.companyProfileCardFooter .companyProfileContact {
		width: 100%;
		padding-top: 10px;
	}

	.CPFooter {
		display: inline-block !important;
	}

	.onlyLink {
		margin-top: 20px;
	}

	.companyProfileCardRight {
		margin-bottom: 20px;
		overflow: hidden;
	}

	.companyProfileCardRight .row {
		overflow: hidden;
	}

	.dashboardCard1 {
		margin-bottom: 10px;
	}

	.dashboardCard2 {
		margin-bottom: 10px;
	}

	.dashboardCard3 {
		margin-bottom: 10px;
	}

	.dashboardCard4 {
		margin-bottom: 10px;
	}



	/* ADMIN PANEL  */

	/* Admin Navbar */
	.APNavSectionLeft {
		margin-left: 0px;
	}

	.APNavSectionRight .part1 {
		margin-right: 12px;
	}

	.APNavSectionRight .part1 a {
		margin: 0 10px;
		padding: 0;
	}

	.APNavSectionRight .part2 .SearchIcon {
		margin: 0;
		padding: 0;
	}

	.APNavSectionRight .part2 .APNavInner3 .custom-dropdown-menu {
		left: -70px;
		right: 10px;
		width: 283px;
		position: absolute;
		right: auto;
	}

	.APNavSectionRight .part2 .APNavInner3 .custom-dropdown-menu .dropdownMenu {
		text-align: left;
		margin: 5px 0;
	}

	.dropdownMenuLower {
		margin-top: 20px;
		background-color: #f3734c;
		padding: 8px 0;
		border-radius: 7px;
	}

	/* Admin Sidebar */
	.Sidebar {
		padding-bottom: 0;
		position: initial;
		width: 100%;
		margin-top: 84px;
		height: auto;
	}

	.site-min-height {
		margin: 20px 0 30px 0;
		padding: 0 15px;
	}

	.SidebarList {
		margin-top: 0;
	}

	.APEmailTemplateListing {
		min-height: 430vh;
	}

	/* Admin Dashboard */
	.dashboardHeader {
		margin: 15px;
	}

	.dashboardSection1 {
		width: 100%;
		margin-top: 30px;
		margin-left: 0px;
	}

	.recharts-wrapper {
		width: 100% !important;
		height: 240px !important;
	}

	.recharts-surface {
		width: 100%;
		height: 240px;
	}

	.recharts-legend-wrapper {
		display: none;
	}

	.loginCardStyles {
		width: 100%;
		padding: 20px;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		border: none;
		margin: 150px auto 0;
	}

	.APPaymentDetailsRadio input {
		min-width: 15px;
		max-width: 15px;
		margin-left: 20px;
		height: 13px;
	}

	.APBasic input {
		border: none;
		outline: none;
		font-size: 17px;
		height: 60px;
		font-weight: 400;
		opacity: 0.8;
		min-width: 300px;
		max-width: 350px;
		border: 1px dashed #a7a6a6;
		padding: 20px 20px;
	}

	.APBasic select {
		border: none;
		outline: none;
		font-size: 17px;
		height: 60px;
		font-weight: 400;
		opacity: 0.8;
		min-width: 100px;
		max-width: 1100px;
		border: 1px dashed #a7a6a6;
		padding: 20px 20px;
		color: black;
	}

	.APButton3 {
		color: #294a9c;
		background-color: white;
		font-weight: 400;
		border: 1px #294a9c solid;
		font-size: 14px;
		padding: 9px 25px;
		border-radius: 5px;
		min-width: 150px;
		margin: 5px;
	}

	.APButton4 {
		color: #f3734c;
		background-color: white;
		font-weight: 400;
		border: 1px #f3734c solid;
		font-size: 14px;
		padding: 9px 25px;
		border-radius: 5px;
		margin: 5px;
		min-width: 150px;
	}

	.navButton1 {
		color: white;
		background-color: #294a9c;
		font-weight: 500;
		border: 2px #294a9c solid;
		font-size: 13px;
		padding: 9px 20px;
		border-radius: 3px;
		/* margin-left: 15px; */
		min-width: 135px;
	}

	.APActionButton .btn-secondary {
		background-color: #f3734c;
		border: none;
		margin: 3px 3px;
		padding: 2px 2px;
		font-size: 14px;
		border-radius: 5px;
		color: white;
	}

	.APActionButton .btn-primary {
		background-color: #294a9c;
		border: none;
		margin: 3px 3px;
		padding: 2px 2px;
		font-size: 14px;
		border-radius: 5px;
		color: white;
	}

	.breadCumb {
		margin: 10px 25px;
	}

	.breadCumb1 {
		margin: 20px 0px;
	}

	.adminPageHeading {
		margin: 0 25px
	}

	.adminForm {
		padding: 0 25px;
	}

	.APDashboardSearchBx {
		margin: 25px 0px 0 0;
		width: 100%;
	}

	.APDashboardSearchBx input {
		max-width: 100%;
		min-width: 100%;
	}

	.manageSubadminPart2 {
		overflow-y: auto;

	}

	.manageSubadminpart3 .pagination {
		float: right;
		margin-top: 40px;
	}

	.ManageSubAdminHeader button {
		float: right;
		margin-right: 0px;
	}

	.APHomepageSliderImage {
		width: 100px;
		height: 60px;
	}

	.APManageSubadmin .ManageSubAdminHeader {
		width: 100%;
		display: inline-block;
	}

	.ManageSubAdminHeader h2 {
		display: inline-block;
		margin-top: 15px;
		width: 100%;
	}

	.ManageSubAdminHeader button {
		float: left;
		margin-right: 10px;
		margin-left: 0;
		margin-top: 20px;
	}

	.selectedInputImage {
		width: auto;
		height: 150px;
		/* border: 1px solid grey; */
		/* border-radius: 10px; */
		margin: 10px 0;
	}

	/* .JSChangePhoto {
		border: 1px solid white;
		width: 30vh;
		height: 17vh;
		border-radius: 5px;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	} */

	.modalParent {
		margin: 10px 0 10px 0;
	}

	.modalLeft {
		width: 50%;
	}

	.modalRight {
		width: 48%;
	}
}


/* styles.css */
.modalMain {
	width: 100%;
}

.adminModal {
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	max-width: 700px;
	max-height: 500px;
	background-color: white;
	border: 2px solid #000;
	box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
	padding: 20px;
}

.modals .modalHead h1 {
	color: #f3734c;
}

/* Add a vertical scrollbar when needed */
/* .modal-content {
	max-height: 500px;
	overflow-y: auto;
	overflow-x: auto;
}

  
/* Close button style */
.close-button {
	position: absolute;
	/* top: 10px; */
	/* right: 10px; */
	float: right;
}

.modalParent {
	width: 100%;
	margin: 10px 0 10px 15px;
}

.modalLeft {
	display: inline-block;
	font-weight: 500;
	width: 36%;
	vertical-align: top;
	font-size: 18px;
}

.modalRight {
	display: inline-block;
	width: 62%;
	font-size: 18px;
}

.membershipModal {
	max-width: 100%;
	max-height: initial;
	background: transparent;
	border: none;
	box-shadow: none;
}

.leftData {
	font-size: 20px;
}

.rightData {
	font-size: 20px;
}

.emailTemplateModalAdmin {
	font-size: 20px;
	margin-left: 12px;
}

.dateInputs {
	margin-top: 18px;
	display: flex;
}

.dateFilter input {
	border: none;
	outline: none;
	font-size: 17px;
	height: 50px;
	font-weight: 400;
	opacity: 0.8;
	/* min-width: 100px; */
	max-width: 261px;
	border: 1px dashed #a7a6a6;
	padding: 20px 20px;
}

.dateFilter {
	position: relative;
	margin-right: 23px;
}

.dateFilter .form-label {
	position: absolute;
	left: 12px;
	top: -13px;
	z-index: 9;
	background: #fff;
	padding: 0 8px;
	color: rgba(0, 0, 0, 0.6);
}

.swal2-container {
	z-index: 99999
}










/* New Search Jobs page Css start */

.breadcumbs {
	padding: 10px 0 10px 0;
	margin-bottom: 0px;
	list-style: none;
	background-color: inherit;
	border-radius: inherit;
	border-bottom: 1px solid #ddd;
	text-align: right;
}

.breadcumbs li {
	display: inline-block;
	font-size: 90%;
}

.breadcumbs li a {

	color: #666 !important;

}

.breadcumbs>li+li::before {
	color: #999;
	font-family: 'FontAwesome';
	content: "\f0da";
	padding: 0 8px 0 15px;
}

.sectionJobListing {
	margin: 30px 0;
}

#searchBlock {
	background-color: #e1e1e1;
	padding: 20px;
}

.paginationpart i {
	margin-left: 10px;
}

.paginationpart {

	margin-bottom: 20px;
}

#searchBlock h2 {
	/* color: #422778;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 130%;
    padding: 0;
    margin: 0 0 20px 0;
    text-transform: uppercase; */
	color: #000;
	font-family: 'Signika', sans-serif;
	font-weight: 600;
	font-size: 18px;
	padding: 0;
	margin: 0 0 20px 0;
	text-transform: uppercase;
}

#searchBlock .btn-primary {
	/* background-color: #FC21D0;
    border: none;
    padding: 8px 0px;
    font-size: 100%;
    font-weight: 400;
    width: 100%;
	margin-bottom: 15px; */
	background-color: #EB602F;
	border: none;
	padding: 8px 0px;
	font-size: 16px;
	font-weight: 600;
	width: 100%;
	margin-bottom: 10px;
}

#searchBlock .btn-primary:hover {
	background-color: #666;
}

#searchBlock .btn-clear {
	background: #000000 !important;
}

/* .job-list{
	background-color: #F0F0F0;
    border: none;
	padding: 15px 15px 10px 15px;
    
    margin-bottom: 25px;
    font-size: 90%;
} */
.job-list {
	padding: 25px 25px 15px 25px;
	border: 1px solid #FFC548;
	margin-bottom: 25px;
	font-size: 15px;
	min-height: 170px;
}

#featured-jobs-home .col-md-6:nth-child(4n-7) .job-list,
#featured-jobs-home .col-md-6:nth-child(4n-6) .job-list {
	background-color: #FFF6E3;
}

.job-list h2 a:hover {
	color: #000;
	text-decoration: underline !important;
}

.job-list h2 a {
	color: #EB602F;
	/* display: inline-block !important; */
}


.job-list h2 {
	text-align: left;
	font-size: 16px;
	margin: 0 0 8px 0;
	padding: 0;
	color: #EB602F;
	font-weight: 600;
	/* min-height: 165px; */
	clear: none;
	/* max-width: 600px !important; */
}

.job-list h3 {
	font-size: 110%;
	margin: 0;
	padding: 0 0 8px 0;
	color: #FC21D0;
	font-weight: 300;
	clear: none;
}

.job-list h3 a {
	color: #000;
}

.job-list h3 a:hover {
	text-decoration: underline !important;
}

.job-list .job-list-buttons {
	font-size: 100%;
	display: inline-block;

	margin: 10px 0;
}

.job-list .btn-moreInfo {
	margin-right: 5px;
	background: #EB602E !important;
	border-color: #EB602E !important;
}

.job-list .btn-moreInfo:hover {

	background: #FFC548 !important;
	border-color: #FFC548 !important;
}

.job-list .btn-primary {
	padding: 5px 20px;
	font-weight: 600;
	font-size: 100%;

	background-color: #422778;
	border-color: #422778;

}

.job-list p {
	font-size: 16px;
	margin: 0 0 10px 0;
	padding: 0;
	color: #666;
	line-height: 160%;
}

.job-list .btn-apply {
	margin-right: 5px;
	background-color: #000;
	border-color: #000;
}

.job-list .btn-apply:hover {
	background-color: #EB602F;
	border-color: #EB602F;

}

.job-list .btn-default {
	border: none;
	padding: 0;
	margin: 0 0 0 5px;
	text-transform: uppercase;
	font-size: 80%;
	text-align: left;
	background: none;

}

.job-list .btn-save {
	margin-right: 7px;
	background: #939393;
	border-color: #939393;


}

.job-list .btn-save:hover {
	background: #EB602E;
	border-color: #EB602E;



}

.job-list .btn-default:hover {
	color: #EB602F;
}

.job-list img {
	float: right;

	margin: 0px 0 10px 10px;
	mix-blend-mode: multiply;
}

.record-count p {
	font-size: 90%;
	color: #666;
}

.paginationpart .btn-default i {
	color: #23527c;

}

.categorySelect .form-select {
	height: unset !important;
	border: unset !important;
}

/* .form-select {
    box-shadow: none !important;
    outline: none;
    font-size: 16px;
    min-width: -moz-fit-content;
    min-width: fit-content;
  width: 100%;
    color: #797979;
    height: 35px;
	padding: 4px 10px;
    opacity: 1;
	border: none;
	border-radius: var(--bs-border-radius);
} */
/* .formm-select{
	display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s 
} */



/* New Search Jobs page Css End */

/* Footer new code start  */

footer {
	padding: 50px 0 40px 0;
	margin-top: 1px;
	background-color: #000;
}

.footer-nav {
	margin-top: 0px;
}

.footer-nav li {
	font-size: 90%;
	font-weight: 600;
	margin-bottom: 10px;
	text-transform: uppercase;

}

.footer-nav>li>a {
	color: #FFC548 !important;
	padding: 0 15px 0 0;
	margin: 0 0px 0 0;
}

.footer-nav>li>a:hover {
	color: #EB602F !important;
}

footer hr {
	margin: 20px 0 20px 0;
	border-color: #666;
	display: block;
	clear: both;
	width: 100%;
}

img.img-responsive {
	margin: -246px 0 0 0;
}

.sub-line li {
	font-size: 90%;
}

.sub-line {
	display: flex;
	gap: 8px;
	padding-left: 0;
	margin-left: -5px;
	list-style: none;
}

.sub-line li a {
	color: #EB602F;
	padding: 0 15px 0 0;
	margin: 0 0px 0 0;
}

.sub-line li a:hover {
	color: #FFC548;
	text-decoration: underline !important;
}

.fa-facebook-official:before {
	content: "\f230";
}

.sub-line li .fa:hover {
	color: #eee;
	font-size: 140%;
}

.sub-line li .fa {
	color: #EB602F;
	font-size: 140%;
}

footer img {
	/* margin: -90px 0 0 0; */
	height: 150px;
	width: 150px;

}

.text-right {
	text-align: right;
}


/* Footer new code end  */



/* New JOb description start  */
.job-details {
	margin: 30px 0;
}

.job-details .titleJobDetails h1 {

	/* color: #422778;
	font-size: 140%;
	font-weight: 400;
	margin: 0 0 10px 0;
	padding: 0; */
	color: #000;
	font-size: 30px;
	font-weight: 600;
	margin: 0 0 10px 0;
	padding: 0;

}

.job-details .titleJobDetails h2 {

	/* color: #FC21D0;
	font-size: 24px;
	font-weight: 300;
	margin: 0 0 20px 0;
	padding: 0; */
	color: #EB602F;
	font-size: 24px;
	font-weight: 300;
	margin: 0 0 20px 0;
	padding: 0;
}

.job-details .titleJobDetails img {

	float: right;
	width: 200px;
}

.titleJobDetails {
	display: inline-block;
	width: 100%;
	margin-bottom: 20px;
}

.logoProfile {
	/* margin: 0 0 10px 10px; */
	max-width: 160px !important;
	max-height: 90px !important;
}

.vacany-details {
	min-height: 20px;
	padding: 19px;
	margin-bottom: 20px;
	background-color: #f5f5f5;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
	font-size: 90%;
	color: #444;
}

.job-options {

	margin: 0 0 20px 0;
	border-bottom: 1px solid #ddd;
	padding: 0 0 20px 0;

}

.job-options .btn {
	width: 100%;
}

.job-options .btn {
	color: #fff;
	margin-bottom: 10px;
}

.job-options .btn-apply {
	background-color: #EB602E;
	border-color: #EB602E;
}

.job-options .btn-apply:hover {
	background-color: #000000;
	border-color: #000000;
}

.job-options .btn-save {
	background-color: #939393;
	border-color: #939393;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
}

.job-options .btn-save:hover {
	background-color: #000000;
	border-color: #000000;
}

.btn-lg {
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	border-radius: 6px;
}

.job-options .apply-links {
	width: 90%;
	margin: 10px auto 0 auto;
}

.job-options p a {
	color: #757575;
	display: flex;
	align-items: center;
	gap: 8px;
}

.job-options p a:hover {
	text-decoration: underline !important;
	border-bottom: 1px solid 757575;
}

.promo-block {
	padding: 16px 20px 16px 20px;
	border-radius: 5px;
	border-bottom: 1px solid #ddd;
	margin: 0 0 20px 0;
	background-color: #FFF6E3;
}

.promo-block h2 {
	/* font-size: 125%;
	margin: 0 0 10px 0;
	padding: 0;
	color: #FC21D0;
	font-weight: 300;
	line-height: 120%; */
	font-size: 18px;
	margin: 0 0 10px 0;
	padding: 0;
	color: #EB602F;
	font-weight: 700;
	line-height: 120%;
}

.promo-block ul {
	list-style: none;
	margin: 0px 0px 15px 0px !important;
	padding: 0;
}

.promo-block li {
	margin: 0 0 4px 0;
	padding: 0 0 0 24px;
	position: relative;
	font-size: 90%;
	color: #696969;
	line-height: 160%;
}

.promo-block li img {
	width: 20px;
	position: absolute;
	top: 3px;
	left: 0;
	color: #FC21D0;
}

.promo-block p {
	color: #422778;
	font-size: 100%;
}

.btn-sm {
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}

.promo-block .btn-primary {
	background-color: #EB602F;
	border-color: #EB602F;
}

.promo-block .btn-primary:hover {
	background-color: #000;
	border-color: #000;
}

.promo-block .text-customize {
	color: #696969;
	line-height: 160%;
	margin: 0 0 10px;
}

/* New JOb description end  */

/* New About us Code start  */
.content-general {
	margin: 40px 0 60px 0;
}


.content-general h1 {
	color: #000;
	font-size: 30px;
	font-weight: 600;
	margin: 0 0 30px 0;
	padding: 0;
}

.content-general ul {

	margin: 50px 0 30px 0px;
	padding: 0;
}

.content-general ul li {
	color: #696969;
	font-size: 100%;
	line-height: 160%;
	margin: 0px 0 10px 0;

}

.content-general ul li strong {
	color: #FC21D0;
	font-size: 130%;
	font-weight: 700;

}

.content-general h2 {
	color: #EB602F;
	font-size: 22px;
	font-weight: 300;
	margin: 0 0 10px 0;
	padding: 0;
	line-height: 22px;
}

hr {

	margin: 30px 0;
}

.content-general .img-circle {
	max-width: 200px;
	float: right;
	border-radius: 50%;
	margin: 0 0 20px 20px;
}

.content-general p {
	color: #696969;
	font-size: 16px;
	line-height: 160%;
}

.content-general h3 {
	color: #000;
	font-size: 19px;
	font-weight: 300;
	margin: 20px 0 10px 0;
	padding: 0;
	line-height: 140%;
}

.content-general h3 a {
	color: #422778;
	text-decoration: underline;
}

.postandmanage {
	color: #8418C1;
	font-size: 120%;
	font-weight: 400;
	margin: 20px 0 5px 0;
	padding: 0;
	line-height: 140%;
}

.promo-block h3 {
	color: #000;
	font-size: 120%;
	font-weight: 400;
	margin: 20px 0 5px 0;
	padding: 0;
	line-height: 140%;
}

.promo-block img {
	width: 15px;
	margin-right: 5px;
	color: #FC21D0;
}

.promo-block p {
	color: #696969;
	font-size: 100%;
	line-height: 160%;
	margin: 0 0 10px;
}



/* New About us Code end  */


@media (max-width: 767px) {

	/* New JOb description start media query   */
	.job-details .titleJobDetails h1 {
		font-size: 115%;
	}

	.employersLogo {

		max-height: 45px;


	}

	.home-search-block {
		border-top: unset !important;
	}

	.job-details .titleJobDetails h2 {
		font-size: 120%;
	}

	/* Listing page New Media query start  */
	.job-list h2 {
		font-size: 90%;
	}

	.job-list h3 {
		font-size: 80%;
	}

	.job-list img {
		width: 65px;
		height: auto;
	}

	.job-list .job-list-buttons {

		display: block;

	}

	.job-list .btn-primary {

		font-size: 65%;

	}

	.job-list .btn-default {

		font-size: 68%;

	}

	.footerAddicon {
		position: absolute;
		top: 0px;
		right: 0;
		/* left: 369px; */
		color: #B895FF;
		float: right;
		font-size: 22px;
		margin-bottom: 32px;
		margin-right: 0px;
		margin-left: 0;
	}

	.footersubLinks {
		/* display:flex;
	justify-content: space-between; */
		/* float: right; */
		width: 100%;
		position: relative;
		/* margin-bottom: 20px; */
		margin: 0px 0px 30px 0;
	}

	.FooterListLinks h3 {
		color: #FFC548 !important;
		font-size: 17px;
		font-weight: 500;
		padding-top: 10px;
	}

	.footer-nav ul {
		margin: 15px 0;
	}

	.footer-nav li a {
		color: #FFC548;
		padding: 0 15px 0 0;
		margin: 0 0px 0 0;
	}

	.footer-nav li a:hover {
		color: #EB602F !important;
	}




	/* Listing page New Media query End  */

	/* New About us Code start  */



	.content-general h1 {

		font-size: 150%;



	}

	.content-general h2 {

		font-size: 100%;

	}




	.content-general p {
		font-size: 90%;
		line-height: 160%;
	}

	.content-general h3 {

		font-size: 110%;




	}

	.content-general h3 a {
		color: #422778;
		text-decoration: none;
	}



	/* New About us Code end  */


}

/* .options-btn {
	display: flex;
	gap: 5px;
} */



/* new Home Page code Start  */

/* .intro{
	background-color: #f1f1f1;
	padding: 30px 0;
    margin: 0px 0 50px 0;
} */

.intro {
	background-color: #f1f1f1;
	padding: 30px 0;
	margin: 0px 0 50px 0;
}

/* .intro h2{
	color: #FC21D0;
	font-weight: 300;
	font-size: 185%;
	line-height: 130%;
	margin-top: 20px;
    margin-bottom: 10px;
} */

.intro h2 {
	color: #000;
	font-size: 29px;
	font-weight: 700;
	line-height: 130%;
	margin-top: 20px;
	margin-bottom: 10px;
}

.intro h3 {
	/* color: #422778;
    font-size: 120%;
    font-weight: 300;
    line-height: 130%;
	margin-top: 20px;
    margin-bottom: 10px; */
	color: #000;
	font-size: 21px;
	font-weight: 300;
	line-height: 130%;
	margin-top: 20px;
	margin-bottom: 10px;
}

.intro h4 {


	font-size: 18px;

}

.featured-jobs h1 {
	color: #422778;
	font-weight: 300;
	font-size: 250%;
	text-transform: uppercase;
	margin: 0 0 40px 0;
	text-align: center;
	letter-spacing: 0.2em;
}

.featured-jobs .title-wrap {
	background-color: #fff;
	padding: 0 20px;
}

.featured-jobs h1:after {
	border-color: #ccc;
	border-bottom-width: 4px;
	border-bottom-style: double;
	display: block;
	content: '';
	margin: -22px 0 0 0;
}

.featured-jobs-home .btn-register {

	background-color: #FC21D0;
	color: #fff;
	font-weight: 600;
	margin-right: 10px;
	padding: 15px 30px;

}

.featured-jobs .btn-lg {
	border-radius: 5px;
	margin-top: 20px;
}

.featured-jobs-home .btn-lg.btn-register {
	background-color: #EB602F;
	color: #fff;
	font-weight: 600;
	margin-right: 10px;
	padding: 15px 30px;
}

.featured-jobs-home .btn-lg.btn-search-jobs:hover {
	background-color: #EB602F;
}

.featured-jobs-home .btn-lg.btn-search-jobs {
	background-color: #000;
	color: #fff;
	font-weight: 600;
	margin-left: 10px;
	padding: 15px 30px;
}

.featured-jobs-home .btn-lg.btn-register:hover {
	background-color: #000;
	color: #fff;
	font-weight: 600;
	margin-right: 10px;
}

.featured-jobs .btn-search-jobs {
	background-color: #422778;
	color: #fff;
	font-weight: 600;
	margin-left: 10px;
	padding: 15px 30px;
}

.featured-jobs .btn-search-jobs:hover {
	background-color: #FC21D0;
	color: #fff;
	font-weight: 600;

}


.why {
	background-color: #FFF9EE;
	padding: 60px 0 40px 0;
	margin: 80px 0 1px 0;
}

.why h1 {
	color: #EB602E;
	font-weight: 700;
	font-size: 250%;
	margin: 0 0 30px 0;
	text-align: center;

}

.why .title-wrap {
	background-color: #F0F0F0;
	/* background-color: #fff; */
	padding: 0 20px;
}

.alt-color-1 {
	color: #FC21D0;
}

why h1::after {
	border-color: #ccc;
	border-bottom-width: 4px;
	border-bottom-style: double;
	display: block;
	content: '';
	margin: -22px 0 0 0;
}

.why h2 {
	font-size: 19px;
	line-height: 150%;
	margin: 0 0 30px 0;
	padding: 0px 50px 0px 50px;
	color: #000;
	font-weight: 300;
	text-align: center;
}

.why h3 {
	color: #EB602F;
	font-weight: 300;
	font-size: 230%;
	text-transform: uppercase;
	margin: 0 0 20px 0;
	letter-spacing: 0.2em;
}

.why h4 {
	font-size: 19px;
	margin: 20px 0 10px 0;
	padding: 10px 50px 0 55px;
	color: #000;
	font-weight: 600;
	text-transform: uppercase;
	position: relative;
	letter-spacing: 0.2em;
}

.why i {
	position: absolute;
	top: 0;
	left: 0;
	font-size: 38px;
	color: #EB602F;
}

.why p {
	font-size: 15px;
	margin: 0 0 0px 0;
	padding: 0 40px 0px 55px;
	color: #666;
	position: relative;
}

.services-lgbti {
	background-color: #FFF5FD;
	padding: 60px 0 40px 0;
	margin: 0px 0 60px 0;
}

.services-lgbti h1 {
	color: #422778;
	font-weight: 300;
	font-size: 250%;
	text-transform: uppercase;
	margin: 0 0 60px 0;
	text-align: center;
	letter-spacing: 0.2em;
}

.services-lgbti .title-wrap {
	background-color: #FFF5FD;
	/* background-color: #fff; */
	padding: 0 20px;
}

.services-lgbti h1::after {
	border-color: #ccc;
	border-bottom-width: 4px;
	border-bottom-style: double;
	display: block;
	content: '';
	margin: -22px 0 0 0;
}

.services-lgbti h3 {
	color: #FC21D0;
	font-weight: 300;
	font-size: 185%;
	text-transform: uppercase;
	margin: 0 0 20px 0;
	letter-spacing: 0.2em;
}

.services-lgbti p {
	font-size: 110%;
	margin: 0 0 0px 0;
	padding: 0 40px 20px 0px;
	color: #422778;

}

.services-lgbti h2 {
	font-size: 120%;
	line-height: 150%;
	margin: 0 0 20px 0;
	padding: 0px 50px 0px 0px;
	color: #422778;
	font-weight: 300;
}

.services-lgbti ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

.services-lgbti img {
	padding: 0;
}

.services-lgbti li {
	font-size: 100%;
	color: #7E6BA4;
	margin: 0 0 5px 0;
	padding: 0 0 0 0px;

}

.services-lgbti h4 {
	font-size: 120%;
	margin: 20px 0 10px 0;
	padding: 10px 50px 0 0px;
	color: #422778;
	font-weight: 600;
	text-transform: uppercase;
	position: relative;
	letter-spacing: 0.1em;
}

.services-lgbti .btn-primary {
	background-color: #FC21D0;
	border: none;
	font-size: 100%;
	padding: 15px 40px;
	margin-top: 20px;
	font-weight: 600;
}

.services-lgbti .btn-primary:hover {
	background-color: #666;
}

.featuredEmployer {
	/* background-color: #FFF5FD; */
	padding: 20px 0 30px 0;
	margin: 0px 0;
}

.featuredEmployer h1 {
	color: #FC21D0;
	font-weight: 300;
	font-size: 200%;
	text-transform: uppercase;
	margin: 0 0 35px 0;
	text-align: center;
	letter-spacing: 0.2em;
	line-height: 120%;
}

.featuredEmployer .title-wrap {
	background-color: #fff;
	padding: 0 20px;
	display: inline-block;
}

.featuredEmployer h1::after {
	border-color: #ccc;
	border-bottom-width: 4px;
	border-bottom-style: double;
	display: block;
	content: '';
	margin: -22px 0 0 0;
}

.featuredEmployer h2 {
	color: #422778;
	font-weight: 300;
	font-size: 100%;
	text-transform: uppercase;
	margin: 0 0 40px 0;
	text-align: center;
	letter-spacing: 0.2em;
}

.logoCompany {
	max-height: 50px;
	margin: 0 10px 20px 10px;
}






@media (max-width: 767px) {
	.intro h2 {
		font-size: 120%;
	}

	.intro h3 {
		font-size: 100%;
	}

	.featured-jobs .title-wrap {

		padding: 0;
	}

	.featured-jobs h1 :after {

		margin: 15px 0 !important;

	}

	.featured-jobs h1 {
		font-size: 150%;

	}

	.why h1 {
		font-size: 150%;

	}

	.why h1::after {
		margin: 15px 0 !important;


	}

	.why h2 {
		padding: 0;
	}

	.job-options.displayNone {
		display: none !important;
	}

	.services-lgbti h3 {

		font-size: 150%;


	}

	.featured-jobs .btn-search-jobs {

		width: 240px;
		margin-left: 0;
	}

	.featuredEmployer h1 {
		font-size: 150%;
	}

	.featuredEmployer h1::after {
		margin: 15px 0 !important;
	}

	.featuredEmployer h2 {
		font-size: 90%;
	}

	.navbar-brand {



		top: 0px;
		text-align: center !important;
		/* position: unset !important; */
		display: flex;
		justify-content: end !important;
	}

	.imageRespnse {
		width: 130px;
		margin: auto !important;

	}

	.navbar-brand .frontendNavLogo {
		margin: 0;
		width: 200px;
	}

	.social-media ul li {
		display: none !important;
	}

	.login_logout {
		/* display: inline-block!important; */
		display: none !important;
		/* padding: 0px 55px 0 0; */
	}

	.social-media {
		margin-bottom: 0 !important;
		padding: 0 !important;
		min-height: 0 !important;

	}

	.login {
		text-align: center !important;
		/* display: block !important; */
		display: flex !important;
		justify-content: start !important;
		margin-left: 10px !important;
	}

	.search-header {
		position: inherit !important;
		top: inherit !important;
		bottom: inherit !important;
		margin: -19px auto 20px auto !important;
		padding: 20px 20px 20px 20px !important;
		width: 100% !important;
		text-align: center !important;
		background-color: #FFC548 !important;
		border-radius: 0px !important;
	}

	/* .sliderbody {
		display: none !important;
	} */

	.featured-jobs-home .btn-lg.btn-register,
	.featured-jobs-home .btn-lg.btn-search-jobs {
		margin: 10px 0 0 0;
	}
}



/* new Home Page code End  */

/* Forget PAssword Start code  */
.passwordForget {
	padding: 70px 0;
	margin: 0 0 3% 0;
}

.passwordForget h6 {
	margin-bottom: 6%;
}

.passwordForget .card {
	max-width: 100%;
	font-size: 500;
	box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
	border: 0;
}

.passwordForget .error {
	box-shadow: none !important;
	outline: none;
	font-size: 17px;
	height: 45px;
	font-weight: 400;
	opacity: 0.8;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px solid red;
	border-radius: 3px;
}

.passwordForget .input-error {
	box-shadow: none !important;
	outline: none;
	font-size: 17px;
	height: 45px;
	font-weight: 400;
	opacity: 0.8;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px solid red;
	border-radius: 3px;
}

.passwordForget input {
	box-shadow: none !important;
	outline: none;
	font-size: 17px;
	height: 45px;
	font-weight: 400;
	opacity: 0.8;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px solid #fc21d0;
	border-radius: 3px;
}

.passwordForget select {
	box-shadow: none !important;
	outline: none;
	font-size: 17px;
	height: 45px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px solid #fabaa7;
	border-radius: 3px;
	height: 45px;
}

.passwordForget textarea {
	box-shadow: none !important;
	outline: none;
	font-size: 18px;
	font-weight: 400;
	opacity: 0.8;
	min-width: fit-content;
	border: 1px solid #fabaa7;
	border-radius: 3px;
}

.passwordForget .card button {
	color: white;
	background-color: #294a9c;
}

.passwordForget .card button:hover {
	color: white;
	background-color: #f3734c;
}


/* Forget PAssword end code  */

/* Employe Login start  */

.accountRegistry {
	padding: 80px 10px 80px 10px;
}

.EPRegistrationBottomText a {
	color: #EB602E;
}

.accountRegistry .textPurple {
	color: #422778;
}

/* Employe login end  */
.contactListt {
	margin: 15px 1%;
	background: #fff;
	width: 100%;
	border: 1px #ddd solid;
	padding: 22px;
	border-radius: 10px;
	/* min-height: 130px; */
	text-align: left;
}

.employerLogin .textPurple {
	color: #EB602E;
}


.contactnewSection {
	padding: 70px 0;
	margin: 0 0 3% 0;
}



.employerLogin select {
	outline: none;
	font-size: 17px;
	height: 50px;
	font-weight: 400;
	opacity: 0.8;
}

.employerLogin select:hover {
	border: 1px dashed #CBC3E3;
	box-shadow: 10px 14px 22px rgba(246, 172, 150, 0.3);
}

.newAboutus {
	margin: 0 0 3%;
	padding: 30px 0;
}




/* newto lgbti jobs css  */
ul.icon-list {
	list-style: none;
	margin: 20px 0 30px 45px;
	padding: 0;
}

ul.icon-list li {
	margin: 0 0 20px 0;
	padding: 0;
	position: relative;
	color: #696969;
	font-size: 100%;
	line-height: 160%;
}

ul.icon-list li strong {
	color: #EB602F;
	font-size: 19px;
}

ul.icon-list li .fa {
	font-size: 30px;
	position: absolute;
	top: 0px;
	left: -45px;
	color: #FFC548;
}

.text-wrap {
	padding: 0 20px;
}

.newPolicy {
	padding: 18px 0;
	margin: 0 0 3% 0;
}

p {
	margin: 0 0 10px;
}

.contactSection {
	padding: 40px 0;
	margin: 0 0 3% 0;
}


/* Services page Start  */

.servicesTxt h1 {
	color: #000;
	font-size: 30px;
	font-weight: 600;
	margin: 0 0 30px 0;
	padding: 0;

}

.servicesTxt p {
	color: #696969;
	font-size: 100%;
	line-height: 160%;
}

.servicesTxt h2 {
	color: #EB602F;
	font-size: 22px;
	font-weight: 300;
	margin: 0 0 10px 0;
	padding: 0;
	line-height: 22px;
}

.servicesTxt ul {
	margin: 20px 0 30px 45px;

}

.servicesTxt ul li {
	list-style: disc;
}

.servicesTxt h3 {
	color: #000;
	font-size: 19px;
	font-weight: 300;
	margin: 20px 0 10px 0;
	padding: 0;
	line-height: 140%;
}


/* Service page End  */

/* Extra Css  */
.jobDetailsDescription {
	padding: 30px 0;
}


.navbar {
	border-radius: 0px;
	/* margin: 20px 0 0px 0; */
	min-height: 80px;
}




/* .navbar-brand img {
    width: auto;
    height: 145px;
} */

.navbar .login li {
	color: #EB602F;
}

.navbar .login li a {
	color: #EB602F;
}

.navbar .login li .fa {
	margin-right: 3px;
	color: #FFC548;
}


.featured-jobs-home h1 {
	color: #EB602E;
	font-weight: 700;
	font-size: 38px;
	margin: 0 0 40px 0;
	text-align: center;
}

h1 span.left {
	display: inline-block;
	height: 16px;
	width: 50px;
	margin: 2px 20px 0 0;
	background: url(/public/Images/bg-dots-left.png) 0 0 no-repeat;
	background-size: 100%;
}

h1 span.right {
	display: inline-block;
	height: 16px;
	width: 50px;
	margin: 2px 0 0 25px;
	background: url(/public/Images/bg-dots-right.png) 0 0 no-repeat;
	background-size: 100%;
}


/* Contact US page css  */
.contact-general {
	margin: 40px 0 60px 0;
}

.contact-general h1 {
	color: #000;
	font-size: 35px;
	font-weight: 600;
	margin: 0 0 30px 0;
	padding: 0;
}

.contact-general h2 {
	color: #EB602F;
	font-size: 26px;
	font-weight: 300;
	margin: 0 0 10px 0;
	padding: 0;
	line-height: 140%;
}

.contact-general p {
	color: #696969;
	font-size: 15px;
	line-height: 160%;
}

/* contact us page css end  */


.navbar .social-links {
	margin-right: 30px;
}

.navbar li {
	font-size: 100%;
	color: #777;
	font-weight: 700;
}

.social-media {
	text-align: right;
	margin-bottom: 0px;
	padding: 20px 0 0px 0;
	min-height: 35px;
}

.social-media li {
	font-size: 100%;
	color: #777;
	font-weight: 700;
	display: inline-block;
}

.social-media ul {
	display: inline-block;
}

.social-media li a {
	padding: 0 0 0 10px;
}

.social-links .fa {
	color: #777;
}



.social-media a {
	margin: 0 10px;
	text-decoration: none;
	/* color: #007bff; */
	color: #FFC548;
}

.social-media a:hover {
	color: #EB602E !important;
}

.login {
	display: inline-block;
}

.login a {
	color: #EB602F !important;
}

.login .fa {
	margin-right: 3px !important;
	color: #FFC548 !important;
}

.social-links .fa:hover {
	color: #EB602F;
}

.navbar-nav .nav>li>a:active,
.navbar-nav .nav>li>a:focus,
.navbar-nav li a:hover {
	background: none;
	color: #EB602F;
}

.navbar.top-nav {
	background: url(/public/Images/bg-nav.png) center bottom repeat-x;
	background-size: auto 24px;
	padding: 0 0 14px 0;
}

.home-search-block {
	background: url(/public/Images/bg-artwork.png) center repeat-x;
	background-size: cover;
	padding: 30px 0;
	border-top: 4px solid #fff;
	border-bottom: 4px solid #000;
}

.search-header {
	margin: 0 auto;
	padding: 25px 20px 30px 20px;
	width: 800px;
	text-align: center;
	background-color: #FFC548;
	border-radius: 10px;
}

/* .search-header .btn-primary {
    background-color: #000;
    border: none;
    padding: 7px 18px;
    -webkit-box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.3);
    font-size: 23px;
    color: #fff;
    text-decoration: none;
}

.search-header .btn-primary:hover{
	background-color: #999;
	color: #fff;
} */

/* .btn-primary {
    background-color: #EB602F;
    border-color: #EB602F;
}

.btn-primary:hover {
    background-color: #FFC548;
    border-color: #FFC548;
} */

.dropdown-item.active,
.dropdown-item:active {
	background-color: unset !important;
	color: #000 !important;
}

.family-law {
	color: #000;

}

.family-law:hover {

	text-decoration: underline !important;
}


@media (max-width: 1350) {
	.frontendNavLogo {
		max-width: 200px;
	}
}

.leadership_team ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.leadership_team ul li {
	font-size: 16px !important;
	list-style-type: disc !important;
	margin-left: 20px !important;
}


@media only screen and (min-width: 1400px) {
	.sliderImageBox {
		height: 600px;
		background-size: cover;
	}
}



.Contactformgeneral input {
	border: none;
	outline: none;
	font-size: 17px;
	height: 50px;
	font-weight: 400;
	opacity: 0.8;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px dashed #a7a6a6;
}

.Contactformgeneral input:hover {
	border: 1px dashed #CBC3E3;
	box-shadow: 10px 14px 22px rgb(235, 96, 46, 0.3);
}

.Contactformgeneral select:hover {
	border: 1px dashed #CBC3E3;
	box-shadow: 10px 14px 22px rgb(235, 96, 46, 0.3);

}

.login_logout .dropdown-menu {
	display: none !important;
}

.login_logout .dropdown-menu.show {
	display: block !important;
}

.login_logout .dropdown-menu {
	background: #000 !important;
	position: absolute;
}

.login_logout .dropdown-menu .dropdown-item {
	clear: both;
	color: #fff !important;
	display: block !important;
	font-size: 90% !important;
	font-weight: 400 !important;
	line-height: 1.42857143 !important;
	padding: 3px 20px !important;
	white-space: nowrap !important;
}

.login_logout .dropdown-menu .dropdown-item:hover {
	background: initial !important;
	color: #eb602e !important;
}

.login_logout {
	display: inline-block;
}

.newcontact_form .passwordVisibility {
	position: absolute;
	right: 13px;
	top: 45px;
}

/* .css-dwkzlu-MuiDataGrid-root .MuiDataGrid-withBorderColor{
	display: none !important;
} */

.css-15lx25q-MuiDataGrid-footerContainer {
	display: none !important;
}

.css-j8yymo {
	/* height: 30px !important; */
	/* opacity: unset !important; */
	/* min-width: 40px !important; */
	/* display: none; */
}
.APJobsList .manageSubadminPart2 {
	padding: 0px !important;
}
.APJobseekerList .manageSubadminPart2 {
	padding: 0px !important;
}